import React from 'react';
import { styled } from '../../theme';
import ProgressBar from './progress-bar';
import { MediaQueries } from '../../theme/media-queries-css';
import { ThemeContext } from '../../themecontext';

const MainWrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;

  @media screen and (max-width: 1360px) {
    padding: 0px;
  }

  ${MediaQueries.medium} {
    height: 100%;
  }
`;

type Props = {
  progress: number;
  children: React.ReactNode;
};

export default class Main extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  render() {
    const { desktop } = this.context.device;

    return (
      <MainWrapper className="flowlayout-wrapper">
        {/*desktop && <ProgressBar progress={this.props.progress} />*/}
        <Content>{this.props.children}</Content>
      </MainWrapper>
    );
  }
}
