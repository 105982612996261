import React, { useState, useEffect, useContext } from 'react';
import YouTube from 'react-youtube';
import { styled, Theme } from '../../theme';

import { MediaQueries } from '../../theme/media-queries-css';
import { ThemeContext } from '../../themecontext';

const Title = styled('div')<{
  isLandingPage: boolean;
}>`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding: 0 0 10px;

  ${({ isLandingPage }) =>
    isLandingPage &&
    `
		dislay: none;
	`}

  ${MediaQueries.large} {
    font-size: 28px;
    line-height: 23px;
    margin-bottom: 20px;
    background: #fff;
  }
`;

const Wrapper = styled.div<{
  isLandingPage: boolean;
}>`
  ${({ isLandingPage }) =>
    !isLandingPage &&
    `
		padding-bottom: 50px;

		${MediaQueries.large} {
			padding-top: 0;
		}

		${MediaQueries.medium} {
			padding-top: 0px;
		}

	`}

  ${({ isLandingPage }) =>
    isLandingPage &&
    `

		${MediaQueries.medium} {

		}
	`}
`;

const YouTubeWrapper = styled('div')<{
  isPlaying: boolean;
}>`
  position: relative;
  height: 0;
  cursor: pointer;
  padding-top: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 */

  ${({ isPlaying }) =>
    !isPlaying &&
    `
		${MediaQueries.small} {
			padding-bottom: 75%;
		}
	`}
`;

const VideoWrapper = styled('div')<{ isPlaying: boolean }>`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  display: ${({ isPlaying }) => (isPlaying ? 'block' : 'none')} iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CoverImageWrapper = styled('div')<{
  isPlaying: boolean;
  isLandingPage: boolean;
}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	display: ${({ isPlaying }) => (!isPlaying ? 'block' : 'none')}
	z-index: 10;

	${MediaQueries.large} {
		&:hover {
			svg { transform: scale(1.2); }
		}
	}

	svg {
		transition: transform .25s ease;
		transform-origin: 50% 50%;

		${({ isLandingPage }) =>
      !isLandingPage &&
      `
			width: 62px;
			height: 62px;

			${MediaQueries.medium} {
				width: 62px;
				height: 62px;
			}

			${MediaQueries.small} {
				width: 44px;
				height: 44px;
			}
		`}

		${({ isLandingPage }) =>
      isLandingPage &&
      `
			width: 50px;
			height: 50px;

			${MediaQueries.medium} {
				width: 62px;
				height: 62px;
			}

			${MediaQueries.small} {
				width: 44px;
				height: 44px;
			}
		`}

		polygon { fill: ${({ theme }) => theme.color.purple}; fill-rule:evenodd; }
		circle  { fill: ${({ theme }) => theme.color.green}; }
	}

`;

const CoverImage = styled('img')`
  height: auto;
  width: 100%;
`;

const PlayIcon = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
  cursor: pointer;
  transition: transform 0.3s ease;
`;

let YTplayer = null;
let isMounted: boolean = false;

const VideoEU = (props: any) => {
  const context = useContext(ThemeContext);
  const { mobile } = context.device;
  const { id = '', title = '' } = props;

  const [YouTubeisPlaying, setYouTubeisPlaying] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false); // only used for triggering render update

  const isLandingPage = window.location.pathname === '/';

  const opts: any = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      //color: theme.color.green,
      fs: 1, // fullscreen active
      modestbranding: 1,
      rel: 0,
      origin: window.location.host,
    },
  };

  let onReady = (e) => {
    YTplayer = e.target;
  };

  let onPlay = (e) => {
    if (isMounted) setYouTubeisPlaying(true);
  };

  /*let onPause = (event) => {
	}*/

  let onEnd = (e) => {
    //setYouTubeisPlaying(false)
  };

  let coverImage: string = mobile
    ? 'https://i.ytimg.com/vi/' + props.id + '/sddefault.jpg'
    : 'https://i.ytimg.com/vi/' + props.id + '/maxresdefault.jpg';

  let playVideo = (e: React.MouseEvent<HTMLElement>) => {
    if (isMounted) setYouTubeisPlaying(true);
    if (YTplayer !== null) YTplayer.playVideo();
  };

  // force update
  useEffect(() => {
    setTimeout(() => {
      isMounted = true;
      setForceUpdate(true);
    }, 200);
    return () => {
      YTplayer = null;
      isMounted = false;
    };
  }, []);

  const SVGPlayIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
      <circle cx="35" cy="35" r="35" />
      <polygon points="29.2 22.2 48.4 35 29.2 47.8 29.2 22.2" />
    </svg>
  );

  return (
    <>
      {id !== '' && (
        <Wrapper
          className={`${!isLandingPage ? 'negative-margin padding left white' : ''}`}
          isLandingPage={isLandingPage}
        >
          <div className="medium">
            {title !== '' && !isLandingPage ? <Title isLandingPage={isLandingPage}>{title}</Title> : ''}
            <YouTubeWrapper isPlaying={YouTubeisPlaying}>
              <CoverImageWrapper isLandingPage={isLandingPage} isPlaying={YouTubeisPlaying} onClick={playVideo}>
                <PlayIcon className="yt-play-btn" onClick={playVideo}>
                  {SVGPlayIcon}
                </PlayIcon>
                <CoverImage src={coverImage} />
              </CoverImageWrapper>
              <VideoWrapper className="youtube" isPlaying={YouTubeisPlaying}>
                <YouTube
                  videoId={id}
                  opts={opts}
                  onReady={onReady}
                  onPlay={onPlay}
                  /*onPause={onPause}*/
                  onEnd={onEnd}
                />
              </VideoWrapper>
            </YouTubeWrapper>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default VideoEU;
