import React from 'react';

import { styled } from '../../theme';
import InputSelect from '../../components/input-select';
import { MediaQueries } from '../../theme/media-queries-css';

const Wrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 26px;

  ${MediaQueries.small} {
    padding-top: 6px;
    padding-bottom: 18px;
  }
`;
const ValidationText = styled.div<{ error: boolean }>`
  font-family: Novozymes;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  color: ${({ error }) => (error ? 'red' : '#000000')};
`;

interface Props {
  minWidth: number;
  maxWidth: number;
  minValue?: number;
  maxValue?: number;
  value: number | null;
  unit: string;
  validationText?: string;
  pattern?: string;
  label: string;
  onValue: (value: number | null) => void;
  className: string;
  options?: string;
}

interface State {
  convertedValue: number | null;
  typedValue: string;
}

export default class RecipeInput extends React.PureComponent<Props, State> {
  state = { typedValue: '', convertedValue: null };

  onValue = (typedValue: string): void => {
    const convertedValue = this.convert(typedValue);
    this.setState({ typedValue, convertedValue });
    this.props.onValue(convertedValue);
  };
  convert = (value: string): number | null => {
    if (value === '') {
      return null;
    }
    const n = Number(value);
    if (isNaN(n)) {
      return null;
    }
    if (n < this.props.minValue || n > this.props.maxValue) {
      return null;
    }
    return n;
  };
  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (props.value === state.convertedValue) {
      return null;
    }
    // Different value is being pushed than last conversion
    return {
      convertedValue: props.value,
      typedValue: String(props.value),
    };
  }
  render() {
    const { typedValue } = this.state;
    const { maxWidth, minWidth, label, pattern, validationText, className, options, unit } = this.props;

    const error = typedValue !== '' && this.convert(typedValue) === null;

    return (
      <Wrapper>
        <InputSelect
          maxWidth={maxWidth}
          minWidth={minWidth}
          label={label}
          onValue={this.onValue}
          value={typedValue}
          error={error}
          pattern={pattern === undefined ? '[a-zA-Z0-9.s]*' : pattern}
          className={className}
          options={options}
          unit={unit}
        />
        {/*<ValidationText error={error}>{validationText}</ValidationText>*/}
      </Wrapper>
    );
  }
}
