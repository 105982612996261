/* eslint-disable max-len */

import React, { ReactElement, useState, useEffect, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  IconButton,
  Theme,
  ThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation, Trans } from 'react-i18next';
import i18n from '../../i18NextConf';
import CountryOptions from '../../static-data/CountryOptions';
import { Button, NovozymesTheme } from '@novozymes-digital/components';
import { pardotFormHandler } from '@novozymes-digital/utils';

type PardotDialogProps = {
  show: boolean;
  onClose(): void;
  dialogTitle?: string;
  dialogIntro?: string;
  subject: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: '100%',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: theme.spacing(4),
  },
  field: {
    '& .MuiInputBase-root': {
      borderRadius: '16px',
    },
  },
  label: {
    marginBottom: '4px',
  },
}));

const PARDOT_FORM_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-11-19/d8tnb';

export default function PardotDialog({
  show,
  onClose,
  dialogTitle,
  dialogIntro,
  subject,
  ...props
}: PardotDialogProps): ReactElement {
  const [open, setOpen] = useState(show);
  const formRef = useRef<HTMLFormElement>(null);

  // eslint-disable-next-line no-undef
  const sourceUrl = window.location.href;
  const gaId = ''; //getClientId()
  const classes = useStyles();

  const handleClose = useCallback((): void => {
    setOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();

      if (formRef.current == null) {
        return;
      }
      pardotFormHandler(PARDOT_FORM_ENDPOINT, formRef.current, handleClose);
    },
    [handleClose]
  );

  return (
    <ThemeProvider theme={NovozymesTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle disableTypography={false} id="alert-dialog-title">
          <Box p={2}>
            <Box pb={1}>
              <Typography variant="h2">{dialogTitle || i18n.t('heading')}</Typography>
            </Box>
            <Typography variant="body1">{dialogIntro || i18n.t('intro')}</Typography>
          </Box>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.scroll}>
          <form id={`${subject}-form`} onSubmit={onSubmit} ref={formRef}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('firstName')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    required
                    name="first_name"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('lastName')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    required
                    name="last_name"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('eMail')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    required
                    name="email"
                    type="mail"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('jobTitle')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    required
                    name="job_tltle"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('company')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    required
                    name="company"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('country')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    select
                    required
                    name="country"
                  >
                    {CountryOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={0}>
                  <Typography className={classes.label} variant="body1">
                    {i18n.t('comments')}
                  </Typography>
                  <TextField
                    className={`${classes.fullWidth} ${classes.field}`}
                    variant="outlined"
                    multiline
                    rowsMax="10"
                    rows={4}
                    name="comment"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={0}>
                  <FormControlLabel
                    control={<Checkbox required value="True" name="i_accept_terms" />}
                    label={
                      <Typography variant="body2">
                        <Trans
                          i18nKey="agreeTermsConditions"
                          components={{
                            terms: <a target="_blank" rel="noopener noreferrer" href="/TermsOfUsePage" />,
                            privacy: (
                              <a target="_blank" rel="noopener noreferrer" href="https://www.novozymes.com/privacy" />
                            ),
                          }}
                        />
                      </Typography>
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={0}>
                  <FormControlLabel
                    control={<Checkbox value="True" name="i_opt_in" />}
                    label={<Typography variant="body2">{i18n.t('occasionalUpdates')}</Typography>}
                  />
                </Box>
              </Grid>
              <input type="hidden" name="subject" value={subject} />

              <input type="hidden" name="internal_url" value={sourceUrl} />
              <input type="hidden" name="ga_client_id" value={gaId} />

              <Grid item xs={12} className={classes.textAlignCenter}>
                <Button type="primary" id={`${subject}-btn-form-submit`} data-e2e="ResultPage-SubmitForm" isSubmit>
                  {i18n.t('Submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
