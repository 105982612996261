import React, { useState } from 'react';
import i18n from '../../i18NextConf';

import RegionConfig from '../../region-config';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import Form from './form';
import Input from './input';
import InputCheckBox from './checkbox';
import Button from '../button';

import { contactProblemAPI } from '../../services/api';
import { colors } from '@novozymes-digital/components';

const Container = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 auto 20px;

  form {
    width: 100%;
  }

  .input-fields {
    width: 100%;
  }
`;
const Title = styled.div`
  color: ${colors.black};
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 600px) {
    color: ${colors.black};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const Header = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SuccesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  margin-bottom: 20px;
  line-height: 24px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export interface checBoxesInterface {
  id: string;
  label: string;
  name: string;
}

const CheckBoxes = () => {
  let checkBoxes: checBoxesInterface[] = [
    {
      id: 'low_bread_volume_2',
      label: i18n.t('Low bread volume'),
      name: 'LowbreadVolume',
    },
    {
      id: 'not_uniform_crumb_structure_2',
      label: i18n.t('Not uniform crumb structure'),
      name: 'CrumbStructure',
    },
    {
      id: 'pale_crust_color_2',
      label: i18n.t('Pale crust color'),
      name: 'PaleCrustColor',
    },
    {
      id: 'dough_stickiness_2',
      label: i18n.t('Dough stickiness'),
      name: 'DoughStickiness',
    },
    {
      id: 'other_issues_2',
      label: i18n.t('Other issues'),
      name: 'OtherIssues',
    },
  ];

  return checkBoxes;
};

const FormularOtherIssuesResultspage = (props: { closeModal: () => void }) => {
  const data: any = {};

  const { closeModal } = props;
  const [CheckBox, setCheckBox] = useState(false);

  const [Issues, setIssues] = useState([]);
  const [Comment, setComment] = useState('');
  const [UserName, setUserName] = useState('');
  const [UserEmail, setUserEmail] = useState('');
  const [UserPhone, setUserPhone] = useState('');
  const [UserCompany, setUserCompany] = useState('');
  const [Succes, setSucces] = useState(false);
  const [ValidCheckBoxes, setValidCheckBoxes] = useState(false);

  // Get user info
  RegionConfig.getUser().then(function (user) {
    setUserName(user.name);
    setUserEmail(user.email);
    setUserPhone(user.phone_number);
    setUserCompany(user['custom:company']);
  });

  const handleCheckboxes = (checkbox: any) => {
    let checkboxValue: string = checkbox.value;
    let isChecked: boolean = checkbox.checked;

    const idx: number = Issues.indexOf(checkboxValue);

    if (isChecked) {
      if (idx < 0) {
        Issues.push(checkboxValue);
      }
    } else {
      if (idx > -1) {
        Issues.splice(idx, 1);
      }
    }

    setValidCheckBoxes(Issues.length > 0);
  };

  const changeNameValue = (name: any) => {};
  const changeCompanyValue = (company: any) => {};

  const onSubmitForm = async () => {
    if (ValidCheckBoxes) {
      await contactProblemAPI(UserName, UserEmail, UserPhone, UserCompany, Issues.join(', '), Comment);

      setSucces(true);
    }
  };

  return (
    <Container>
      {!Succes ? (
        <Form
          className="other-issues"
          onFormSubmit={onSubmitForm}
          disabled={!ValidCheckBoxes}
          submitText={i18n.t('Submit')}
          inputs={
            <div className="input-fields">
              <Header>{i18n.t('Possible issues')}</Header>
              {CheckBoxes().map((item, index) => (
                <InputCheckBox
                  key={index}
                  id={item.id}
                  title={item.label}
                  name={item.name}
                  value={item.label}
                  onValue={handleCheckboxes}
                />
              ))}
              {/*<Input
            maxWidth={454}
            label={i18n.t('Mobile')}
            pattern="[a-zA-Z0-9]+"
            onValue={changeContactValue}
            value={Contact}
            error={ContactValid}
          />*/}
              <Input
                inputType="textarea"
                maxWidth={454}
                label={i18n.t('Describe your problem in more details')}
                onValue={setComment}
                value={``}
                error={false}
                name="problem"
              />
            </div>
          }
        />
      ) : (
        <SuccesContainer>
          {i18n.t('Thank you for sharing your challenges. We will get back to you as soon as possible')}
        </SuccesContainer>
      )}

      {Succes && (
        <CloseBtnWrapper>
          <Button minWidth={200} marginRight={0} primary={false} disabled={false} onClick={closeModal}>
            {i18n.t('Close')}
          </Button>
        </CloseBtnWrapper>
      )}
    </Container>
  );
};

export default FormularOtherIssuesResultspage;
