import React from 'react';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

const CheckBoxWrapper = styled.div`
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 33px;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: solid 1px #e7e7e7;
    order: 1;
  }

  input[type='checkbox']:checked ~ span {
    background-color: #fff;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  input[type='checkbox']:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 9px;
    top: 4px;
    width: 8px;
    height: 13px;
    border: solid ${({ theme }) => theme.color.purple};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Title = styled.div`
  order: 2;
`;

const Label = styled.label``;

const Span = styled.span``;

const CheckBox = styled.input.attrs((props) => ({
  type: 'checkbox',
  id: props.id,
}))``;

interface Props {
  title: string;
  id: string;
  onValue: (value: any) => void;
  name: string;
  value: string;
}

const InputCheckbox = (props: Props) => {
  const { title = '', id, onValue, name = '', value = '' } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onValue({ value: e.target.value, checked: isChecked });
  };

  return (
    <>
      <CheckBoxWrapper className="checkbox-container">
        <Label htmlFor={id}>
          <Title>{title}</Title>
          <CheckBox id={id} name={name} value={value} onChange={handleChange} />
          <Span></Span>
        </Label>
      </CheckBoxWrapper>
    </>
  );
};

export default InputCheckbox;
