interface CSSMediaQueries {
  small: string;
  medium: string;
  large: string;
}

export const MediaQueries: CSSMediaQueries = {
  small: '@media screen and (max-width: 600px)',
  medium: '@media screen and (max-width: 992px)',
  large: '@media screen and (min-width: 993px)',
};
