import { ApplicationType } from '../pages/application/application-config';
import { Enzyme } from '../types';

export interface StarRating {
  softness: number;
  elasticity: number;
  moistness: number;
}

const defaultStarRating: StarRating = {
  softness: 1,
  elasticity: 1,
  moistness: 1,
};

const starSystemCH = (enzyme: Enzyme, application: ApplicationType): StarRating => {
  switch (application) {
    case 'WhitePanBreadStraightDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadSpongeDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'HamburgerBun':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'Panettone':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'RyeBread':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadSweetStraightDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WholeWheat100SpongeDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WholeWheat100StraightDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'Brioche':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'USBrioche':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    default:
      return defaultStarRating;
  }

  return defaultStarRating;
};

const starSystemRest = (enzyme: Enzyme, application: ApplicationType): StarRating => {
  switch (application) {
    case 'WhitePanBreadStraightDough':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadSpongeDough':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'HamburgerBun':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'Panettone':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'RyeBread':
      switch (enzyme) {
        case 'Novamyl Rye BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 2,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 3,
            elasticity: 2,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadSweetStraightDough':
      switch (enzyme) {
        case 'NM3D':
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WholeWheat100SpongeDough':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 4,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }

      break;

    case 'WholeWheat100StraightDough':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };
        case 'Novamyl Boost BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 3,
          };
        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }

      break;

    case 'Brioche':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 2,
            moistness: 1,
          };

        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };

        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'USBrioche':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 2,
            moistness: 1,
          };

        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };

        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadStraightDoughIndiaSEAsia':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };

        case 'Novamyl Boost BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 3,
          };

        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    case 'WhitePanBreadSpongeDoughIndiaSEAsia':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };

        case 'Novamyl Boost BG':
          return {
            softness: 3,
            elasticity: 3,
            moistness: 3,
          };

        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 4,
          };

        default:
          break;
      }
      break;
    case 'WholeWheat100StraightDoughIndiaSEAsia':
      switch (enzyme) {
        case 'Novamyl 10000 BG':
          return {
            softness: 2,
            elasticity: 3,
            moistness: 1,
          };

        case 'Novamyl Boost BG':
          return {
            softness: 4,
            elasticity: 4,
            moistness: 3,
          };

        case 'Novamyl 3D BG':
          return {
            softness: 4,
            elasticity: 3,
            moistness: 4,
          };

        default:
          break;
      }
      break;

    default:
      return defaultStarRating;
  }

  return defaultStarRating;
};

export default (enzyme: string, application: string, region: string): StarRating => {
  if (region === 're_CH') {
    return starSystemCH(enzyme as Enzyme, application as ApplicationType);
  } else {
    return starSystemRest(enzyme as Enzyme, application as ApplicationType);
  }
};
