import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import { getBrowserHeight } from '../../helpers/general';
import { colors } from '@novozymes-digital/components';

const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  min-width: 100%;
  min-height: 100%;
  display: none;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .EnzymePrice {
    width: 454px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;

const Container = styled.div<{
  verticalPos: string;
}>`
  max-width: 673px;
  width: 100%;
  position: absolute;
  z-index: 999;
  left: 50%;
  padding: 60px 20px 20px;
  overflow: hidden;

  ${({ verticalPos }) =>
    verticalPos == 'top' &&
    `
    top: 0%;
    transform: translate(-50%,0%);
  `}

  ${({ verticalPos }) =>
    verticalPos == 'center' &&
    `
    top: 50%;
    transform: translate(-50%,-50%);
  `}

  &.top {
    top: 0;
    transform: translate(-50%, 0%);
  }

  ${MediaQueries.small} {
    padding: 65px 30px 80px;
  }
`;

const CloseOverlay = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 10;

  ${MediaQueries.small} {
    top: 25px;
    right: 30px;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 14px;
    content: ' ';
    height: 28px;
    width: 2px;
    background-color: #fff;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  form {
    background: #f6f6f5;
    color: ${colors.black};
  }
`;

interface Props {
  verticalPos?: string;
  name?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  currentComponent?: string;
}

const Modal = (props: Props) => {
  const { children, open, onClose, verticalPos = 'center', currentComponent = '' } = props;

  const parentContainerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const onOverlayClick = (e: React.MouseEvent) => {
    /*e.preventDefault()*/
    let target = e.target as any;
    let currentTarget = e.currentTarget as any;

    if (target === null || currentTarget === null || typeof target.className !== 'string') return;

    if (target.className.indexOf('close') > -1) {
      currentTarget.click();
    }

    if (e.target === e.currentTarget || target.className.indexOf('container') > -1) {
      onClose();
      if (localStorage.getItem('scrollPos') !== null) {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }
    }
  };

  useLayoutEffect(() => {
    if (containerRef.current != null && parentContainerRef.current != null) {
      parentContainerRef.current.scrollTop = 0;
      containerRef.current.classList.remove('top');
      containerRef.current.offsetHeight > getBrowserHeight() ? (containerRef.current.className += ' top') : '';
    }
  }, [currentComponent]);

  return (
    <Overlay onClick={onOverlayClick} className={open ? 'open' : ''} ref={parentContainerRef}>
      <Container className="container" ref={containerRef} verticalPos={verticalPos}>
        <CloseOverlay className="close" />
        {children}
      </Container>
    </Overlay>
  );
};

export default Modal;
