// @ts-nocheck
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, Grid, useMediaQuery } from '@material-ui/core';
import reactReplaceString from 'react-string-replace';
import { styled } from '../../theme';
import i18n from '../../i18NextConf';
import { Trans } from 'react-i18next';
import LanguageSwitcher from '../../components/NewLanguageSwitcher';
import { Auth, Hub } from 'aws-amplify';
import TagManager from 'react-gtm-module';
import { Navigate } from 'react-router';
import NovozymesAuthCSS from '../../theme/aws-auth-css';

import getVideoUrlForLang from './getVideoUrlForLang';
import Video from '../../components/video/';
import AuthPopup from './AuthPopup';
import { Button, colors, Footer, DropDown } from '@novozymes-digital/components';
import { MediaQueries } from '../../theme/media-queries-css';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_HOST || 'https://smartbake-api.ssp-s1-sandbox.novozymes.cloud';

const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Content = styled.div``;

const FullHeightPage = styled.div`
  display: flex;
  min-height: ${({ minHeight }) => minHeight || `100vh`};
  background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.color || '#000'};
  position: relative;
`;
const MainTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
`;
const SubTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 8px;
  text-align: center;
`;

const Paragraph = styled.div`
  font-family: Novozymes;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black80};

  &.title {
    font-weight: bold;
    margin-bottom: 7px;
  }
`;

const Intro = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
      120deg,
      ${colors.primaryBlue} 0%,
      ${colors.primaryBlue} 70%,
      transparent 70%,
      transparent 100%
    ),
    url('/bread.png') repeat-x;
  background-size: cover;
`;

const DownArrow = styled.div`
  @keyframes float {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
  &:hover {
    animation: none;
  }
  font-size: 32px;
  line-height: 0;
  border: 3px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  animation-name: float;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

const WhiteButton = styled.div`
  background: none;
  color: ${colors.white95};
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  text-decoration: underline;
  text-decoration-color: ${colors.primaryGreen};
  text-underline-offset: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '4em',
};

const LandingPage = (): ReactElement => {
  const infoStartRef = useRef();
  const [authenticated, setAuthenticated] = useState(false);
  const [authPopupState, setAuthPopupState] = useState<'' | 'signUp' | 'signIn'>('');

  const lang = localStorage.getItem('i18nextLng');

  const videoUrl = getVideoUrlForLang(lang);

  const isMobile = useMediaQuery(MediaQueries.medium);

  const VideoInfoBlocks = [
    <Grid item xs={12} md={6} key="video-paragraph">
      <Paragraph
        className="video-paragraph"
        style={{
          maxWidth: '80%',
          display: 'block',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Video url={videoUrl} />
      </Paragraph>
    </Grid>,
    <Grid item xs={12} md={6} style={{ display: 'flex' }} key="title">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Paragraph className="title">{i18n.t('How baking freshness enzymes work')}</Paragraph>
        <Paragraph>
          {i18n.t(
            'What causes bread to go stale, and how can enzymes slow that process down? Watch the 2-minute video to learn how enzymes can extend bread’s natural freshness and help reduce the amount of wasted bread. Get started now'
          )}
        </Paragraph>
      </Box>
    </Grid>,
  ];

  const scrollToRef = (ref) => () => {
    if (ref) {
      ref.current.scrollIntoView(true);
    }
  };

  const logMeApi = async (callBack) => {
    try {
      await axios({
        method: 'post',
        url: `${BASE_URL}/logme`,
        data: {
          tc_url: window.location.href + 'TermsOfUsePage',
          privacy_url: 'https://www.novozymes.com/en/privacy-policy',
          client_host: window.location.host,
        },
      });
      callBack();
    } catch (error) {
      console.log(error);
      callBack();
    }
  };

  const getSecurityGroup = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${BASE_URL}/get_security_group`,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Error on fetchPreviousRecommendations:${error.message}`);
    }
  };

  const handleLogout = async () => {
    Auth.signOut({})
      .then((data) => window.location.replace('/'))
      .catch((err) => console.log(err));
  };

  const onAuthEvent = useCallback((payload) => {
    if (payload && payload.event === 'signIn') {
      Auth.currentUserInfo()
        .then((user) => {
          TagManager.dataLayer({
            dataLayer: {
              userId: user.attributes.sub,
            },
            dataLayerName: 'dataLayer',
          });

          // Check if the user in on the correct cognito group
          getSecurityGroup()
            .then((securityGroup) => {
              const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
              const requiredAccessGroup = securityGroup['environment'];
              const cognitoGroups = payload.data?.signInUserSession?.accessToken?.payload['cognito:groups'];
              const grantAccess =
                (isDevEnv && requiredAccessGroup === 'local') ||
                (cognitoGroups && cognitoGroups.indexOf(requiredAccessGroup) !== -1);

              if (grantAccess) {
                logMeApi(() => window.location.replace('/ApplicationPage'));
              } else {
                handleLogout();
              }
            })
            .catch((error) => {
              handleLogout();
            });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });

    Auth.currentAuthenticatedUser()
      .then(() => setAuthenticated(true))
      .catch(() => {});
  }, [onAuthEvent]);

  if (authenticated) return <Navigate to="/ApplicationPage" />;

  return (
    <>
      <NovozymesAuthCSS />
      <AuthPopup
        isOpen={!!authPopupState}
        authState={authPopupState}
        handleClose={() => {
          setAuthPopupState('');
        }}
      />
      <PageRoot>
        <Content>
          <FullHeightPage color="#fff" minHeight="60vh">
            <Intro />

            <Container
              style={{
                ...containerStyles,
                position: 'relative',
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <MainTitle>
                  <Trans
                    i18nKey="Welcome to Smartbake®"
                    defaults="Welcome to Smartbake<sup>®</sup>"
                    components={{ sup: <sup /> }}
                  />
                </MainTitle>
                <SubTitle>
                  {i18n.t('Fresh-keeping Enzyme Advice.')} {i18n.t('Re-imagined.')}
                </SubTitle>

                <Box mt={3} display="inline-flex">
                  <Button
                    id="landing-btn-create-account"
                    type="primary"
                    onClick={() => {
                      setAuthPopupState('signUp');
                    }}
                  >
                    {i18n.t('Create a new account')}
                  </Button>
                  <Box mx={1} />
                  <Button
                    id="landing-btn-sign-in"
                    onClick={() => {
                      setAuthPopupState('signIn');
                    }}
                  >
                    {i18n.t('Sign In')}
                  </Button>
                </Box>
                <Box mt={2}>
                  <WhiteButton
                    id="landing-btn-contact-us"
                    onClick={() => {
                      window.location.assign('mailto:YSE@novozymes.com');
                    }}
                  >
                    {i18n.t('Contact us')}
                  </WhiteButton>
                </Box>
              </Box>
              <DownArrow id="landing-btn-arrow-down" onClick={scrollToRef(infoStartRef)}>
                <DropDown fontSize="inherit" />
              </DownArrow>
            </Container>
          </FullHeightPage>
          <Container style={{ paddingBottom: '6em', paddingTop: '3em' }} ref={infoStartRef}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Paragraph className="title">
                    <Trans
                      i18nKey="How does SmartBake® work?"
                      defaults="How does SmartBake<sup>®</sup> work?"
                      components={{ sup: <sup /> }}
                    />
                  </Paragraph>
                  <Paragraph>
                    {reactReplaceString(
                      i18n.t(
                        'SmartBake® makes it fast and simple for you to find the right enzyme and dosage for target shelf life in each application.'
                      ),
                      '®',
                      (match, i) => (
                        <sup key={i}>{match}</sup>
                      )
                    )}
                    {i18n.t(
                      'Based on our 30 years experience in fresh-keeping enzymes and powered by a predictive algorithm, this new tool will help you optimize your formulations faster So you can spend your time on what really matters growing your business.'
                    )}
                  </Paragraph>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src="smartbake_screenshot.png"
                  alt="Smartbake screenshot"
                  style={{
                    maxWidth: '80%',
                    display: 'block',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                />
              </Grid>
            </Grid>

            <Box my={4} />

            <Grid container spacing={4}>
              {isMobile ? VideoInfoBlocks.reverse() : VideoInfoBlocks}
            </Grid>
          </Container>
          <Footer>
            <LanguageSwitcher />
          </Footer>
        </Content>
      </PageRoot>
    </>
  );
};

export default LandingPage;
