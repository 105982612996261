import React, { useContext, useState, useEffect, useRef, MouseEvent } from 'react';
import { styled } from '../../theme';
import { ThemeContext } from '../../themecontext';

import { getBrowserHeight } from '../../helpers/general';
import AccordionArrow from './arrow';

import './accordion.css';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Accordion = (props) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion-icon');

  const context = useContext(ThemeContext);

  const { title } = props;
  const content = useRef(null);

  const setPosOverlay = (accordionHeight: number) => {
    let container: HTMLElement = document.querySelector('.container.learnmore');
    if (container !== null) {
      let containerHeight: any = container.scrollHeight + accordionHeight;
      container.classList.remove('top');
      if (containerHeight > getBrowserHeight()) {
        container.className += ' top';
      }
    }
  };

  useEffect(() => {
    if (setActive === 'active') {
      content.current !== 'undefined' ? setPosOverlay(content.current.children[0].offsetHeight) : '';
    }
  }, [setActive]);

  const toggleAccordion = (e: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setActiveState(setActive === '' ? 'active' : '');

    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);

    setRotateState(setActive === 'active' ? 'accordion-icon' : 'accordion-icon active');
  };

  return (
    <div className="accordion-section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <h3 className="accordion-title">{title}</h3>
        <AccordionArrow className={`${setRotate}`} />
      </button>
      <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion-content">
        <div className="accordion-text">{props.children}</div>
      </div>
    </div>
  );
};

export default Accordion;
