import React from 'react';

import { ThemeContext } from '../../themecontext';

import ApplicationPageEU from './index_eu';
import ApplicationPageCH from './index_ch';

export default class ApplicationPage extends React.PureComponent {
  static contextType = ThemeContext;

  render() {
    if (!this.context.region) {
      return null;
    }

    let page: any;

    switch (this.context.region) {
      case 're_CH':
        page = <ApplicationPageCH {...this.props}></ApplicationPageCH>;
        break;
      default:
        page = <ApplicationPageEU {...this.props}></ApplicationPageEU>;
    }

    return page;
  }
}
