export default {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'eu-central-1:f6c6c549-8e00-4fa0-afe9-a55c1b421feb',
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION || 'eu-central-1',
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'XX-XXXX-X',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || 'eu-central-1_egMHhDxJM', //'eu-central-1_K4ePNYCbU',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID || '3ijmntvpb1lev0bmor7bundjqa', //'18nt0b3l6c7n801ari6dhmrgdo',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    /*
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },

        // OPTIONAL - customized storage object
        //storage: new MyStorage(),
        */
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  } /*,
        AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: 'cd4113c6db5043ecad43c4870a9c56a9',
          // Amazon service region
          region: 'eu-central-1',
          mandatorySignIn: false,
        }
        */,
};
