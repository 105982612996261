import React, { useContext } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';

const Wrapper = styled('div')`
  margin: 20px 0 0;
`;

const Svg = styled('svg')`
  width: 25px;
  height: 25px;

  circle {
    fill: ${({ theme }) => theme.color.purple};
  }

  path {
    fill: #fff;
    fill-rule: evenodd;
  }
`;

const BackIcon = (
  <Wrapper>
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
      <circle cx="12.5" cy="12.5" r="12.5" />
      <path d="M8.68,13.2a4.59,4.59,0,0,1-.2-.56,22.33,22.33,0,0,1,3.92-2.13c1.18-.35,1.65.06,1.42,1.31s-.62,2.46-.91,3.7c-.13.51-.51,1.45-.13,1.72.38.1,1.16-.47,1.91-.78.16.3.3.42.15.55a9.76,9.76,0,0,1-3.9,2.26c-.76.19-1.28-.12-1.34-.87a4.14,4.14,0,0,1,.15-1.21c.23-1.07.49-2.12.73-3.19.09-.43.15-.86.26-1.53Z" />
      <path d="M11.65,7.38a1.66,1.66,0,0,1,1.48-1.93,1.42,1.42,0,0,1,1.58,1,1.87,1.87,0,0,1-1.58,2A1.25,1.25,0,0,1,11.65,7.38Z" />
    </Svg>
  </Wrapper>
);

const InfoIcon = () => {
  return BackIcon;
};

export default InfoIcon;
