import re_EU from './re_EU.json';
import re_NA from './re_NA.json';
import re_SA from './re_SA.json';
import re_CH from './re_CH.json';
import re_RAP from './re_RAP.json';
import re_IND from './re_IND.json';
import re_SEA from './re_SEA.json';
import re_ME from './re_ME.json';
import re_RU from './re_RU.json';
import re_JA from './re_JA.json';

import re_default from './re_default.json';

import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

/*
interface IRegionConfig {
    getConfig(s: string): string;
}
*/

class RegionConfig {
  private _user: CognitoUser = null;

  configs = {
    re_EU,
    re_NA,
    re_SA,
    re_CH,
    re_RAP,
    re_IND,
    re_SEA,
    re_ME,
    re_RU,
    re_JA,
  } as {
    [key: string]: any;
  };

  getConfig = async (setting) => {
    if (this._user == null) {
      this._user = await Auth.currentAuthenticatedUser().catch((err) => {
        /*console.log(err)*/
      });
    }
    const userRegion = this._user && this._user['attributes'] && this._user['attributes']['custom:region'];
    if (!userRegion) {
      return '';
    }
    const regionSpecificConfig = this.configs[userRegion];
    return !!regionSpecificConfig ? regionSpecificConfig[setting] : re_default[setting];
  };

  getUserRegion = async () => {
    if (this._user == null) {
      this._user = await Auth.currentAuthenticatedUser().catch((err) => {
        /*console.log(err)*/
      });
    }
    return this._user != null ? this._user['attributes']['custom:region'] : 're_EU';
  };

  getUser = async () => {
    if (this._user == null) {
      this._user = await Auth.currentAuthenticatedUser().catch((err) => {
        /*console.log(err)*/
      });
    }
    return this._user != null ? this._user['attributes'] : '';
  };
}

export default new RegionConfig();
