import React from 'react';
import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import { Trans } from 'react-i18next';
import WrapWithLink from '../../components/WrapWithLink';
import { Enzyme } from '../../types';
import ProductLinks from '../../static-data/ProductLinks';
import i18n from '../../i18NextConf';

const EnzymeInformationWrapper = styled('div')`
  background: ${({ theme }) => theme.color.lightgrey};
`;

const EnzymeInformation = styled('div')`
  position: relative;
  background: #113f49;
  padding: 46px;

  .enzyme-info-text:last-of-type {
    margin-bottom: 0 !important;
  }

  ${MediaQueries.large} {
    padding: 73px 90px 65px;
  }

  ${MediaQueries.medium} {
    padding: 93px 46px 65px;
    margin-left: auto;
    margin-right: auto;
  }

  ${MediaQueries.small} {
    padding: 93px 46px 45px;
  }
`;
const OKResult = styled('div')`
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MediaQueries.large} {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
  }

  ${MediaQueries.medium} {
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
  }

  ${MediaQueries.small} {
    width: 50px;
    height: 50px;
  }
`;

const CheckmarkSVG = styled('div')`
  ${MediaQueries.large} {
    width: 70px;
    height: 70px;
  }

  ${MediaQueries.medium} {
    width: 55px;
    height: 55px;
  }

  svg polyline {
    animation: checkmark-animation 0.5s linear forwards;
    animation-delay: 0.4s;
    animation-direction: normal;
    animation-iteration-count: 1;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    stroke: #fff;
    fill: none;
    stroke-width: 4px;
  }

  svg circle {
    fill: #c5da00;
  }

  @keyframes checkmark-animation {
    0% {
      stroke-dasharray: 0, 100;
    }
    100% {
      stroke-dasharray: 100, 0;
    }
  }
`;

const EnzymeInformationText = styled('div')`
  font-size: 22px;
  line-height: 31px;
  margin: 0 auto 40px;
  color: #fff;
  text-align: center;
  max-width: 680px;

  sup {
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const ResultsCheckmark = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
    <circle cx="27.5" cy="27.5" r="27.5" />
    <polyline points="15 28.66 26.66 38.2 39.86 15" />
  </svg>
);

interface ResultIntroProps {
  region: string;
  application: string;
  recommendation: Enzyme;
  alternatives: Enzyme[];
}

const ResultIntro = ({ region, application, recommendation, alternatives }: ResultIntroProps) => {
  const language = i18n.languages[0];

  const recommendationName = i18n.t(recommendation || '');
  const recommendationLink = recommendation && ProductLinks(language)[recommendation].info;

  const alternativeName = i18n.t(alternatives[0] || '');
  const alternativeLink = alternatives[0] && ProductLinks(language)[alternatives[0]].info;

  const hasNoAlternatives = !alternatives.length;

  return (
    <EnzymeInformationWrapper className="negative-margin mobile medium">
      <EnzymeInformation className="EnzymeInformation">
        <OKResult>
          <CheckmarkSVG>{ResultsCheckmark}</CheckmarkSVG>
        </OKResult>
        {region === 're_CH' || hasNoAlternatives ? (
          <>
            <EnzymeInformationText className="enzyme-info-text">
              <Trans
                i18nKey="Novozymes recommends using Novamyl® 3D BG for your target shelf life. At the dosage below, Novamyl® 3D BG will improve the bread crumb softness significantly and in addition your bread will achieve a higher moistness perception throughout storage."
                values={{
                  recommendationName: recommendationName.replace('®', '<sup>®</sup>'),
                }}
                components={{
                  recommendLink: (
                    <a
                      href={recommendationLink}
                      target="_blank"
                      id="result-header-product-page"
                      style={{
                        fontWeight: 'bold',
                        color: 'inherit',
                        textDecoration: 'inherit',
                      }}
                    />
                  ),

                  sup: <sup />,
                }}
              />
            </EnzymeInformationText>
          </>
        ) : (
          <>
            <EnzymeInformationText className="enzyme-info-text">
              <Trans
                i18nKey="Novozymes recommends using Novamyl® 3D BG for your target shelf life or Novamyl® 10000 BG as an alternative."
                values={{
                  recommendationName: recommendationName.replace('®', '<sup>®</sup>'),
                  alternativeName: alternativeName.replace('®', '<sup>®</sup>'),
                }}
                components={{
                  recommendLink: (
                    <a
                      href={recommendationLink}
                      target="_blank"
                      id="result-header-product-page"
                      style={{
                        fontWeight: 'bold',
                        color: 'inherit',
                        textDecoration: 'inherit',
                      }}
                    />
                  ),
                  altLink: (
                    <a
                      href={alternativeLink}
                      target="_blank"
                      id="result-header-product-page"
                      style={{
                        fontWeight: 'bold',
                        color: 'inherit',
                        textDecoration: 'inherit',
                      }}
                    />
                  ),
                  sup: <sup />,
                }}
              />
            </EnzymeInformationText>

            <EnzymeInformationText className="enzyme-info-text">
              {application === 'RyeBread' ? (
                <Trans
                  i18nKey="At the dosages below, while Novamyl® Rye BG and Novamyl® 3D BG give equal bread crumb softness, we recommend Novamyl® Rye BG as it is developed specifically for rye bread application and is highly effective in low pH rye bread made with sourdough."
                  components={{
                    sup: <sup />,
                  }}
                />
              ) : (
                <Trans
                  i18nKey={alternatives.length === 1 ? 'Recommend text sub 2 enzymes' : 'Recommend text sub 3 enzymes'}
                  values={{
                    recommend: recommendationName.replace('®', '<sup>®</sup>'),
                    alternative1: i18n.t(alternatives[0] || '').replace('®', '<sup>®</sup>'),
                    alternative2: i18n.t(alternatives[1] || '').replace('®', '<sup>®</sup>'),
                  }}
                  components={{
                    sup: <sup />,
                  }}
                />
              )}
            </EnzymeInformationText>
          </>
        )}
      </EnzymeInformation>
    </EnzymeInformationWrapper>
  );
};

export default ResultIntro;
