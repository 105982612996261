import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import SvgArrow from './recommendations_arrow.svg';
import i18n from '../../i18NextConf';
import { ApplicationType, getApplicationParamTypes, getParamLabel } from '../../pages/application/application-config';
import { Recommendation } from '../../types';

interface SavedRecommendationProps {
  closeMenu: () => void;
  resetHistory: () => void;
  savedData: Recommendation;
}

const Wrapper = styled(Link)`
  position: relative;
  background-color: transparent;
  padding: 0px;
  display: block;
  text-decoration: none;
  color: inherit;
  z-index: 100;
  margin-top: 20px;

  ${MediaQueries.small} {
    margin-top: 0;
  }

  &:after {
    display: block;
    height: 1px;
    margin: 20px 0;
    background: #dbdddb;
    content: '';

    ${MediaQueries.small} {
      margin: 10px 0;
    }
  }
`;

const Div = styled('div')`
  &.flex {
    display: flex;
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.arrow {
    flex-basis: 30px;
    background: url(${SvgArrow}) no-repeat 50% 50%;
    background-size: 8px auto;
  }

  &.content {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 5px;
  }

  .date {
    color: #113f49;
    font-size: 14px;
    line-height: 23px;
  }
`;

const SubHeading = styled('div')`
  margin-bottom: 5px;
`;
const removeBodyScroll = () => {
  document.querySelector('html').classList.remove('noscroll');
};

const SavedRecommendation = ({ savedData, closeMenu, resetHistory }: SavedRecommendationProps) => {
  const usedParams = getApplicationParamTypes(savedData.parameters.application);

  const closeMainNavi = () => {
    closeMenu();
  };

  const loadRecommendation = () => {
    localStorage.setItem('recipe-param-application', String(savedData.parameters.application));
    localStorage.setItem('recipe-param-sugar', String(savedData.parameters.sugar));
    localStorage.setItem('recipe-param-fat', String(savedData.parameters.fat));
    localStorage.setItem('recipe-param-targetShelfLife', String(savedData.parameters.targetShelfLife));
    removeBodyScroll();
    closeMainNavi();
    resetHistory();
  };

  return (
    <Wrapper
      id="menu-saved-recommendation"
      to={{
        pathname: '/ResultsPage',
      }}
      onClick={loadRecommendation}
    >
      <Div className="flex row align-center">
        <Div className="flex content">
          <Div className="flex column">
            <Div className="date">{moment(savedData.created).format(i18n.t('DD/MM/YYYY'))}</Div>
            <Div className="title">{savedData.title}</Div>
            <SubHeading>{savedData.comment}</SubHeading>
            <SubHeading>
              {Object.keys(savedData.parameters)
                .filter((key) => usedParams.includes(key))
                .map(
                  (key) =>
                    `${getParamLabel(savedData.parameters.application as ApplicationType, key)}: ${
                      savedData.parameters[key]
                    }`
                )
                .join(' | ')}
            </SubHeading>
          </Div>
        </Div>
        <Div className="arrow"></Div>
      </Div>
    </Wrapper>
  );
};

export default SavedRecommendation;
