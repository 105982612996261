import React from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

const Wrapper = styled.div`
  font-family: inherit;
  margin-bottom: 20px;

  ${MediaQueries.large} {
    font-size: 18px;
    line-height: 28px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .orange {
    font-weight: bold;
    color: ${({ theme }) => theme.color.orange};
  }

  .green {
    font-weight: bold;
    color: ${({ theme }) => theme.color.green};
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin: 0 0 6px;

  ${MediaQueries.large} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
  }
`;
const Description = styled.div``;

type Props = {
  title?: string;
  children: React.ReactNode;
  maxwidth?: number;
};

const EnzymeDescr = (props: Props) => {
  const { title = '', children } = props;

  return (
    <Wrapper>
      {title !== '' && <Title>{title}</Title>}
      <Description>{children}</Description>
    </Wrapper>
  );
};

export default EnzymeDescr;
