import React from 'react';

import { ThemeContext } from '../../themecontext';

import OverviewPageEU from './index_eu';
import OverviewPageCH from './index_ch';

interface Props {
  children: React.ReactNode;
  device?: string;
}

export default class OverviewPage extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  render() {
    if (!this.context.region) {
      return null;
    }

    let page: any;

    switch (this.context.region) {
      case 're_CH':
        page = <OverviewPageCH {...this.props}></OverviewPageCH>;
        break;
      default:
        page = <OverviewPageEU {...this.props}></OverviewPageEU>;
    }

    return page;
  }
}
