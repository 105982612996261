import React, { useContext, useEffect, createRef } from 'react';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import Header from './parts/header';
import InfoIcon from './parts/info-icon';
import Accordion from '../accordion';
import CloseModal from './parts/close-modal';

import BanImg from './images/ban.jpg';
import NovamylImg from './images/novamyl.jpg';
import NaviArrow from '../navigation/navi_arrow.svg';

const Title = styled('div')<{
  learnMorePage: boolean;
}>`
  font-size: 28px;
  line-height: 36px;
  ${({ learnMorePage }) =>
    !learnMorePage &&
    `
    padding-left: 8px;
  `}
`;

const Content = styled('div')`
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0 30px;
`;

const Paragraph = styled('p')`
  margin-bottom: 20px;
`;

const Image = styled('img')`
  width: 100%;
  height: auto;
`;

const Div = styled('div')`
  display: flex;
  justify-content: space-between;

  > p {
    width: calc(50% - 8px);
  }
`;

const Span = styled('span')`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin-top: 10px;
`;

const H3 = styled('h3')`
  font-size: 24px;
  line-height: 25px;
  font-weight: bold;
  margin: 0 0 30px;
`;

const RelatedSubject = styled('div')`
  position: relative;
  border-top: solid 1px #dbdddb;
  border-bottom: solid 1px #dbdddb;
  margin: 0 0 40px;
  font-weight: bold;
  cursor: pointer;

  ${MediaQueries.large} {
    padding: 28px 0;
  }

  ${MediaQueries.medium} {
    padding: 20px 0;
  }

  ${MediaQueries.small} {
    padding: 20px 20px 20px 0;
  }

  &:before {
    position: absolute;
    right: 6px;
    top: 50%;
    background: url(${NaviArrow}) no-repeat right 50%;
    background-size: 8px auto;
    transform: translateY(-50%);
    width: 8px;
    height: calc((91.1 / 55.08) * 8px);
    content: '';
    z-index: 1;
    pointer-events: none;

    ${MediaQueries.small} {
      background-size: 6px auto;
      width: 6px;
      height: calc((91.1 / 55.08) * 6px);
    }
  }
`;

interface Props {
  learnMorePage?: boolean;
  onClose?: () => void;
  openModal?: any;
  shownFromNavi?: boolean;
  showComponent?: (value: string) => any;
}

const FactorsEffectingFreshkeeping = (props: Props) => {
  const { shownFromNavi = false, learnMorePage = true, onClose, showComponent } = props;

  let title: string = i18n.t('Factors affecting fresh-keeping');

  const openSoftness = (subject: string) => {
    const { openModal } = props;
    openModal(subject);
  };

  const showComponentInNavi = (component: string) => {
    showComponent(component);
  };

  return (
    <>
      {!shownFromNavi && <Header learnMorePage={learnMorePage} title={title} />}

      <Content>
        {shownFromNavi && (
          <>
            <InfoIcon />
            <H3>{title}</H3>
          </>
        )}

        <Accordion className="accordion" title={i18n.t('The effect of storage temperature on freshness')}>
          <div>
            <Paragraph>
              {i18n.t(
                'The storage temperature can affect the rate of staling. The lower the temperature, the faster the staling i.e. the bread will get harder faster. This can be mitigated by increasing the enzyme dosage.'
              )}
            </Paragraph>
          </div>
        </Accordion>

        <Accordion className="accordion" title={i18n.t('The effect of Novamyl® on bread appearance')}>
          <div>
            <Paragraph>
              {i18n.t(
                'Novamyl®, although it greatly improves the fresh-keeping properties of bread, it does not influence bread volume and crust color or the dough handling properties.'
              )}
            </Paragraph>
          </div>
        </Accordion>

        <Accordion className="accordion" title={i18n.t('The effect of packaging on freshness')}>
          <div>
            <Paragraph>
              {i18n.t(
                'Packaging material can affect the fresh-keeping properties of your bread. Contact you supplier to learn more.'
              )}
            </Paragraph>
          </div>
        </Accordion>

        <Accordion className="accordion" title={i18n.t('The effect of bacterial alpha amylases on freshness')}>
          <div>
            <Paragraph>
              {i18n.t(
                'Bacterial alpha amylases such as BAN 800 MG were originally used to extend fresh-keeping properties but have been replaced by Novamyl® maltogenic alpha amylases as they lead to inelastic and gummy crumb texture.'
              )}
            </Paragraph>
            <Div>
              <Paragraph>
                <Image src={NovamylImg} alt="" />
                <Span>{i18n.t('Novamyl®')}</Span>
              </Paragraph>
              <Paragraph>
                <Image src={BanImg} alt="" />
                <Span>{i18n.t('BAN')}</Span>
              </Paragraph>
            </Div>
          </div>
        </Accordion>
      </Content>

      {shownFromNavi ? (
        <RelatedSubject
          onClick={(e: React.MouseEvent) => {
            showComponentInNavi('SoftnessOfTheCrumb');
          }}
        >
          {i18n.t('How is the softness of the crumb measured?')}
        </RelatedSubject>
      ) : (
        <RelatedSubject
          onClick={(e: React.MouseEvent) => {
            openSoftness('softness');
          }}
        >
          {i18n.t('How is the softness of the crumb measured?')}
        </RelatedSubject>
      )}

      {!shownFromNavi && <CloseModal topMargin={10} bottomMargin={20} closeModal={onClose} />}
    </>
  );
};

export default FactorsEffectingFreshkeeping;
