import { Auth } from '@aws-amplify/auth';
import axios from 'axios';

// Setup to point to prod while waiting for luis to be back to get overview of environments
const BASE_URL = process.env.REACT_APP_API_HOST || 'https://smartbake-api.ssp-s1-sandbox.novozymes.cloud';

// axios.defaults.withCredentials = true

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([401, 403].includes(error.response.status)) {
      Auth.signOut({})
        .then((data) => window.location.replace('/'))
        .catch((err) => console.log(err));
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(
  async function (config) {
    let token = null;

    await Auth.currentSession()
      .then((data) => {
        if (!data) return;
        token = (<any>data).accessToken.jwtToken;
      })
      .catch((err) => {
        console.log(err);
      });

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export const fetchPreviousRecommendationsAPI = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BASE_URL}/applications/results/history`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Error on fetchPreviousRecommendations:${error.message}`);
  }
};

export const saveApplicationSuggestionAPI = async (applicationName: string) => {
  const data = {
    name: applicationName,
  };
  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/applications/suggestion`,
      data,
    });
    return response;
  } catch (error) {
    throw new Error(`Error on saveApplicationSuggestionAPI:${error.message}`);
  }
};

export const calculateResultAPI = async (parameters: {
  application: string;
  region?: string;
  sugar?: number;
  fat?: number;
  egg?: number;
  ryeFlour?: number;
  targetShelfLife?: number;
}) => {
  const data = {
    parameters: parameters,
  };
  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/applications/results/calculate`,
      data,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error on calculateResultAPI:${error.message}`);
  }
};

export const saveResultAPI = async (
  parameters: {
    application: string;
    sugar?: number;
    fat?: number;
    targetShelfLife?: number;
    egg?: number;
    ryeFlour?: number;
  },
  comment: string,
  title: string
) => {
  const data = {
    parameters: parameters,
    comment,
    title,
  };
  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/applications/results/save`,
      data,
    });
    return response;
  } catch (error) {
    throw new Error(`Error on saveResultAPI:${error.message}`);
  }
};

// TODO delete
export const contactProblemAPI = async (
  name: string,
  email: string,
  phone: string,
  company: string,
  problem: string,
  comment: string
) => {
  const data = {
    name: name,
    email: email,
    phone: phone,
    company: company,
    problem: problem,
    comment: comment,
  };
  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/v1/contact/problem`,
      data,
    });
    return response;
  } catch (error) {
    throw new Error(`Error on contactProblemAPI:${error.message}`);
  }
};
