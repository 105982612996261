import React from 'react';
import { ResponsiveLine } from '@nivo/line';

import { EnzymeGraphType } from '../../types';
import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

const Wrapper = styled.div`
  width: 56%;
  display: flex;
  justify-content: flex-end;
`;

const InnerWrapper = styled.div<{
  graphWidth: string;
  currentRegion: string;
}>`
  position: relative;
  width: ${({ graphWidth }) => graphWidth};
  width: 432px;
  background: ${({ theme }) => theme.color.lightgrey}
    ${({ currentRegion }) =>
      currentRegion !== 're_CH' &&
      `
    height: calc(432px + 96px);

    ${MediaQueries.medium} {
      width: 360px;
      height: calc(360px + 96px);
    }
  `}
    ${({ currentRegion }) =>
      currentRegion === 're_CH' &&
      `
    height: calc(432px + 66px);

    ${MediaQueries.medium} {
      width: 360px;
      height: calc(360px + 66px);
    }

  `}
    ${MediaQueries.small} {
    width: 100%;
    height: calc((100vw - 2 * 20px) + 96px);
  }

  * {
    font-family: 'Novozymes' !important;
  }
`;

const TextTop = styled.div`
  display: inline-block;
  font-size: 15px;
`;

const TextTopWrapper = styled.div<{
  currentLang: string;
}>`
  position: absolute;
  top: 16px;
  left: 0;
  width: 54px;
  text-align: right;

  ${({ currentLang }) =>
    currentLang === 'zh' &&
    `
    left: 10px;
  `}

  ${MediaQueries.medium} {
    top: 22px;
  }

  ${MediaQueries.small} {
    top: 18px;
  }
`;

const TextBottomWrapper = styled.div<{
  currentLang: string;
}>`
  position: absolute;
  top: 402px;
  left: 0;
  width: 54px;
  text-align: right;

  ${({ currentLang }) =>
    currentLang === 'zh' &&
    `
    top: 410px;
    left: 10px;
  `}

  ${MediaQueries.medium} {
    top: 330px;
  }

  ${MediaQueries.small} {
    top: calc(100% - 120px);
  }
`;

const TextBottom = styled.div`
  display: inline-block;
  font-size: 15px;
`;

type Props = {
  width?: string;
  data: Array<EnzymeGraphType>;
  legendLeft: string;
  legendBottom: string;
  textTop: string;
  textBottom: string;
  device?: any;
  region?: string;
  lang?: string;
  spaceXAxis?: boolean;
};

class EnzymeGraph extends React.PureComponent<Props> {
  render() {
    const {
      data,
      legendLeft,
      legendBottom,
      textTop,
      textBottom,
      width = '',
      device,
      region = 're_EU',
      spaceXAxis,
    } = this.props;

    let lang: string = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng') : 'en';
    let responsiveLineBottom: number = region === 're_EU' ? 146 : 146;

    /* calculate min/max for graphs */
    let theYMin = 1000000;
    let theYMax = -1000000;

    data.slice(0, data.length - 1).forEach((point) => {
      if (point.min < theYMin) {
        theYMin = point.min;
      }
      if (point.max > theYMax) {
        theYMax = point.max;
      }
    });

    const targetShelfLife = localStorage.getItem('recipe-param-targetShelfLife');

    return (
      <Wrapper className="graph">
        <InnerWrapper graphWidth={width !== '' ? width : '100%'} currentRegion={region}>
          <TextTopWrapper currentLang={lang}>
            <TextTop className="TextTop">{textTop}</TextTop>
          </TextTopWrapper>
          <ResponsiveLine
            data={data}
            margin={{
              top: 50,
              right: 36,
              bottom: responsiveLineBottom,
              left: 64,
            }}
            xScale={{
              type: 'point',
            }}
            yScale={{
              type: 'linear',
              stacked: false,
              /*
              min: yMin,
              max: yMax,
              */
              min: theYMin,
              max: theYMax,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: legendBottom,
              legendOffset: 32,
              legendPosition: 'middle',
              format: (value: number) => {
                return !(value % (spaceXAxis ? 15 : 7)) || targetShelfLife === value.toString() || value === 1
                  ? value.toString()
                  : '';
              },
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: legendLeft,
              legendOffset: -45,
              legendPosition: 'middle',
            }}
            enablePoints={false}
            animate={true}
            isInteractive={true}
            enableCrosshair={true}
            enableSlices={'x'}
            colors={data.map((v) => v.color)}
            legends={[
              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: device.desktop ? -86 : device.mobile ? -10 : -50,
                translateY: 130,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 160,
                itemHeight: 25,
                itemOpacity: 0.95,
                symbolSize: 12,
                symbolShape: 'square',
              },
            ]}
          />
          <TextBottomWrapper currentLang={lang}>
            <TextBottom className="Text">{textBottom}</TextBottom>
          </TextBottomWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default EnzymeGraph;
