import React, { useContext } from 'react';
import getStarRatings, { StarRating } from '../../helpers/getStarRatings';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import { ThemeContext } from '../../themecontext';

const CircleIndicatorsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: calc(100% / 3);

  ${MediaQueries.small} {
    align-items: start;
  }
`;

const CircleIndicators = styled.div`
  display: inline-flex;

  > span {
    display: inline-block;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-right: 6px;

    ${MediaQueries.small} {
      margin-right: 3px;
      margin-bottom: 6px;
      width: 7px;
      height: 7px;
    }

    &.green {
      background: ${({ theme }) => theme.color.green};
    }

    &.orange {
      background: ${({ theme }) => theme.color.orange};
    }

    &.lightgrey {
      background: #ced0cd;
    }

    &.grey {
      background: #838c85;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const Indicators = ({ type, ratings }: { type: string; ratings: StarRating }) => {
  const getColor = () => {
    switch (type) {
      case 'recommended':
        return 'green';
      case 'alternative':
        return 'orange';
      default:
        return 'grey';
    }
  };

  const color = getColor();

  return (
    <>
      <CircleIndicatorsWrapper>
        <CircleIndicators>
          {Array.from(Array(ratings.softness).keys()).map((_, index) => (
            <span className={color} key={`softness` + index}></span>
          ))}
          {Array.from(Array(4 - ratings.softness).keys()).map((_, index) => (
            <span className="lightgrey" key={`softnessGrey` + index}></span>
          ))}
        </CircleIndicators>
      </CircleIndicatorsWrapper>

      <CircleIndicatorsWrapper>
        <CircleIndicators>
          {Array.from(Array(ratings.elasticity).keys()).map((_, index) => (
            <span className={color} key={`elasticity` + index}></span>
          ))}
          {Array.from(Array(4 - ratings.elasticity).keys()).map((_, index) => (
            <span className="lightgrey" key={`elasticityGrey` + index}></span>
          ))}
        </CircleIndicators>
      </CircleIndicatorsWrapper>

      <CircleIndicatorsWrapper>
        <CircleIndicators>
          {Array.from(Array(ratings.moistness).keys()).map((_, index) => (
            <span className={color} key={`moistness` + index}></span>
          ))}
          {Array.from(Array(4 - ratings.moistness).keys()).map((_, index) => (
            <span className="lightgrey" key={`moistnessGrey` + index}></span>
          ))}
        </CircleIndicators>
      </CircleIndicatorsWrapper>
    </>
  );
};

type Props = {
  type: string;
  enzymeName: string;
  application: string;
  region: string;
};

const EnzymeIndicators = (props: Props) => {
  const { type, enzymeName, application, region } = props;

  const ratings = getStarRatings(enzymeName, application, region);

  return (
    <>
      <Indicators type={type} ratings={ratings} />
    </>
  );
};

export default EnzymeIndicators;
