import React, { useContext } from 'react';

import { Theme } from '../../theme';
import { ThemeContext } from '../../themecontext';

import VideoEU from './video_eu';
import VideoCH from './video_ch';

type Props = {
  id?: string;
  url?: string;
  title?: string;
};

const Video = (props: Props) => {
  if (props.id) {
    return <VideoEU {...props}></VideoEU>;
  } else if (props.url) {
    return <VideoCH {...props}></VideoCH>;
  }

  return null;
};

export default Video;
