import React, { useContext } from 'react';
import { ThemeContext } from '../../themecontext';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import Header from './parts/header';
import InfoIcon from './parts/info-icon';
import LearnMoreGraphics from './graphics';
import CloseModal from './parts/close-modal';

import NaviArrow from '../navigation/navi_arrow.svg';

const Title = styled('div')`
  font-size: 28px;
  line-height: 36px;
`;

const Content = styled('div')`
  font-size: 16px;
  line-height: 24px;
`;

const Paragraph = styled('p')`
  margin-bottom: 20px;
`;

const Image = styled('img')`
  width: 100%;
  height: auto;
  margin: 20px 0 30px;
`;

const H3 = styled('h3')`
  font-size: 24px;
  line-height: 25px;
  font-weight: bold;
  margin: 0 0 30px;
`;

const RelatedSubject = styled('div')`
  position: relative;
  border-top: solid 1px #dbdddb;
  border-bottom: solid 1px #dbdddb;
  margin: 0 0 40px;
  font-weight: bold;
  cursor: pointer;

  ${MediaQueries.large} {
    padding: 28px 0;
  }

  ${MediaQueries.medium} {
    padding: 20px 0;
  }

  ${MediaQueries.small} {
    padding: 20px 20px 20px 0;
  }

  &:before {
    position: absolute;
    right: 6px;
    top: 50%;
    background: url(${NaviArrow}) no-repeat right 50%;
    background-size: 8px auto;
    transform: translateY(-50%);
    width: 8px;
    height: calc((91.1 / 55.08) * 8px);
    content: '';
    z-index: 1;
    pointer-events: none;

    ${MediaQueries.small} {
      background-size: 6px auto;
      width: 6px;
      height: calc((91.1 / 55.08) * 6px);
    }
  }
`;

interface Props {
  learnMorePage?: boolean;
  onClose?: () => void;
  openModal?: any;
  shownFromNavi?: boolean;
  showComponent?: (value: string) => any;
}

const ElasticityOfTheCrumb = (props: Props) => {
  const context = useContext(ThemeContext);
  const { region, lang } = context;

  const { shownFromNavi = false, learnMorePage = true, onClose, showComponent } = props;
  let title: string = i18n.t('How is elasticity of the crumb measured?');

  const openFreshkeepingFactors = (subject: string) => {
    const { openModal } = props;
    openModal(subject);
  };

  const showComponentInNavi = (component: string) => {
    showComponent(component);
  };

  return (
    <>
      {!shownFromNavi && (
        <Header learnMorePage={learnMorePage} title={i18n.t('How is elasticity of the crumb measured?')} />
      )}

      <Content>
        {shownFromNavi && (
          <>
            <InfoIcon />
            <H3>{title}</H3>
          </>
        )}

        <Paragraph>
          {i18n.t(
            'Elasticity of the crumb is measured on a 25 mm thick slice of bread using a texture analyser (Stable Micro Systems). Elasticity (% ratio) = (P3/P2) * 100%.'
          )}
        </Paragraph>
        <Paragraph>{i18n.t('P2: Maximum force needed to press the probe 28% into the crumb.')}</Paragraph>
        <Paragraph>{i18n.t('P3: Force needed to keep the probe at 28% for 30 seconds.')}</Paragraph>

        <Image src={LearnMoreGraphics(lang).graph_1} alt="" />
      </Content>

      {shownFromNavi ? (
        <RelatedSubject
          onClick={(e: React.MouseEvent) => {
            showComponentInNavi('FactorsEffectingFreshkeeping');
          }}
        >
          {i18n.t('Factors affecting fresh-keeping')}
        </RelatedSubject>
      ) : (
        <RelatedSubject
          onClick={(e: React.MouseEvent) => {
            openFreshkeepingFactors('freshkeeping');
          }}
        >
          {i18n.t('Factors affecting fresh-keeping')}
        </RelatedSubject>
      )}

      {!shownFromNavi && <CloseModal topMargin={10} bottomMargin={20} closeModal={onClose} />}
    </>
  );
};

export default ElasticityOfTheCrumb;
