import React from 'react';
import { styled } from './theme';
import Button from './components/button';
import i18n from './i18NextConf';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  background-color: #f6f6f6;
  justify-content: center;
  padding-top: 64px;
  min-height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled('div')`
  position: relative;
  margin-bottom: 22px;
  color: inherit;
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  white-space: nowrap;
  color: inherit;
`;

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Page>
          <Content>
            <Title>{i18n.t('Something went wrong.')} </Title>
            <Button
              id="error-button-back"
              minWidth={225}
              marginRight={0}
              primary
              onClick={() => {
                window.location.assign('/');
              }}
            >
              {i18n.t('Back to start page.')}
            </Button>
          </Content>
        </Page>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
