import { colors } from '@novozymes-digital/components';
import React from 'react';

import { styled } from '../theme';

const InputWrapper = styled.div<{
  focused: boolean;
  minWidth: number;
  maxWidth: number;
  marginBottom: number;
}>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  border: solid 1px #${({ focused }) => (focused ? 'e7e7e7' : 'e7e7e7')};
  background-color: #f6f6f5;
  padding: 0;
  transition: margin 0.35s ease;

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
`;
const InputLabel = styled.div`
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  user-select: none;
  white-space: nowrap;
  margin-bottom: 10px;
`;
const InputValue = styled.input`
  font-size: 16px;
  line-height: normal !important;
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  border: 0;
  background: #f6f6f5;
  color: inherit;
  outline: none;
  height: 50px;
`;
const InputUnit = styled.div`
  position: absolute;
  top: 34px;
  left: 125px;
  right: 0;
  height: 25px;
  font-family: Novozymes;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  user-select: none;
  line-height: 1.39;
  letter-spacing: normal;
  color: inherit;
  box-sizing: border-box;
  transition: color;
  white-space: nowrap;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.61, 0.005, 0.225, 1.335);
`;

export default class Input extends React.PureComponent<
  {
    value: any;
    unit?: string;
    label: string;
    minWidth: number;
    maxWidth: number;
    marginBottom?: number;
    pattern?: string;
    error: boolean;
    onValue: (value: string) => void;
    className?: string;
    placeholder?: string;
  },
  { focused: boolean }
> {
  state = {
    focused: false,
  };
  onFocus = () => {
    this.setState({ focused: true });
  };
  onBlur = () => {
    this.setState({ focused: false });
  };
  onValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onValue(e.target.validity.valid ? e.target.value : this.props.value);
  };

  render() {
    const { focused } = this.state;
    const { value, unit, label, minWidth, maxWidth, marginBottom, pattern, className, placeholder } = this.props;

    return (
      <>
        <InputLabel>{label}</InputLabel>
        <InputWrapper
          focused={focused}
          minWidth={minWidth}
          maxWidth={maxWidth}
          marginBottom={marginBottom === undefined ? 0 : marginBottom}
        >
          {unit === undefined ? null : <InputUnit>{unit}</InputUnit>}
          <InputValue
            value={value}
            type="text"
            pattern={pattern === undefined ? '[a-zA-Z0-9.s]*' : pattern}
            onChange={this.onValue}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            className={className}
            placeholder={placeholder}
          />
        </InputWrapper>
      </>
    );
  }
}
