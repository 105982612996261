const getVideoUrlForLang = (lang: string) => {
  switch (lang) {
    case 'zh':
      return 'https://player.youku.com/embed/XNDQyNTc0Njc4MA==';
    case 'ru':
      return 'https://www.novozymes.tv/v.ihtml/player.html?source=share&photo%5fid=63286545';
    default:
      return 'https://www.novozymes.tv/v.ihtml/player.html?source=share&photo%5fid=62539068';
  }
};

export default getVideoUrlForLang;
