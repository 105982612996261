import React, { useContext } from 'react';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import Header from './parts/header';
import InfoIcon from './parts/info-icon';
import Button from '../button';
import NaviArrow from './images/navi_arrow.svg';

const Title = styled('div')`
  font-size: 28px;
  line-height: 36px;
`;

const Content = styled('div')`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const Ul = styled('ul')`
  list-style: none;
`;
const Li = styled('li')`
  position: relative;
  border-bottom: solid 1px #dbdddb;
  font-weight: bold;

  ${MediaQueries.large} {
    font-size: 18px;
    line-height: 24px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }

  a {
    padding: 28px 0;
    color: inherit;
    text-decoration: none;
    display: block;

    ${MediaQueries.small} {
      padding: 12px 20px 12px 0;
    }
  }

  &:before {
    position: absolute;
    right: 6px;
    top: 50%;
    background: url(${NaviArrow}) no-repeat right 50%;
    background-size: 8px auto;
    transform: translateY(-50%);
    width: 8px;
    height: calc((91.1 / 55.08) * 8px);
    content: '';
    z-index: 1;
    pointer-events: none;

    ${MediaQueries.small} {
      background-size: 6px auto;
      width: 6px;
      height: calc((91.1 / 51) * 6px);
    }
  }
`;

const TitleText = `
  How is elasticity of the crumb measured?
`;

interface Props {
  showBtn?: boolean;
  showHeader?: boolean;
  showComponent?: (component: string) => void;
}

const LearnMore = (props: Props) => {
  const { showBtn = true, showHeader = true, showComponent } = props;

  return (
    <>
      {showHeader && <Header />}
      {/*<InfoIcon />*/}
      <Content>
        <Ul>
          <Li>
            <a
              href="#"
              onClick={(e: React.MouseEvent) => {
                showComponent('FactorsEffectingFreshkeeping');
              }}
            >
              {i18n.t('Factors affecting fresh-keeping')}
            </a>
          </Li>
          <Li>
            <a
              href="#"
              onClick={(e: React.MouseEvent) => {
                showComponent('SoftnessOfTheCrumb');
              }}
            >
              {i18n.t('How is the softness of the crumb measured')}
            </a>
          </Li>
          <Li>
            <a
              href="#"
              onClick={(e: React.MouseEvent) => {
                showComponent('ElasticityOfTheCrumb');
              }}
            >
              {i18n.t('How is the elasticity of the crumb measured')}
            </a>
          </Li>
        </Ul>
      </Content>
      {showBtn && (
        <Button minWidth={200} primary={false}>
          {i18n.t('Close')}
        </Button>
      )}
    </>
  );
};

export default LearnMore;
