import React from 'react';

import { styled } from '../../theme';
import NovozymesLogo from '../novozymes-logo';
import Logo from '../logo';

import { Auth } from 'aws-amplify';
import { MediaQueries } from '../../theme/media-queries-css';

import { ThemeContext } from '../../themecontext';
import { colors } from '@novozymes-digital/components';

const SidebarWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  ${MediaQueries.large} {
    height: 100%;
    padding: 56px 70px 0;
  }

  @media screen and (max-width: 1280px) and (min-width: 993px) {
    padding: 56px 40px 0;
  }

  ${MediaQueries.medium} {
    width: 100%;
    padding: 39px 40px;
  }

  ${MediaQueries.small} {
    padding: 39px 40px 10px;
  }
`;

const SidebarContent = styled('div')<{
  isResultsPage: boolean;
}>`
   flex-grow: 1;
   margin-bottom: 48px;
   position: relative;

   ${MediaQueries.medium} {
     margin-bottom: 0;
   }

   sup {
     font-size: 16px;
     line-height: 1;
     display: inline-block;
     vertical-align: top;
   }


  .application-subtitle {
    padding-top: 14px;

    ${({ isResultsPage }) =>
      !isResultsPage &&
      `
      ${MediaQueries.medium} {
        max-width: 260px;
      }
    `}

    ${MediaQueries.medium} {
        display: none;

        &.show {
          display: block;
        }
      }
    }

  }

  .application-header {
    max-width: 260px;

    ${MediaQueries.small} {
      max-width: 230px;
    }
  }

`;

const TopContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoutButtonWrapper = styled.button`
  z-index: 1;
  cursor: pointer;
  height: 42px;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: right;
  padding: 0px;
  margin-top: -8px;

  img {
    display: inline-block;
  }
`;

const LogoWrapper = styled('div')`
  ${MediaQueries.large} {
    margin-bottom: 80px;
  }

  ${MediaQueries.medium} {
    padding-bottom: 0px;
  }
`;

const NovozymesLogoWrapper = styled('div')`
  position: relative;
  margin-bottom: 20px;

  ${MediaQueries.medium} {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Label = styled('div')`
  font-family: Novozymes;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black80};
  margin-top: 36px;

  ${MediaQueries.medium} {
    margin-top: 10px !important;
    margin-bottom: 3px;
  }

  &.overview-subtitle,
  &.application-subtitle {
    margin-top: 0px;
    margin-bottom: 0;
  }

  &.recipe-parameters-header,
  &.results-header {
    margin-top: 40px;

    &.slider-closed {
      margin-top: 60px !important;
    }
  }
`;

export const Title = styled('div')`
  font-family: inherit;
  font-weight: bold;
  color: ${colors.black80};
  margin-bottom: 0px;

  ${MediaQueries.large} {
    font-size: 32px;
    line-height: 36px;
    max-width: 254px;
  }

  ${MediaQueries.medium} {
    font-size: 25px;
    line-height: 28px;
  }
`;

export const SubTitle = styled('div')`
  font-family: inherit;
  color: #fff;
  margin-top: 8px;
  margin-bottom: 0px;

  ${MediaQueries.large} {
    font-size: 16px;
    line-height: 14px;
    max-width: 254px;
  }

  ${MediaQueries.medium} {
    font-size: 14px;
    line-height: 14px;
  }
`;

interface Props {
  children: React.ReactNode;
  sliderclosed: boolean;
  openclose: () => void;
}

export default class Sidebar extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  isOpen: boolean = false;
  preventDefaultInactive: boolean = false;
  recipeIsOpen: boolean = false;
  sliderHasContent = false;

  handleLogout = async () => {
    Auth.signOut({})
      .then((data) => window.location.replace('/'))
      .catch((err) => console.log(err));
  };

  sliderOpen = (foreceOpen: boolean = false, animationSpeed: number = 500) => {
    const { openclose, sliderclosed } = this.props;
    const { tablet, mobile } = this.context.device;

    //console.log(sliderclosed)

    this.isOpen = sliderclosed;

    let applicationHeader: HTMLDivElement = document.querySelector('.application-header');
    let applicationSubtitle: HTMLDivElement = document.querySelector('.application-subtitle');
    let sidebarWrapper: HTMLDivElement = document.querySelector('.sidebar-wrapper');

    let leftColumn: HTMLDivElement = document.querySelector('.left-column');
    let leftColumnHeight: any;

    let rightColumn: HTMLDivElement = document.querySelector('.RightColumn');
    let sidebarWrapperTopPadding = parseInt(window.getComputedStyle(sidebarWrapper).getPropertyValue('padding-top'));

    applicationSubtitle ? (applicationSubtitle.className += ' show') : '';

    if (foreceOpen) openclose();

    if (this.recipeIsOpen) {
      this.preventDefaultInactive = true;
    }

    setTimeout(() => {
      leftColumnHeight =
        sidebarWrapperTopPadding * 2 +
        (applicationSubtitle ? applicationSubtitle.offsetHeight : 0) +
        (applicationHeader ? applicationHeader.offsetHeight : 0);
      leftColumn.style.height = leftColumnHeight + 'px';
      rightColumn.style.minHeight = 'auto';
      rightColumn.style.minHeight = rightColumn.offsetHeight + leftColumnHeight + 'px';
    }, animationSpeed);
  };

  sliderClose = (forceClose: boolean = false) => {
    const { openclose, sliderclosed } = this.props;

    this.isOpen = sliderclosed;

    let dropDownRecipe: HTMLElement = document.querySelector('.DropDownRecipeLabel');
    let applicationHeader: HTMLDivElement = document.querySelector('.application-header');
    let applicationSubtitle: HTMLDivElement = document.querySelector('.application-subtitle');
    let sidebarWrapper: HTMLDivElement = document.querySelector('.sidebar-wrapper');

    this.sliderHasContent = applicationSubtitle != null ? true : false;

    let leftColumnHeight: any;

    let leftColumn: HTMLDivElement = document.querySelector('.left-column');
    let rightColumn: HTMLDivElement = document.querySelector('.RightColumn');

    let sidebarWrapperTopPadding = parseInt(window.getComputedStyle(sidebarWrapper).getPropertyValue('padding-top'));

    let deviceWidth = window.innerWidth || document.body.clientWidth;
    let screenHeight = window.innerHeight;

    if (leftColumn.clientHeight < screenHeight) {
      leftColumn.classList.remove('overflow-hidden');
      leftColumn.className += ' overflow-hidden';
    } else {
      leftColumn.classList.remove('overflow-hidden');
    }

    if (sliderclosed || forceClose) {
      // slider is open

      //console.log('CLOSE SLIDER')

      if (forceClose) openclose();

      let Body: HTMLElement = document.querySelector('body');
      Body.style.removeProperty('overflow');

      this.preventDefaultInactive = false;

      if (document.querySelector('.recipe-parameters-header, .results-header') !== null) {
        document.querySelector('.recipe-parameters-header, .results-header').classList.remove('slider-closed');
        document.querySelector('.recipe-parameters-header, .results-header').className += ' slider-closed';
      }

      if (applicationSubtitle != null) {
        applicationSubtitle.classList.remove('show');
      }

      leftColumnHeight = sidebarWrapperTopPadding * 2 + (applicationHeader ? applicationHeader.offsetHeight : 0) + 'px';
      leftColumn.style.height = leftColumnHeight;

      rightColumn.style.minHeight = 'auto';
      rightColumn.style.minHeight = rightColumn.offsetHeight + parseInt(leftColumnHeight) + 'px';
      leftColumn.style.removeProperty('overflow');

      /*
      if(typeof(dropDownRecipe) !== null) {
        /\b(\w*show\w*)\b/gi.test(dropDownRecipe.className) ? dropDownRecipe.click() : null // trigger recipe dropdown to close
      }*/
    } else {
      //console.log('OPEN slider')
      if (this.sliderHasContent) {
        applicationSubtitle.classList.remove('show');
        applicationSubtitle.className += ' show';
      }

      if (this.recipeIsOpen) {
        leftColumn.classList.remove('overflow-hidden');
        leftColumn.setAttribute('style', 'overflow: auto');
        this.preventDefaultInactive = true;
      }

      if (document.querySelector('.recipe-parameters-header, .results-header') !== null) {
        document.querySelector('.recipe-parameters-header, .results-header').classList.remove('slider-closed');
      }

      leftColumnHeight =
        sidebarWrapperTopPadding * 2 +
        (this.sliderHasContent ? applicationSubtitle.offsetHeight : 0) +
        applicationHeader.offsetHeight +
        'px';
      leftColumn.style.height = leftColumnHeight;

      rightColumn.style.minHeight = rightColumn.offsetHeight + parseInt(leftColumnHeight) + 'px';
    }
  };

  componentDidUpdate() {
    const { tablet } = this.context.device;
    if (!tablet) return;

    this.sliderClose();
  }

  componentDidMount() {
    const { tablet, desktop } = this.context.device;
    if (!tablet) return;

    /* Slide Swipe functionality */

    let leftColumn: HTMLDivElement = document.querySelector('.left-column');

    let touchStartYPos: number;
    let touchEndPos: number;
    let lastTouchPos: any;
    let isMoving: boolean = false;

    leftColumn.addEventListener('touchstart', (e) => {
      lastTouchPos = e;
      touchStartYPos = e.touches[0].clientY;
    });

    leftColumn.addEventListener('touchend', (e) => {
      isMoving = false;
    });

    leftColumn.addEventListener('touchmove', (e) => {
      if (this.preventDefaultInactive) {
        return;
      }

      e.preventDefault();

      if (isMoving) return;

      lastTouchPos = e;

      let gestureDistance: number = Math.abs(touchStartYPos - lastTouchPos.touches[0].clientY);

      if (gestureDistance >= 10) {
        isMoving = true;
        let gestureDirection: string = touchStartYPos < lastTouchPos.touches[0].clientY ? 'down' : 'up';

        if (gestureDirection === 'down' && !this.isOpen) {
          this.sliderClose(true);
        }

        if (gestureDirection === 'up' && this.isOpen) {
          this.sliderOpen(true, 0);
        }
      }
    });

    /* Recipe dropdown ResultsPage */

    let recipeDropDown: HTMLDivElement = document.querySelector('.DropDownRecipeLabel');

    if (recipeDropDown) {
      recipeDropDown.addEventListener('click', (e) => {
        setTimeout(() => {
          let eTarget = e.target as HTMLElement;
          let isClosed: boolean = eTarget.className.match(/\b(\w*show\w*)\b/) != null;
          if (isClosed) {
            this.recipeIsOpen = true;
            this.preventDefaultInactive = true;
          } else {
            this.recipeIsOpen = false;
            this.preventDefaultInactive = false;
          }
        }, 150);
      });
    }

    /* Hide slider on swipe functionality */

    let RightColumn: HTMLDivElement = document.querySelector('.RightColumn');

    if (!/(recipeparameters)/gi.test(window.location.pathname)) {
      RightColumn.addEventListener('touchstart', () => {
        if (this.isOpen === false) {
          this.sliderClose(true);
        }
      });
    }

    if (/(recipeparameters)/gi.test(window.location.pathname)) {
      this.sliderClose(true);
    } else {
      this.sliderOpen();
    }
  }

  render() {
    const isResultsPage = window.location.pathname === '/ResultsPage';
    const { desktop } = this.context.device;
    let RightColumn: HTMLDivElement = document.querySelector('.RightColumn');

    desktop && RightColumn != null ? RightColumn.style.removeProperty('min-height') : null;

    return (
      <SidebarWrapper className="sidebar-wrapper">
        {desktop ? (
          <TopContentWrapper className="top-content-wrapper">
            <LogoWrapper className="logo-wrapper">
              <Logo size={23} />
            </LogoWrapper>
          </TopContentWrapper>
        ) : null}
        <SidebarContent className="sidebar-content" isResultsPage={isResultsPage}>
          {this.props.children}
        </SidebarContent>
        {desktop ? (
          <NovozymesLogoWrapper>
            <NovozymesLogo />
          </NovozymesLogoWrapper>
        ) : null}
      </SidebarWrapper>
    );
  }
}
