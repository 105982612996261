import React from 'react';

import { styled } from '../../theme';
import EnzymeInfo from './enzyme-info';
import EnzymeCost from './enzyme-cost';
import EnzymeIndicators from './enzyme-indicators';
import i18n from '../../i18NextConf';

import { MediaQueries } from '../../theme/media-queries-css';
import EnzymeListItem from './enzyme-list-item';
import EnzymeListHeader from './enzyme-list-header';
import { Enzyme } from '../../types';
import { colors } from '@novozymes-digital/components';

const EnzymeInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${MediaQueries.small} {
    flex-direction: column;
  }
`;

const EnzymeLabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${MediaQueries.large} {
  }
`;

const EnzymeLabelParent = styled.div`
  flex-basis: 40%;

  ${MediaQueries.large} {
    flex-basis: 40%;
    &::before {
      position: absolute;
      top: 45px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #e7e7e7;
      content: '';
    }
  }

  ${MediaQueries.medium} {
    flex-basis: 40%;
  }

  ${MediaQueries.small} {
    flex-basis: 34%;
  }
`;

const EnzymeLabel = styled.div<{
  bgcolor?: string;
}>`
  position: relative;
  color: ${colors.black};
  font: bold 14px/1 'Novozymes';
  white-space: nowrap;
  background: ${({ bgcolor }) => bgcolor};
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: #fff;
  padding: 0 7px;
  text-transform: uppercase;
  margin-bottom: 14px;
  border-radius: 3px;

  ${MediaQueries.large} {
    left: 0px;
  }

  ${MediaQueries.small} {
    font-size: 12px;
    left: -20px;
  }
`;

const EnzymeListItemWrapper = styled.div``;

const EnzymeIndicatorsWrapper = styled.div`
  flex-basis: 50%;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 20px;
  width: 100%;

  ${MediaQueries.large} {
    flex-basis: 60%;
    padding-right: 30px;
  }

  ${MediaQueries.medium} {
    flex-basis: 60%;
    padding-right: 30px;
  }

  ${MediaQueries.small} {
    flex-basis: 60%;
    padding-right: 0;
  }
`;

const EnzymListWrapper = styled('div')`
  background: ${({ theme }) => theme.color.lightgrey};
`;

const List = styled('div')`
  background: #fff;
  margin-top: 16px;

  ${MediaQueries.large} {
    padding: 20px 20px 40px 20px;
  }

  ${MediaQueries.medium} {
    padding: 30px 20px;
  }

  ${MediaQueries.small} {
    padding: 20px 12px;
  }

  .enzyme-info .enzyme-descr {
    font-weight: bold;
  }

  ${MediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
  }
`;

type EnzymeData = {
  name: Enzyme;
  PPMMax: number;
  PPMMin: number;
};

type Props = {
  device: any;
  region: string;
  application: string;
  unit: string;
  recommendations: EnzymeData[];
  scrollToTabAndMakeActive: (tab: string) => void;
};

const covertToGPerKg = (num, unit, region) =>
  unit === 'ppm' ? num : Number((num / (region === 're_CH' ? 40 : 10)).toFixed(1));

const getDosageString = (min, max, unit, region) =>
  `${covertToGPerKg(min, unit, region)} - ${covertToGPerKg(max, unit, region)} ${i18n.t(
    unit === 'ppm' ? 'ppm' : i18n.t(region === 're_CH' ? ' g / 25 kg' : 'g / 100 kg')
  )}`;

const isNM10000AboveMaxPpm = (recommendations: EnzymeData[]) => {
  const nm10000 = recommendations.find((recommend) => recommend.name === 'Novamyl 10000 BG');

  return nm10000 && nm10000.PPMMax > 400;
};

export default class EnzymeList extends React.PureComponent<Props> {
  render() {
    const { device, region, recommendations, application, unit, scrollToTabAndMakeActive } = this.props;
    const recommended = recommendations[0];

    const EnzymeList = (
      <EnzymListWrapper className="medium">
        <List className="EnzymeList">
          <EnzymeListHeader
            label={i18n.t('Recommended')}
            color={'rgb(196, 210, 3)'}
            scrollToTabAndMakeActive={scrollToTabAndMakeActive}
          />

          <EnzymeListItem
            region={region}
            device={device}
            type="recommended"
            label={i18n.t('Recommended')}
            name={recommended.name}
            description={getDosageString(recommended.PPMMin, recommended.PPMMax, unit, region)}
            color={'rgb(196, 210, 3)'}
            application={application}
          />
          {recommendations.length > 1 && (
            <>
              <EnzymeListHeader
                label={recommendations.length > 2 ? i18n.t('Alternatives') : i18n.t('Alternative')}
                color={'#ffb403'}
                scrollToTabAndMakeActive={scrollToTabAndMakeActive}
              />
              {recommendations.slice(1).map((enzyme, index) => {
                return (
                  <EnzymeListItem
                    region={region}
                    device={device}
                    type="alternative"
                    label={i18n.t(`${index === 0 ? 'Recommended' : 'Alternative'}`)}
                    name={enzyme.name}
                    description={getDosageString(enzyme.PPMMin, enzyme.PPMMax, unit, region)}
                    color={'rgb(196, 210, 3)'}
                    application={application}
                    key={enzyme + index.toString()}
                  />
                );
              })}
            </>
          )}

          <EnzymeListHeader
            label={i18n.t('Control')}
            color={'#838C85'}
            scrollToTabAndMakeActive={scrollToTabAndMakeActive}
          />

          <EnzymeListItem
            type="control"
            region={region}
            device={device}
            label={i18n.t('Control')}
            description={i18n.t('Without enzymes')}
            color="#838C85"
            application={application}
          />
        </List>
      </EnzymListWrapper>
    );

    return EnzymeList;
  }
}
