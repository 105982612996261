import React, { useMemo, useState } from 'react';
import { CheckboxField, useAuthenticator, TextField, PasswordField, Button, Alert } from '@aws-amplify/ui-react';
import i18n from '../../i18NextConf';
import { Link } from 'react-router-dom';
import './AuthPopup.css';
import { I18n } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';

export interface SignInProps {
  setActualAuthState: any;
}

export function SignIn(props: SignInProps) {
  const { setActualAuthState } = props;
  const [inputs, setInputs] = useState({});
  const [agreement, setAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleTermsChange = (event) => {
    setAgreement(event.target.checked);
  };

  const { toSignUp, toResetPassword } = useAuthenticator((context) => [context.user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      setIsErrorOpen(false);
      await Auth.signIn(inputs['email'], inputs['password']);
    } catch (error) {
      setIsLoading(false);
      setIsErrorOpen(true);
      console.log('error signing in', error.message);
    }
  };

  const buttonDisable = useMemo(() => !agreement || !inputs['email'] || !inputs['password'], [agreement, inputs]);

  return (
    <div style={{ padding: '24px' }}>
      <div className="bigHeader">{I18n.get('Sign in to your account')}</div>
      <form data-amplify-form="" data-amplify-authenticator-signin="">
        <div className="amplify-flex" style={{ flexDirection: 'column' }}>
          <TextField
            autoComplete="username"
            label={i18n.t('Email') + ' *'}
            name="email"
            placeholder={i18n.t('Enter your email address')}
            required
            onChange={handleChange}
          />
          <PasswordField
            autoComplete="new-password"
            label={i18n.t('Password') + ' *'}
            name="password"
            placeholder={i18n.t('Enter your password')}
            required
            onChange={handleChange}
          />
          {isErrorOpen && (
            <Alert
              isDismissible={true}
              onDismiss={() => setIsErrorOpen(false)}
              className="amplify-flex amplify-alert amplify-alert--error"
            >
              {I18n.get('Incorrect username or password.')}
            </Alert>
          )}
          <CheckboxField
            name="custom:termsandconditions"
            value={'1'}
            checked={agreement}
            label={
              <>
                {i18n.t('I accept Novozymes')}{' '}
                <a className="in-signup-link" target="_blank" href="https://www.novozymes.com/en/legal-notice">
                  {i18n.t('legal notice')}
                </a>
                {' ' + i18n.t('and') + ' '}
                <a className="in-signup-link" target="_blank" href="https://www.novozymes.com/privacy">
                  {i18n.t('Privacy Policy')}
                </a>
              </>
            }
            onChange={handleTermsChange}
            id="signIn-terms"
          />
          <Button
            isLoading={isLoading}
            loadingText="Sign In"
            onClick={handleSubmit}
            ariaLabel=""
            disabled={buttonDisable}
            isFullWidth={true}
            className="amplify-button--primary"
          >
            Sign In
          </Button>
          <div className="sign-on-flow help-links">
            <div>{I18n.get('Get help')}</div>
            <ul>
              <li
                onClick={() => {
                  setActualAuthState('signUp');
                  toSignUp();
                }}
              >
                {I18n.get('Create a new SmartBake account')}
              </li>
              <li
                onClick={() => {
                  setActualAuthState('resetPassword');
                  toResetPassword();
                }}
              >
                {I18n.get('Reset password')}
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
}

SignIn.defaultProps = {};

export default SignIn;
