import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import i18n from '../../i18NextConf';

import { withTheme } from 'styled-components';
import { styled, Theme } from '../../theme';
import { Parameters } from '../../types';
import { MediaQueries } from '../../theme/media-queries-css';

import PurpleArrow from '../../images/purple-arrow.svg';
import { colors } from '@novozymes-digital/components';

const NavigationWrapper = styled(Link)`
  position: relative;
  text-decoration: none;
  font-size: 18px;
  display: block;

  ${MediaQueries.medium} {
    font-size: 16px;
    max-width: 100%;

    &:last-of-type {
      margin-bottom: 26px;
    }

    > div {
      border-left: 0;
      margin: 0 !important;
      border: solid 1px #e7e7e7;
      padding: 16px 20px 19px !important;

      * {
        font-size: inherit !important;
      }
    }

    &:not(:last-of-type) > div {
      border-bottom: 0;
    }
  }

  ${MediaQueries.small} {
    width: 100%;
    text-align: center;
  }
`;

const Container = styled.div`
  position: relative;
  border-left: solid 4px ${(props) => props.theme.color.green};
  margin: 22px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 20px;

  ${MediaQueries.medium} {
    &:before {
      position: absolute;
      right: 21px;
      top: 50%;
      background: url(${PurpleArrow}) no-repeat right 50%;
      background-size: 8px auto;
      transform: translateY(-50%);
      width: 8px;
      height: calc((91.1 / 51) * 8px);
      content: '';
      z-index: 1;
    }
  }

  ${MediaQueries.small} {
    &:before {
      background-size: 6px auto;
      width: 6px;
      height: calc((91.1 / 51) * 6px);
    }
  }

  @media screen and (min-width: 993px) {
    flex-direction: row;
    margin: 0 0 30px;

    .CommentLabel {
    }

    > .Info:nth-child(odd) {
      flex-basis: 50%;
      flex-shrink: 1;
      flex-grow: 0;
      width: auto;
      min-width: 400px;
    }

    > .Info:nth-child(even) {
      flex-basis: 50%;
      flex-grow: 1;
      flex-shrink: 0;
      width: auto;
    }
  }
`;

const Info = styled.div``;

const Date = styled.div`
  color: ${({ theme }) => theme.color.green};
  font-size: 18px;
  line-height: 25px;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Title = styled.div`
  color: ${colors.black};
  font-size: 18px;
  line-height: 25px;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CommentLabel = styled.div`
  color: ${(props) => props.theme.color.grey};
  font-size: 18px;
  line-height: 25px;

  ${MediaQueries.medium} {
    display: none;
  }
`;
const Comment = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: ${colors.black};

  ${MediaQueries.medium} {
    display: none;
  }
`;

interface Props {
  id: string;
  title: string;
  comment: string;
  date: string;
  theme: Theme;
  parameters: Parameters;
}

const RecommendationOverview = (props: Props) => {
  const { id, title, comment, date, theme, parameters } = props;

  //console.log(parameters.application)

  return (
    <NavigationWrapper
      to={{
        pathname: '/ResultsPage',
      }}
      state={{
        sugar: parameters.sugar || null,
        fat: parameters.fat || null,
        targetShelfLife: parameters.targetShelfLife || null,
        ryeFlour: parameters.ryeFlour || null,
      }}
      onClick={() => {
        localStorage.setItem('recipe-param-application', String(parameters.application));
        localStorage.setItem('recipe-param-sugar', String(parameters.sugar));
        localStorage.setItem('recipe-param-fat', String(parameters.fat));
        localStorage.setItem('recipe-param-targetShelfLife', String(parameters.targetShelfLife));
        localStorage.setItem('recipe-param-egg', String(parameters.egg));
        localStorage.setItem('recipe-param-ryeFlour', String(parameters.ryeFlour));
      }}
    >
      <Container className="RecommendationOverview">
        <Info className="Info">
          <Date className="Date">{moment(date).format(i18n.t('DD/MM/YYYY'))}</Date>
          <Title className="title">{title}</Title>
        </Info>
        <Info className="Info">
          <CommentLabel className="CommentLabel">{i18n.t('Comment')}</CommentLabel>
          <Comment className="Comment">{comment}</Comment>
        </Info>
      </Container>
    </NavigationWrapper>
  );
};

export default withTheme(RecommendationOverview);
