import React, { createContext } from 'react';
import { Link } from 'react-router-dom';

import i18n from '../../i18NextConf';
import Button from '../../components/button';
import Loader from '../../components/loader';
import FlowLayout from '../../components/flow-layout';
import StepHeader from '../../components/step-header';
import { Label, Title } from '../../components/flow-layout/sidebar';
import RecommendationOverview from './recommendation-overview';
import { styled } from '../../theme';
import { Recommendation } from '../../types';

import { fetchPreviousRecommendationsAPI } from '../../services/api';
import { MediaQueries } from '../../theme/media-queries-css';

import { Auth } from 'aws-amplify';
// @ts-ignore
import TagManager from 'react-gtm-module';
import { colors } from '@novozymes-digital/components';

export const OverviewLabel = styled.div`
  font-size: 18px;
  margin: 55px 0 16px 0;
  color: ${colors.black};

  ${MediaQueries.medium} {
    font-size: 16px;
    margin-top: 33px;
    font-weight: bold;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 32px;

  ${MediaQueries.medium} {
    text-align: center;
    margin-top: 0;

    button {
      margin-top: 20px;
      min-width: 400px;
    }
  }

  ${MediaQueries.small} {
    width: 100%;
    text-align: center;

    button {
      margin-top: 11px;
      min-width: 0;
      width: 100%;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  device?: string;
}

interface State {
  isLoading: boolean;
  previousRecommendations: Array<Recommendation> | [];
}

export default class OverviewPage extends React.PureComponent<Props, State> {
  state = {
    isLoading: true,
    previousRecommendations: [],
  };

  async componentDidMount() {
    const history = await fetchPreviousRecommendationsAPI();
    const fetchedRecommendations = history;

    Auth.currentUserInfo()
      .then((user) => {
        TagManager.dataLayer({
          dataLayer: {
            userId: user.attributes.sub,
          },
          dataLayerName: 'dataLayer',
        });
      })
      .catch((err) => console.log(err));

    this.setState({
      isLoading: false,
      previousRecommendations: fetchedRecommendations,
    });
  }

  render() {
    const { isLoading, previousRecommendations } = this.state;

    const sidebar = (
      <>
        <Title className="overview-header">{i18n.t('Welcome')}</Title>
        <Label className="overview-subtitle">
          {i18n.t(
            'Use SmartBake® to reach your target shelf life. Enter your most important recipe parameters for a dosage recommendation tailored to your fresh-keeping needs.'
          )}
        </Label>
      </>
    );

    if (isLoading) return <Loader />;

    return (
      <FlowLayout sidebar={sidebar} progress={1 / 4}>
        <StepHeader
          previousPage="overviewpage"
          title={i18n.t('Saved recommendations')}
          description={i18n.t('Find the right fresh-keeping enzyme dosage for your next formulation')}
        />
        <ButtonWrapper>
          <Link to="/ApplicationPage">
            <Button minWidth={254} primary>
              {i18n.t('Find my dosage')}
            </Button>
          </Link>
        </ButtonWrapper>

        <OverviewLabel className="PreviousRecommendations">
          {previousRecommendations.length ? i18n.t('Previous recommendations') : ''}
        </OverviewLabel>
        {previousRecommendations.map((item: Recommendation, index: number) => (
          <RecommendationOverview
            id={item.id}
            key={index}
            title={item.title}
            comment={item.comment}
            date={item.created}
            parameters={item.parameters}
          />
        ))}
      </FlowLayout>
    );
  }
}
