import React, { useContext } from 'react';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

const Wrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;

  ${MediaQueries.small} {
    top: 4px;
    transform: translateY(0);
  }
`;

const Svg = styled('svg')`
  fill: #000;
  stroke: #000;
  width: 16px;
  height: auto;
  transform: rotate(-180deg);
`;

interface Props {
  title?: string;
  goBack: () => void;
}

const GoBack = (props: Props) => {
  const { title = '', goBack } = props;

  return (
    <Wrapper
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        goBack();
      }}
    >
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 91.1">
        <path d="M3.8.5a3.17,3.17,0,0,0-2.3.9,3.08,3.08,0,0,0,0,4.5l41,39.6L1.5,85.1a3.08,3.08,0,0,0,0,4.5,3.52,3.52,0,0,0,4.7,0L49.6,47.7a3.08,3.08,0,0,0,0-4.5L6.2,1.3A4.07,4.07,0,0,0,3.8.5Z" />
      </Svg>
    </Wrapper>
  );
};

export default GoBack;
