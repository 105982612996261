import React from 'react';

import { styled } from '../../theme';
import Button from '../../components/button';
import FreshnessBackgroundImage from '../../images/bg-popup-uk.png';
import i18n from '../../i18NextConf';

const Container = styled.div`
  padding: 50px 0 20px;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
`;

/*const FreshnessBackground = styled.div`
  background-image: url(${FreshnessBackgroundImage});
  padding: 0;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 40px;
`*/

const FreshnessImage = styled.img`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

interface Props {
  onClose: () => void;
}

const FreshnessInfoModal = (props: Props) => {
  return (
    <Container className="FresnessContainer">
      <FreshnessImage src={FreshnessBackgroundImage} />
      {/*<Button minWidth={254} primary onClick={props.onClose}>
        {i18n.t('Close')}
      </Button>
      */}
    </Container>
  );
};
export default FreshnessInfoModal;
