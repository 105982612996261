import React from 'react';

import { styled } from '../theme';
import NovozymesLoader from '../images/ic-loader.svg';
import { MediaQueries } from '../theme/media-queries-css';
import { colors } from '@novozymes-digital/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${colors.primaryBlue};
  overflow: hidden;
`;

const Rotate = styled.img`
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => (
  <Container>
    <Rotate src={NovozymesLoader} width="87" height="82" />
  </Container>
);

export default Loader;
