import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { styled } from '../../theme';
import { formFields, regionOptions, companyTypeOptions } from './signupConfig';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator, CheckboxField, useAuthenticator, SelectField, TextField } from '@aws-amplify/ui-react';
import i18n from '../../i18NextConf';
import { Link } from 'react-router-dom';
import './AuthPopup.css';
import { I18n } from '@aws-amplify/core';
import { SignIn } from './SignIn';

const AuthenticatorWrapper = styled.div`
  max-width: 500px;
`;

const AuthPopup = ({
  isOpen,
  handleClose,
  authState,
}: {
  isOpen: boolean;
  handleClose: () => void;
  authState: 'signUp' | 'signIn';
}) => {
  const [actualAuthState, setActualAuthState] = useState<'signIn' | 'signUp' | 'resetPassword'>('signUp');

  useEffect(() => {
    setActualAuthState(authState);
  }, [authState]);

  //console.log('authState: ' + authState, 'actualAuthState: ' + actualAuthState);
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogContent dividers style={{ backgroundColor: '#f6f6f6', fontFamily: 'Novozymes' }}>
        <AuthenticatorWrapper id="landing-auth" className="LoginWrapper show">
          {actualAuthState !== 'signIn' && (
            <Authenticator
              formFields={formFields}
              initialState={actualAuthState}
              components={{
                ResetPassword: {
                  Footer() {
                    const { toSignIn } = useAuthenticator((context) => [context.user]);
                    return (
                      <>
                        <button
                          className="toSignIn"
                          onClick={() => {
                            setActualAuthState('signIn');
                            toSignIn();
                          }}
                          data-variation={'link'}
                        >
                          {I18n.get('Cancel and go back to login')}
                        </button>
                      </>
                    );
                  },
                },
                SignUp: {
                  Header() {
                    return (
                      <>
                        <div className="bigHeader">{I18n.get('Create a new account')}</div>
                        <div className="signUpHeader">{I18n.get('Enter your details and get access today.')}</div>
                      </>
                    );
                  },

                  FormFields() {
                    const { validationErrors } = useAuthenticator();

                    return (
                      <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />

                        <TextField
                          label={i18n.t('Company') + ' *'}
                          name="custom:company"
                          placeholder={i18n.t('Enter your company name')}
                          required
                        />

                        <SelectField
                          label={i18n.t('Company type') + ' *'}
                          name="custom:companytype"
                          placeholder={i18n.t('Select your company type')}
                          required
                        >
                          {companyTypeOptions.map((company) => (
                            <option value={company.key} label={company.val} key={company.val}>
                              {company.val}
                            </option>
                          ))}
                        </SelectField>

                        <SelectField
                          label={i18n.t('Region') + ' *'}
                          name="custom:region"
                          placeholder={i18n.t('Select region')}
                          required
                        >
                          {regionOptions.map((region) => (
                            <option value={region.key} label={region.val} key={region.val}>
                              {region.val}
                            </option>
                          ))}
                        </SelectField>
                        <div className="fieldDesc">
                          {i18n.t('The content of SmartBake™ changes based on your region selection')}
                        </div>
                        <div className="fieldDesc">{i18n.t('* Required')}</div>

                        {/* Append & require Terms & Conditions field to sign up  */}
                        <CheckboxField
                          hasError={!!validationErrors.termsofuse}
                          name="custom:termsandconditions"
                          value="1"
                          label={
                            <>
                              {i18n.t('I accept Novozymes')}{' '}
                              <a
                                className="in-signup-link"
                                target="_blank"
                                href="https://www.novozymes.com/en/legal-notice"
                              >
                                {i18n.t('legal notice')}
                              </a>
                              {' ' + i18n.t('and') + ' '}
                              <a className="in-signup-link" target="_blank" href="https://www.novozymes.com/privacy">
                                {i18n.t('Privacy Policy')}
                              </a>
                            </>
                          }
                        />

                        <CheckboxField
                          name="custom:directmarketing"
                          value="1"
                          label={i18n.t('I would like to receive direct electronic marketing from Novozymes.')}
                        />
                      </>
                    );
                  },
                },
              }}
              services={{
                async validateCustomSignUp(formData) {
                  if (!formData['custom:termsandconditions']) {
                    return {
                      termsofuse: 'error message',
                    };
                  }
                },
              }}
            />
          )}
          {actualAuthState === 'signIn' && (
            <Authenticator.Provider>
              <SignIn setActualAuthState={setActualAuthState} />
            </Authenticator.Provider>
          )}
        </AuthenticatorWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default AuthPopup;
