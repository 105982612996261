import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';

import BackButton from '../../../components/back-button';
import { ThemeContext } from '../../../themecontext';
import InfoIcon from '../../../images/info_icon.png';

import { Utils } from '../../../helpers/equal_height';

const Wrapper = styled('div')<{ backgroundColor: string }>`
  width: 100%;
  margin-bottom: -30px;

  ${MediaQueries.large} {
    background: ${({ backgroundColor }) => backgroundColor};
  }

  @media screen and (max-width: 1359px) {
    width: 100%;
    padding-left: 0;
  }
`;

const Ul = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
`;

const SliderWrapper = styled('div')`
  .slick-slider .slick-list {
    position: relative;

    ${MediaQueries.medium} {
      padding: 0 !important;
      margin-right: -${({ theme }) => theme.padding.medium};
    }

    ${MediaQueries.small} {
      padding: 0 !important;
      margin-right: -${({ theme }) => theme.padding.small};
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .slick-slide {
      float: none !important;
    }
  }
`;

type Props = {
  bgcolor: string;
  children: React.ReactNode;
  calcEqualHeight: boolean;
};

const ConsumerStatements = (props: Props) => {
  const context = useContext(ThemeContext);
  const { bgcolor = '', children, calcEqualHeight } = props;
  const { desktop, tablet, mobile } = context.device;

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
    onSwipe: () => {},
  };

  useEffect(() => {
    if (tablet) {
      let slickTrack = document.querySelectorAll('.slick-slide > div') as any;

      for (let i = 0; i <= slickTrack.length - 1; i += 1) {
        slickTrack[i].style.paddingTop = '40px';
      }
    }

    setTimeout(() => {
      Utils.equalHeight('.equal-h');
    }, 500);
  }, [calcEqualHeight, tablet]);

  return desktop ? (
    <Wrapper backgroundColor={bgcolor}>
      <Ul>{children}</Ul>
    </Wrapper>
  ) : (
    <SliderWrapper className="react-slide">
      <Slider {...settings}>{children}</Slider>
    </SliderWrapper>
  );
};

export default ConsumerStatements;
