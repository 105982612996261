import { createGlobalStyle } from 'styled-components';
import { MediaQueries } from './media-queries-css';
import HidePassword from '../images/hide-pw.png';
import ShowPassword from '../images/show-pw.png';
import VerticalArrow from '../images/vertical-arrow.svg';
import VerticalArrowInactive from '../images/vertical-arrow-inactive.svg';
import { colors } from '@novozymes-digital/components';

const NovozymesAuthCSS = createGlobalStyle`

    span.amplify.showhide-pw {
      position: absolute;
      top: 47px;
      right: 20px;
      width: 25px;
      height: 21px;
      background-size: 25px 21px !important;
      background: url(${HidePassword}) no-repeat 50% 50%;
      z-index: 10;
      cursor: pointer;

      &.show-pw {
        background: url(${ShowPassword}) no-repeat 50% 50%;
      }

    }

    div.login-field-required {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 50px;
      color: #9FA6A1;
      font-style: italic;
      font-size: 14px;
      line-height: 1;
    }

    div[class*="Section__container"] {
      ul.feedback-error {
        margin: 0 0 30px;
        list-style: none;

        li {
          font-size: 16px;
          line-height: 24px;
          color: #ff3c14;
        }
      }
    }

    div[class*="Section__container"] {
        margin:0 auto;

        input[name=code],
        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=string],
        input[name=username],
        input[type=password] {

            height: 50px !important;
            font-size: 16px;
            line-height: normal;

            // Placeholders

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #ced0cd;
              font-family: 'Novozymes';
              font-style: italic;
              font-size: 16px;
              line-height: normal;
            }

            &::-moz-placeholder { /* Firefox 19+ */
              font-family: 'Novozymes';
              color: #ced0cd;
              font-style: italic;
              font-size: 16px;
              line-height: normal;
            }

            &::-ms-input-placeholder { /* IE 10+ */
              font-family: 'Novozymes';
              color: #ced0cd;
              font-style: italic;
              font-size: 16px;
              line-height: normal;
            }

            &::-moz-placeholder { /* Firefox 18- */
              font-family: 'Novozymes';
              color: #ced0cd;
              font-style: italic;
              font-size: 16px;
              line-height: normal;
            }

            &:-ms-input-placeholder {
              font-family: 'Novozymes';
              color: #ced0cd !important;
              font-style: italic;
              font-size: 16px;
              line-height: normal;
            }

            &:focus {
                outline:0;
            }
        }

        a[class*="Toast__toastClose"] {
            &:before {
                background: ${({ theme }) => theme.color.purple};
            }
            &:after {
                background: ${({ theme }) => theme.color.purple};
            }

            &:hover:before,
            &:hover:after {
                background: ${({ theme }) => theme.color.purple};
            }
        }

        div[class*="Nav__navBar"] {
            button {
                text-transform: none;
            }
        }

    }

    button[class='loginButton']{

      color: rgb(40, 0, 45) !important;
      background-color: rgb(196, 210, 3) !important;
      pointer-events: all !important;

      font-size: 16px !important;
      line-height: 24px !important;
      font-family: 'Novozymes';
      padding: 10px 40px;
      min-height: 45px;
      font-weight: bold;
      border-radius: 50px;
      color: #000000;
      text-transform: none;
      border: none;
      min-width: 153px;

    }
    
    button[class='loginButtonDisabled']{

      color: rgba(0, 0, 0, 0.26) !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
      pointer-events: none !important;

      font-size: 16px !important;
      line-height: 24px !important;
      font-family: 'Novozymes';
      padding: 10px 40px;
      min-height: 45px;
      font-weight: bold;
      border-radius: 50px;
      color: #000000;
      text-transform: none;
      border: none;
      min-width: 153px;
        
    }

    div[class*="Form__formContainer"] {
        margin: 0 auto;

        ${MediaQueries.medium} {
            display: flex;
        }

        @media screen and (max-width: 860px) {
            text-align: left;
            margin-bottom: 0px;
        }

        div[class*="Form__formSection"] {
            position: relative;
            min-width: auto;
            background: #f6f6f5;
            padding: 48px;
            width: 100%;
            margin-bottom: 0;

            .amplify-form-row {

              input[type="checkbox"][id="_custom:termsandconditions"]:checked ~ label,
              input[type="checkbox"][id="_custom:termsandconditionslogin"]:checked ~ label,
              input[type="checkbox"][id="_custom:directmarketing"]:checked ~ label {

                &:after {
                  position: absolute;
                  left: 9px;
                  top: 5px;
                  width: 8px;
                  height: 13px;
                  border: solid ${({ theme }) => theme.color.purple};
                  border-width: 0 3px 3px 0;
                  transform: rotate(45deg);
                  z-index: 2;
                  content: '';
                }
              }

              label[for="_custom:termsandconditions"],
              label[for="_custom:termsandconditionslogin"],
              label[for="_custom:directmarketing"] {
                display: block;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                font-style: italic;
                padding-left: 35px;
                cursor: pointer;
                user-select: none;

                a {
                  color: ${colors.black};
                }

                &:before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 25px;
                  width: 25px;
                  background-color: #fff;
                  border: solid 1px #e7e7e7;
                  z-index: 1;
                  content: '';
                }

              }

              input[type="checkbox"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }
            }

            .term-and-conditions-wrap {
              color: ${colors.black};
              margin: auto;
              margin-top: 10px;
              font-style: italic;
              font-size: 12px;
              line-height: 18px;
              text-align: center;

              ${MediaQueries.small} {
                margin-top: 30px;
                text-align: center;
              }

              a {
                color: ${colors.black} !important;
                text-decoration: underline;
                margin-top: 4px;

                @media screen and (max-width: 480px) {
                  display: block;
                }
              }
            }

            ${MediaQueries.small} {
              padding: 40px 40px 40px;
            }

            ${MediaQueries.medium} {
                max-width: 680px;
            }

            .signup-selectbox-wrapper {
                position: relative;
                height: 54px;
                display: flex;
                align-items: center;
                background: #fff;
                padding: 0px;
                border: 1px solid #e7e7e7;

                div[class*="SelectInput__selectInput"] {
                    width: 100%;
                    height: 100%;
                }

                &::before {
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  background: url(${VerticalArrow}) no-repeat right 50%;
                  background-size: 12px auto;
                  transform: translateY(-50%);
                  width: 12px;
                  height: calc((100/60.1) * 12px);
                  content:'';
                  z-index: 1;
                  pointer-events: none;
                }

                select {
                  width: 100%;
                  height: 100%;
                  margin-bottom: 0 !important;
                  border: 0 !important;
                  appearance: none;
                  background: none !important
                }
            }

            div[class*="Form__formField"] {

                span.required {
                   display: inline-block;
                   vertical-align: top;
                   margin-right: 3px;
                }

                input {
                }

                input,select {
                    color: inherit;
                    &:focus {
                        outline: 0;
                    }
                }

                select {
                    border-radius: 0 !important;
                    font-size: 16px;
                    line-height: normal;
                    background-image: none;
                    background: #fff url(${VerticalArrow}) no-repeat calc(100% - 10px) calc(50% + 2px);
                    background-size: 12px auto;

                    &.inactive {
                      background: #fff url(${VerticalArrowInactive}) no-repeat calc(100% - 10px) calc(50% + 2px);
                      background-size: 12px auto;
                      color: #ced0cd;
                    }

                    &:not([name="dial_code"]) {
                      font-style: italic;
                      color: #ced0cd;
                    }

                    &.active {
                        font-style: normal;
                        color: ${colors.black};
                    }
                }
            }

            div[class*="Section__sectionBody"] {
              margin-bottom:40px;

              .signup-region-text-wrap,
              .signup-reset-pw-text {
                  font-style: italic;
                  color: #838C85;
                  font-size: 14px;
                  line-height: 18px;
                  margin-top: 4px;
              }

              .signup-reset-pw-text {
                margin-top: -20px;
                margin-bottom: -10px;
              }

              .amplify-form-row:not(:last-of-type) {
                margin-top: 28px;
              }

              .amplify-form-row:last-of-type {
                margin-top: 22px;
              }

            }

            div[data-test="sign-in-header-section"] {
                font-size: 24px !important;
                line-height: 28px !important;
                font-weight: bold;
            }

            div[data-test="sign-up-header-section"] {
                font-weight: bold;
                font-size: 24px !important;
                line-height: 28px !important;
                margin-bottom: 0px;

                span {
                    font-size: inherit;
                    line-height: inherit;
                }

            }

            .signup-subsection {
                font-size: 16px;
                line-height: 24px;
                margin: 4px 0 20px;
                color: inherit;
            }

            div[class*="Section__sectionHeader"],
            div[class*="Input__inputLabel"] {
                font-size: inherit;
            }

            /* Forget your password + No account */

            div[class*="Hint__hint"],
            div[class*="Section__sectionFooter"],
            div[class*="Section__sectionFooterSecondaryContent"] {
                font-size: 14px;
            }

            div[class*="Section__sectionHeader"],
            div[class*="Input__inputLabel"],
            div[class*="Hint__hint"],
            div[class*="Section__sectionFooter"] {
                color: ${colors.black} !important;
            }

            div[class*="Input__inputLabel"] {
                font-size: 16px;
                line-height: 24px;
            }

            div[class*="Hint__hint"] {
                margin-top: 20px;
                font-size: 16px;
                line-height: 24px;

                a[data-test="sign-in-forgot-password-link"] {
                  color: ${colors.black} !important;
                  text-decoration: underline;
                }
            }

            div[class*="Section__sectionFooter"] a,
            div[class*="Hint__hint"] a {
                font-family: 'Novozymes' !important;
                font-size: 16px;
                line-height: 24px;
                color: ${({ theme }) => theme.color.green} !important;
            }

            div[class*="Section__sectionFooter"] {
                display: flex;
                flex-direction: row;
                justify-content: center;

                ${MediaQueries.small} {
                  display: block;
                }

                span[class*="Section__sectionFooterPrimaryContent"] {
                    order: 2;
                    margin: 0 0 0 20px;
                    flex-grow: 1;
                    flex-shrink: 1;

                    ${MediaQueries.small} {
                      width: 100%;
                      margin: 0;
                    }

                }

                span[class*="Section__sectionFooterSecondaryContent"] {
                    order: 1;
                    flex-shrink: 1;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    display: inline-block;
                    border-radius: 50px;
                    padding: 10px 20px;
                    margin: 0;
                    min-height: 45px;
                    border: solid 1px ${({ theme }) => theme.color.purple};
                    min-width: 153px;
                    text-align: center;

                    ${MediaQueries.small} {
                      width: 100%;
                      margin: 0 auto;
                    }

                    a[data-test="sign-up-sign-in-link"] {
                      text-decoration: none;
                      display: block;
                    }

                    a {
                      color: ${colors.black} !important;

                      &:hover {
                        text-decoration: none;
                      }
                    }


                }
            }

            div[data-test="sign-in-footer-section"] {
              display: flex;
              justify-content: center;

                span[class*="Section__sectionFooterPrimaryContent"] {
                  margin: 0 auto;
                  flex-grow: 0;
                  flex-shrink: 0;
                }
            }

            div[data-test="sign-up-footer-section"] {
              span {
                flex-grow: 0 !important;
                flex-shrink: 0 !important;
              }
            }

            button[class*="Button__button"]:not([class*="showhide-pw-icon"],[id="loginButton"],[id="loginButtonDisabled"]) {
                font-size: 16px !important;
                line-height: 24px !important;
                font-family: 'Novozymes';
                padding: 10px 40px;
                min-height: 45px;
                /*min-width: auto;*/
                font-weight: bold;
                border-radius: 50px;
                color: ${colors.black};
                text-transform:none;
                letter-spacing: 0;
                transition: all .25s ease;

                ${MediaQueries.small} {
                  font-size: 16px !important;
                  line-height: 24px !important;
                }

                &:focus {
                    outline: 0;
                }
            }

        }

    }

    div[data-test="sign-up-body-section"][data-step="re_CH"] {

        .signupflow.phone-wrapper {
          .phone-field-helptext {
            color: #838c85;
          }
        }

        div[class*="Form__formField"]:nth-child(5) {
            margin-bottom: 0 !important;
        }

        div[class*="Form__formField"]:nth-child(5),
        div[class*="Form__formField"]:nth-child(6) {
            background: #e7e7e7;
            padding: 20px;

            input {
                border: solid 1px #dbdddb !important;
            }
        }

        div[class*="Form__formField"]:nth-child(6) {
            padding-top: 0;
        }

        select[name="dial_code"] {
            height: 50px !important;
            border: 1px solid #dbdddb;
            border-right: 0;
            padding-left: 6px !important;
            flex-basis: 66px !important;
            width: auto !important;
        }

        .signupflow.divider {
            font-weight: bold;
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: -6px;
            height: 30px;
            padding-bottom: 10px;

            span {
                &:not(:nth-child(2)) {
                    flex-grow: 1;
                    flex-shrink: 1;
                }

                &:nth-child(2) {
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding: 0 18px;
                }

                &:first-of-type,
                &:last-of-type {
                    position: relative;
                    &:before {
                        display: block;
                        height: 1px;
                        width: 100%;
                        background: #ced0cd;
                        content: '';
                    }
                }
            }
        }
    }


    .amplify.forgot-pw-subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    div[data-test="forgot-password-header-section"] {
      margin-bottom: 10px !important;
      span {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }
    }

    .amplify.login-divider {
        display: none;

        ${MediaQueries.small} {
          display: block;
          width: 60px;
          height: 2px;
          margin: 30px auto;
          background: #dbdddb;
        }
    }

    div[data-test="sign-in-footer-section"] {
      justify-content: center;
    }

    .sign-on-flow.help-links {
      margin-top: 40px;
      padding: 30px 36px;
      background: #fff;

      ${MediaQueries.medium} {
        margin-top: 0;
      }

      > div {
        font-weight: bold;
        color: inherit;
        border-bottom: solid 1px #e7e7e7;
        padding-bottom: 16px;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        margin: 0;

        li {
          position: relative;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 10px;
          padding: 0 0 0 30px;
          text-decoration: underline;
          cursor: pointer;

          &:before {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 100%;
            width: 7px;
            height: 7px;
            background: ${({ theme }) => theme.color.purple};
            vertical-align: top;
            margin-top: 8px;
            margin-right: 20px;
            content: '';
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .LoginWrapper .phone-field-helptext {
      font-size: 14px;
      line-height: 18px;
      padding: 0px 0 0;
      font-style: italic;
      color: #006212;
    }

    div[data-test="sign-up-body-section"] .inactive {
      opacity: .3;
      color: #ced0cd;
    }

`;

export default NovozymesAuthCSS;
