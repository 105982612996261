import React, { useContext } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';

import BackButton from '../../../components/back-button';
import { ThemeContext } from '../../../themecontext';

const OuterWrapper = styled('div')<{
  bgColor: string;
  paddingTop: number;
  paddingBottom: number;
}>`
  background-color: ${({ bgColor }) => bgColor};

  ${({ paddingTop }) =>
    paddingTop &&
    `
    padding-top: ${paddingTop}px;
  `}

  ${({ paddingBottom }) =>
    paddingBottom &&
    `
    padding-bottom: ${paddingBottom}px;
  `}
`;

const Wrapper = styled('div')<{
  region: boolean;
  showBorders: boolean;
  paddingTop: number;
  paddingBottom: number;
}>`
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 601px) {
    padding-top: ${({ paddingTop }) => paddingTop}px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  }

  ${MediaQueries.small} {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  ${({ showBorders }) =>
    showBorders &&
    `
    border-top: solid 1px #dbdddb;
    border-bottom: solid 1px #dbdddb;
  `}
`;

const Icon = styled('div')`
  width: 25px;
  height: auto;
  margin-right: 6px;
  margin-top: 0px;

  svg {
    path {
      fill: #fff;
      fill-rule: evenodd;
    }

    circle {
      fill: #2d0028;
    }
  }
`;

const Text = styled('div')`
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
`;

const Div = styled('div')<{
  region: boolean;
}>`
  color: inherit;

  ${({ region }) =>
    region &&
    `
    text-decoration: none;
  `}

  &:hover {
    text-decoration: none;
  }
`;

const SVGInfoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <circle cx="12.5" cy="12.5" r="12.5" />
    <path d="M8.68,13.2a4.59,4.59,0,0,1-.2-.56,22.33,22.33,0,0,1,3.92-2.13c1.18-.35,1.65.06,1.42,1.31s-.62,2.46-.91,3.7c-.13.51-.51,1.45-.13,1.72.38.1,1.16-.47,1.91-.78.16.3.3.42.15.55a9.76,9.76,0,0,1-3.9,2.26c-.76.19-1.28-.12-1.34-.87a4.14,4.14,0,0,1,.15-1.21c.23-1.07.49-2.12.73-3.19.09-.43.15-.86.26-1.53Z" />
    <path d="M11.65,7.38a1.66,1.66,0,0,1,1.48-1.93,1.42,1.42,0,0,1,1.58,1,1.87,1.87,0,0,1-1.58,2A1.25,1.25,0,0,1,11.65,7.38Z" />
  </svg>
);

type Props = {
  bgColor: string;
  text?: string;
  subject: string;
  openModal: (subject: string) => void;
  addPadding?: boolean;
  showBorders?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  maxWidth?: string;
};

const LearnMore = (props: Props) => {
  const context = useContext(ThemeContext);
  const {
    bgColor = 'transparent',
    text = '',
    showBorders = false,
    paddingTop = 20,
    paddingBottom = 20,
    addPadding = false,
    maxWidth = 'medium',
  } = props;

  const { region } = context;
  const { desktop, tablet, mobile } = context.device;
  const { openModal, subject = '' } = props;

  return (
    <OuterWrapper
      bgColor={bgColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      className={`${addPadding ? 'padding' : ''}${maxWidth === 'medium' ? ' medium' : ' max-width'}`}
    >
      <Wrapper
        region={region === 're_CH'}
        onClick={() => {
          openModal(subject);
        }}
        showBorders={showBorders}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        <Icon>{SVGInfoIcon}</Icon>
        <Text>
          <Div region={region === 're_CH'}>{text}</Div>
        </Text>
      </Wrapper>
    </OuterWrapper>
  );
};

export default LearnMore;
