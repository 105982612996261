import React, { ReactElement } from 'react';
import { styled } from './theme';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  background-color: #f6f6f6;
  justify-content: center;
  padding-top: 64px;
  min-height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled('div')`
  position: relative;
  margin-bottom: 22px;
  color: inherit;
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  white-space: nowrap;
  color: inherit;
`;

const SubTitle = styled('p')`
  font-size: 16px;
`;

const DownForMaintenance = (): ReactElement => {
  return (
    <Page>
      <Content>
        <Title>Smartbake is down for maintenance </Title>
        <SubTitle>Please come back later</SubTitle>
      </Content>
    </Page>
  );
};

export default DownForMaintenance;
