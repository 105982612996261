import i18n from 'i18next';
import { I18n } from '@aws-amplify/core';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, zh, ru, ja } from './locales';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    //lng   : "en",
    nsSeparator: false, // set nsSeparator to false and allow keys to be phrases having `:`, `.`
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: ['en', 'zh', 'ru', 'pteu', 'ja'],
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    resources: {
      en: { translation: en },
      //      "ar": { "translation": ar},
      zh: { translation: zh },
      ru: { translation: ru },
      ja: { translation: ja },
    },
    detection: {
      lookupQuerystring: 'lng',
      caches: ['localStorage', 'cookie'],
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
  });

const dict = {
  en: en,
  //  'ar': ar,
  zh: zh,
  ru: ru,
  ja: ja,
};

I18n.putVocabularies(dict);

export default i18n;
