import React from 'react';

import { styled } from '../../theme';
import GlobalWrapper from '../../components/global-wrapper';
import Button from '../../components/button';

import i18n from '../../i18NextConf';
import { MediaQueries } from '../../theme/media-queries-css';
import SidebarBackground from '../../images/gfx-bioscript.png';
import { colors } from '@novozymes-digital/components';
import { withRouter } from '../results/withRouter';
import { useNavigate } from 'react-router-dom';

const Page = styled.div`
  background-color: ${colors.primaryBlue};
  background-image: url(${SidebarBackground}) !important;
  background-repeat: no-repeat;
  background-position: right top;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 40px;

  ${MediaQueries.small} {
    padding: 0 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  max-width: 550px;
  height: 600px;
  background-color: ${({ theme }) => theme.color.white};
  margin: 125px auto;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: 30px;
  font-family: Novozymes;
  color: ${colors.black};
  font-weight: bold;
  padding-bottom: 26px;
`;
const TextWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 700px;
  padding: 0 40px;
  width: 100%;
`;

const Paragraph = styled.div`
  font-family: Novozymes;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 25px;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 26px;

  ${MediaQueries.small} {
    padding: 26px 40px 0;
    width: 100%;
  }
`;

const Bold = styled.div`
  font-family: Novozymes;
  font-size: 18px;
  font-weight: bold;
  line-height: 156%;
  color: ${colors.black};
  margin-bottom: 5px;
`;

const ExternalLink = styled.a`
  color: ${colors.black};

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
    display: block;
  }
`;

const Ul = styled.ul`
  list-style: disc !important;
  margin: 20px 0 20px 18px;
`;
const Li = styled.li`
  margin: 0 0 12px;
  padding-left: 6px;
`;

function TermsOfUse() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <GlobalWrapper>
      <Page>
        <Content>
          <Title>
            SmartBake<sup>®</sup> {i18n.t('Terms of Use')}
          </Title>
          <TextWrapper>
            <Paragraph>
              {i18n.t(
                'The website, www.smartbake.novozymes.com (“the Website”), is owned and managed by Novozymes A/S, Krogshoejvej 36, DK-2880 Bagsvaerd, Denmark (“Novozymes”).'
              )}
            </Paragraph>

            <Bold>{i18n.t('Contact')}</Bold>
            <Paragraph>
              {i18n.t(
                'If you have any questions or comments with respect to these Terms of Use, or other information on the Website, please do not hesitate to contact Novozymes through'
              )}
              &nbsp;
              <ExternalLink href="mailto:YSE@novozymes.com">YSE@novozymes.com</ExternalLink>
            </Paragraph>
            <Bold>{i18n.t('Input Data')}</Bold>
            <Paragraph>
              {i18n.t(
                'When you use this Website, you are requested to provide input regarding the following input data in relation to your company (“Input Data”):'
              )}
            </Paragraph>
            <Ul>
              <Li>{i18n.t('Recipe parameters')}</Li>
              <Li>{i18n.t('Application selection')}</Li>
              <Li>{i18n.t('Target shelf life')}</Li>
            </Ul>
            <Paragraph>
              {i18n.t(
                'The Input Data, when entered, is used within the Novozymes group as an input for the algorithm to deliver the recommendations on this Website and to improve the functionality of the algorithm, potentially jointly with external advisors subject to appropriate confidentiality obligations. By providing the Input Data you authorize Novozymes and its affiliates to use the Input Data for these purposes.'
              )}
            </Paragraph>
          </TextWrapper>
          <ButtonWrapper>
            <Button minWidth={254} primary onClick={() => goBack()}>
              {i18n.t('Close')}
            </Button>
          </ButtonWrapper>
        </Content>
      </Page>
    </GlobalWrapper>
  );
}

export default withRouter(TermsOfUse);
