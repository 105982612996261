import React from 'react';
import Video from '.';
import i18n from '../../i18NextConf';

const ElasticityVideo = () => {
  let lang = localStorage.getItem('i18nextLng');

  switch (lang) {
    case 'zh':
      return <Video url="https://player.youku.com/embed/XNDQzMjE5NjkzMg==" title={i18n.t('Effect on elasticity')} />;
    case 'ru':
      return (
        <Video
          url="https://www.novozymes.tv/v.ihtml/player.html?source=share&photo%5fid=64941843"
          title={i18n.t('Effect on elasticity')}
        />
      );

    default:
      return (
        <Video
          url="https://www.novozymes.tv/v.ihtml/player.html?source=share&photo_id=67555254"
          title={i18n.t('Effect on elasticity')}
        />
      );
  }
};

export default ElasticityVideo;
