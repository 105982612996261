import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import i18n from '../../i18NextConf';
import { colors } from '@novozymes-digital/components';

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgb(231, 231, 231);
`;
const IndicatorHeaders = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const EnzymeLabelParent = styled.div`
  flex-basis: 40%;
`;

const CleanEnzymeLabel = styled.div`
  font-size: 1.2rem;
  border-bottom: 2px solid ${colors.primaryGreen};
  line-height: 2rem;
  width: max-content;

  ${MediaQueries.small} {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const IndicatorTitle = styled.div`
  flex-basis: calc(100% / 3);
  text-align: center;
  cursor: pointer;
  ${MediaQueries.large} {
    font-size: 18px;
    line-height: 24px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  ${MediaQueries.small} {
    font-size: 12px;
    line-height: 24px;
  }
`;

type Props = {
  label: string;
  color: string;
  scrollToTabAndMakeActive: (tab: string) => void;
};

export default class EnzymeListHeader extends React.PureComponent<Props> {
  render() {
    const { label, color, scrollToTabAndMakeActive } = this.props;

    const EnzymeListHeader = (
      <ListHeader>
        <EnzymeLabelParent>
          <CleanEnzymeLabel>{label}</CleanEnzymeLabel>
        </EnzymeLabelParent>

        <IndicatorHeaders>
          <IndicatorTitle
            onClick={() => {
              scrollToTabAndMakeActive('softness');
            }}
          >
            {i18n.t('Softness')} <InfoOutlinedIcon fontSize="small" />
          </IndicatorTitle>
          <IndicatorTitle
            onClick={() => {
              scrollToTabAndMakeActive('elasticity');
            }}
          >
            {i18n.t('Elasticity')} <InfoOutlinedIcon fontSize="small" />
          </IndicatorTitle>
          <IndicatorTitle
            onClick={() => {
              scrollToTabAndMakeActive('moistness');
            }}
          >
            {i18n.t('Moistness')} <InfoOutlinedIcon fontSize="small" />
          </IndicatorTitle>
        </IndicatorHeaders>
      </ListHeader>
    );

    return EnzymeListHeader;
  }
}
