declare global {
  interface Window {
    hj: any;
  }
}

export default (tag: string, lang: string) => {
  window.hj =
    window.hj ||
    function () {
      // @ts-ignore
      (hj.q = hj.q || []).push(arguments);
    };

  window.hj('trigger', `${tag}_${lang}`);
};
