import { colors } from '@novozymes-digital/components';
import React from 'react';
import i18n from '../i18NextConf';

import { styled } from '../theme';
import Button from './button';

const Container = styled.div`
  padding: 50px 40px;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  color: ${colors.black};
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 600px) {
    color: ${colors.black};
  }
`;
const Description = styled.div`
  color: ${colors.black};
  font-size: 18px;
  line-height: calc(25 / 18);
  margin: 16px 0px 32px 0px;
  max-width: 366px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: calc(23 / 16);
    color: inherit;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

interface Props {
  title: string;
  id?: string;
  description?: string;
  inputs: React.ReactNode;
  onFormSubmit: (value: any) => void;
  disabled: boolean;
  isSave?: boolean;
  className?: string;
}

const Form = (props: Props) => {
  return (
    <Container id={props.id} className="Form">
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
      {props.inputs}
      <ButtonWrapper>
        <Button
          id={`${props.id || ''}-btn-primary`}
          primary
          onClick={props.onFormSubmit}
          disabled={props.disabled}
          minWidth={254}
        >
          {(props.isSave === undefined ? false : true) ? i18n.t('Save') : i18n.t('Submit')}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
export default Form;
