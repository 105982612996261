export const Utils = {
  is_empty: function (data: any) {
    var count = 0,
      i;

    if (typeof data === 'number') {
      return false;
    }

    if (typeof data === 'boolean') {
      return !data;
    }

    if (data === undefined || data === null) {
      return true;
    }

    if (data.length !== undefined) {
      return data.length === 0;
    }

    for (i in data) {
      if (data.hasOwnProperty(i)) {
        count += 1;
      }
    }

    return count === 0;
  },
  equalHeight: function (selector: any) {
    try {
      let elements = document.querySelectorAll(selector),
        max = 0,
        i = 0;

      if (this.is_empty(elements)) {
        throw 'No matched selector';
      }

      for (let i = 0; i < elements.length; i++) {
        if (elements.hasOwnProperty(i)) {
          if (elements[i].offsetHeight > max) {
            max = elements[i].offsetHeight;
          }
        }
      }

      for (let i = 0; i < elements.length; i++) {
        if (elements.hasOwnProperty(i)) {
          if (elements[i].offsetHeight < max) {
            elements[i].style.height = max + 'px';
          }
        }
      }
    } catch (e) {
      window.console.error('Same Elements Height: ' + e.message);
    }
  },
};
