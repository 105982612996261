import { colors } from '@novozymes-digital/components';
import React from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

const Container = styled.div`
  padding: 50px 40px;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  color: ${colors.black};
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;
const Description = styled.div`
  color: ${colors.black};
  font-size: 18px;
  margin: 16px 0px 32px 0px;
  max-width: 366px;
  text-align: center;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface Props {
  title: string;
  description: string;
}

const ComingSoon = (props: Props) => {
  return (
    <Container>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Container>
  );
};
export default ComingSoon;
