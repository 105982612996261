import React, { useContext } from 'react';
import i18n from '../i18NextConf';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

import PurpleArrow from '../images/purple-arrow.svg';

const Button = styled.div<{ show: boolean }>`
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 31px;
  height: 31px;

  ${MediaQueries.large} {
    display: ${({ show }) => (show ? 'inline-block' : 'none')};
    vertical-align: middle;
    vertical-align: top;
  }

  ${MediaQueries.medium} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    left: ${({ theme }) => theme.padding.medium};
  }

  ${MediaQueries.small} {
    left: ${({ theme }) => theme.padding.small};
  }

  &:before {
    position: absolute;
    top: 50%;
    background: url(${PurpleArrow}) no-repeat right 50%;
    background-size: 13px auto;
    transform: translateY(-50%) rotate(-180deg);
    width: 13px;
    height: calc((91.1 / 51) * 13px);
    content: '';
    z-index: 1;

    ${MediaQueries.large} {
      //left:  ${({ theme }) => theme.padding.large};
    }

    ${MediaQueries.medium} {
      //left:  ${({ theme }) => theme.padding.medium};
    }
  }
`;
interface Props {
  id: string;
  goBackUrl: string;
}

const BackButton = (props: Props) => {
  const { goBackUrl, id } = props;
  let isSavedRecommendationsPage = window.location.pathname === '/SavedRecommendations';

  const goToPage = () => {
    if (goBackUrl) {
      window.location.href = goBackUrl;
    }
  };

  return isSavedRecommendationsPage ? null : (
    <Button id={id} className="back-btn" onClick={goToPage} show={goBackUrl !== null ? true : false}></Button>
  );
};

export default BackButton;
