import React, { useContext } from 'react';

import { ThemeContext } from '../../themecontext';

import RecommendationsEU from './recommendations_eu';
import RecommendationsCH from './recommendations_ch';

const Recommendations = (props: any) => {
  const context = useContext(ThemeContext);
  const { region } = context;

  if (!region) {
    return null;
  }

  let page: any;

  switch (region) {
    case 're_CH':
      page = <RecommendationsCH {...props}></RecommendationsCH>;
      break;

    default:
      page = <RecommendationsEU {...props}></RecommendationsEU>;
  }

  return page;
};

export default Recommendations;
