import 'styled-components';
import baseStyled, { DefaultTheme, ThemedStyledInterface } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    color: {
      purple: string;
      green: string;
      white: string;
      lightgrey: string;
      darkgrey: string;
      offWhite: string;
      orange: string;
      grey: string;
      text: any;
    };
    padding: {
      large: string;
      medium: string;
      small: string;
    };
  }
}

export const theme: DefaultTheme = {
  color: {
    purple: '#2d0127',
    green: '#c4d203',
    white: '#ffffff',
    offWhite: '#f8f6f6',
    orange: '#ffb403',
    grey: '#b3b3b3',
    lightgrey: '#f6f6f6',
    darkgrey: '#e9e9e9',
    text: {
      black: 'rgba(0,0,0,0.87)',
      white: 'rgba(255, 255, 255, 0.87)',
    },
  },
  padding: {
    large: '64px',
    medium: '40px',
    small: '20px',
  },
};

/*
Novozymes-Bd = {
  font-family: 'Novozymes';
  font-weight: bold;
}

Novozymes-Rg = {
  font-family: 'Novozymes';
  font-weight: normal;
}

Novozymes-Tn = {
  font-family: 'Novozymes';
  font-weight: 300;
}
*/

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
