import React, { useContext } from 'react';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

import BackButton from './back-button';
import { ThemeContext } from '../themecontext';

const Wrapper = styled('div')<{
  negativeMargin: boolean;
  bgColor: string;
  desktopHeight: number;
  tabletHeight: number;
  mobileHeight: number;
  addPadding: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};

  ${MediaQueries.large} {
    height: ${({ desktopHeight }) => desktopHeight}px;

    ${({ negativeMargin, theme }) =>
      negativeMargin &&
      `
			padding-left:  ${theme.padding.large};
			padding-right: ${theme.padding.large};
		`}

    ${({ addPadding, negativeMargin, theme }) =>
      !negativeMargin &&
      addPadding &&
      `
			padding-left:  ${theme.padding.large};
			padding-right: ${theme.padding.large};
		`}
  }

  ${MediaQueries.medium} {
    height: ${({ tabletHeight }) => tabletHeight}px;

    ${({ negativeMargin, theme }) =>
      negativeMargin &&
      `
			padding-left:  ${theme.padding.medium};
			padding-right: ${theme.padding.medium};
		`}

    ${({ addPadding, negativeMargin, theme }) =>
      !negativeMargin &&
      addPadding &&
      `
			padding-left:  ${theme.padding.medium};
			padding-right: ${theme.padding.medium};
		`}
  }

  ${MediaQueries.small} {
    height: ${({ mobileHeight }) => mobileHeight}px;

    ${({ negativeMargin, theme }) =>
      negativeMargin &&
      `
			padding-left:  ${theme.padding.small};
			padding-right: ${theme.padding.small};
		`}

    ${({ addPadding, negativeMargin, theme }) =>
      !negativeMargin &&
      addPadding &&
      `
			padding-left:  ${theme.padding.small};
			padding-right: ${theme.padding.small};
		`}
  }
`;

const Line = styled('div')`
  height: 1px;
  width: 100%;
  background: #e7e7e7;
`;

type Props = {
  maxWidth?: string;
  negativeMargin?: string;
  line?: string;
  bgColor?: string;
  desktopHeight: number;
  tabletHeight: number;
  mobileHeight: number;
  addPadding?: boolean;
};

const Divider = (props: Props) => {
  const context = useContext(ThemeContext);
  const {
    line = 'hide',
    bgColor = 'transparent',
    negativeMargin = '',
    desktopHeight = 0,
    tabletHeight = 0,
    mobileHeight = 0,
    addPadding = false,
    maxWidth = '',
  } = props;

  const { desktop, tablet, mobile } = context.device;

  return (
    <Wrapper
      className={`${negativeMargin === 'true' ? 'negative-margin' : ''}${
        maxWidth === 'medium' ? ' medium' : maxWidth === 'large' ? ' large' : ''
      }`}
      negativeMargin={negativeMargin === 'true' ? true : false}
      bgColor={bgColor}
      desktopHeight={desktopHeight}
      tabletHeight={tabletHeight}
      mobileHeight={mobileHeight}
      addPadding={addPadding}
    >
      {line === 'show' && <Line />}
    </Wrapper>
  );
};

export default Divider;
