import React from 'react';

import { styled } from '../theme';
import i18n from '../i18NextConf';
import { MediaQueries } from '../theme/media-queries-css';

import PurpleArrow from '../images/purple-arrow.svg';
import VerticalArrow from '../images/vertical-arrow.svg';
import { colors } from '@novozymes-digital/components';

const InputWrapper = styled.div<{
  minWidth: number;
  maxWidth?: number;
  marginBottom: number;
}>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  padding: 0 20px;
  height: 60px;
  line-height: normal;
  box-sizing: border-box;
  transition: margin 0.35s ease;
  max-width: 400px;

  &:before {
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    background: url(${VerticalArrow}) no-repeat right 50%;
    background-size: 16px auto;
    transform: translateY(-50%);
    width: 16px;
    height: calc((100 / 60.1) * 16px);
    content: '';
    z-index: 1;
  }

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
`;

{
  /*
const InputLabel = styled.div<{ expanded: boolean }>`
  position: absolute;
  top: ${({ expanded }) => (expanded ? '12px' : '36px')};
  left: 20px;
  margin-top: ${({ expanded }) => (expanded ? '0px' : '-10px')};
  height: 1em;
  right: 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  box-sizing: border-box;
  user-select: none;
  transition: top, margin-top, color;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.61, 0.005, 0.225, 1.335);
  white-space: nowrap;
`
*/
}

const InputValue = styled.select<{ parameterSelected: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  border: 0;
  background: transparent;
  font-family: 'Novozymes';
  font-size: 18px !important;
  font-weight: normal;
  font-style: ${({ parameterSelected }) => (parameterSelected ? 'normal' : 'italic')};
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  box-sizing: border-box;
  outline: none;
  color: ${({ theme, parameterSelected }) => (parameterSelected ? '#000' : '#ced0cd')};
  z-index: 10;

  &.active {
    color: ${colors.black};
  }

  &.focus {
    color: ${colors.black};
    font-style: normal;
  }

  ${MediaQueries.small} {
    font-size: 16px;
  }
`;
{
  /*

const InputUnit = styled.div<{ expanded: boolean }>`
  position: absolute;
  top: 34px;
  left: 125px;
  right: 0;
  height: 25px;
  font-family: Novozymes;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  user-select: none;
  line-height: 1.39;
  letter-spacing: normal;
  color: ${({ expanded }) => expanded ? 'rgba(24,45,1,0.25)' : 'rgba(24,45,1,0)'};
  box-sizing: border-box;
  transition: color;
  white-space: nowrap;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.61, 0.005, 0.225, 1.335);
`
*/
}

export default class InputSelect extends React.PureComponent<
  {
    value: any;
    label: string;
    minWidth: number;
    maxWidth: number;
    marginBottom?: number;
    pattern?: string;
    error: boolean;
    onValue: (value: string) => void;
    className?: string;
    options?: string;
    unit: string;
  },
  any
> {
  onFocus = (e) => {
    /*
    this.setState({ focused: true })
    e.target.className += ' focus'*/
  };

  onBlur = (e) => {
    let selectElement = e.target as HTMLSelectElement;
    selectElement.classList.remove('active');
    /*this.setState({ focused: false })
    e.target.classList.remove('focus')*/
  };

  onClickSelect = (e: React.MouseEvent<HTMLSelectElement>) => {
    let selectElement = e.target as HTMLSelectElement;
    selectElement.classList.remove('active');
    selectElement.value === '' ? (selectElement.className += ' active') : null;
  };

  onValue = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.props.onValue(e.target.validity.valid ? e.target.value : this.props.value);
  };

  render() {
    const { value, label, minWidth, maxWidth, marginBottom, options, unit, className } = this.props;

    return (
      <InputWrapper
        minWidth={minWidth}
        maxWidth={maxWidth}
        marginBottom={marginBottom === undefined ? 0 : marginBottom}
      >
        <InputValue
          parameterSelected={value !== '' ? true : false}
          value={value}
          onChange={this.onValue}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onClick={this.onClickSelect}
          className={className}
        >
          <option value="">{i18n.t('Choose') + ` ${label.toLowerCase()}`}</option>
          {options.split(',').map((o) => (
            <option key={o} value={o}>
              {o}
              {unit === '%' ? `${unit}` : ` ${unit}`}
            </option>
          ))}
        </InputValue>
      </InputWrapper>
    );
  }
}
