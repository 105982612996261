import React, { ReactElement } from 'react';
import i18n from '../../i18NextConf';
import { Trans } from 'react-i18next';

import Brioche from '../../images/applications-icons/brioche.svg';
import Hamburger_Bun from '../../images/applications-icons/hamburger_bun.svg';
import White_Pan_Sponge_Dough from '../../images/applications-icons/white_pan_sponge_dough.svg';
import White_Pan_Straight_Dough from '../../images/applications-icons/white_pan_straight_dough.svg';
import Rye from '../../images/applications-icons/rye.svg';
import Panetonne from '../../images/applications-icons/panettone.svg';
import Whole_Wheat_100_straight from '../../images/applications-icons/whole_wheat_100_straight.svg';
import Whole_Wheat_100_Sponge from '../../images/applications-icons/whole_wheat_100_sponge.svg';

export type ApplicationType =
  | 'WhitePanBreadStraightDough'
  | 'WhitePanBreadSweetStraightDough'
  | 'WhitePanBreadSpongeDough'
  | 'HamburgerBun'
  | 'Panettone'
  | 'WholeWheat100SpongeDough'
  | 'WholeWheat100StraightDough'
  | 'RyeBread'
  | 'Brioche'
  | 'USBrioche'
  | 'WhitePanBreadSpongeDoughIndiaSEAsia'
  | 'WhitePanBreadStraightDoughIndiaSEAsia'
  | 'WholeWheat100StraightDoughIndiaSEAsia';

type UserInputs = {
  sugar?: number;
  fat?: number;
  egg?: number;
  ryeFlour?: number;
  targetShelfLife?: number;
};
interface StandardRecipeStep {
  title: string;
  items: {
    label: string | ReactElement;
    value: string | ((inputs: UserInputs) => string);
  }[];
}

interface InternalIApplicationConfig {
  key: ApplicationType;
  name: string;
  title?: string;
  subtitle?: string;
  enabled?: boolean;
  icon?: string;
  sugarOptions?: number[];
  fatOptions?: number[];
  fatType?: string[];
  eggOptions?: number[];
  ryeFlourOptions?: number[];
  targetShelfLifeOptions?: number[];
  standardRecipe?: StandardRecipeStep[];
}

export interface IApplicationConfig {
  key: ApplicationType;
  name: string;
  title?: string;
  subtitle?: string;
  enabled?: boolean;
  icon?: string;
  sugarOptions?: string;
  fatOptions?: string;
  fatType?: string[];
  eggOptions?: string;
  ryeFlourOptions?: string;
  targetShelfLifeOptions?: string;
  standardRecipe?: StandardRecipeStep[];
}

const ApplicationConfig = () => {
  /* SYNC IDs with API enum !!! */
  let ApplicationConfigs: InternalIApplicationConfig[] = [
    {
      key: 'WhitePanBreadStraightDough',
      name: i18n.t('White pan bread EU'),
      title: i18n.t('White pan bread EU'),
      subtitle: i18n.t('Straight dough'),
      enabled: true,
      icon: White_Pan_Straight_Dough,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [0, 1, 2, 3, 4, 5],
      fatOptions: [0, 1, 2],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '55%',
            },
            {
              label: i18n.t('Fresh Yeast'),
              value: '4.5%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.8%',
            },
            {
              label: i18n.t('Calcium Propionate'),
              value: '0.5%',
            },
            {
              label: i18n.t('Ascorbic Acid'),
              value: '60 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '6 ppm',
            },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '30 ppm',
            },
            {
              label: (
                <Trans i18nKey="Lipopan® Max BG" defaults="Lipopan<sup>®</sup> Max BG" components={{ sup: <sup /> }} />
              ),
              value: '10 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '3 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '8 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Scaling 3 x 700 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding'),
              value: '10 min',
            },
            {
              label: i18n.t('Divide in 4 pieces & panning (lidded)'),
              value: '10 min',
            },
            {
              label: i18n.t('Proofing 35°C, 90% RH'),
              value: '60 min',
            },
            {
              label: i18n.t('Baking 220°C'),
              value: '30 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WhitePanBreadSweetStraightDough',
      name: i18n.t('White pan bread CH'),
      title: i18n.t('White pan bread CH'),
      subtitle: i18n.t('Sweet straight dough'),
      enabled: true,
      icon: White_Pan_Straight_Dough,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21, 24, 28],
      fatOptions: [8, 9, 10, 11, 12, 13, 14, 15, 16],
      sugarOptions: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: (input) => {
                if (!input.sugar || !input.fat) {
                  return '-';
                }
                return `${69 - (input.sugar - 10) / 2 - (input.fat - 8) / 2} %`;
              },
            },
            {
              label: i18n.t('Yeast (fresh)'),
              value: '1.5%',
            },
            {
              label: i18n.t('Salt'),
              value: '1%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.3%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '100 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '8 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Pentopan® Mono BG"
                  defaults="Pentopan<sup>®</sup> Mono BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '30 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '6 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '6 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '4 min',
            },
            {
              label: i18n.t('Scaling bread 6 X 450 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Moulding / panning (lidded)'),
              value: '10 min',
            },
            {
              label: i18n.t('Proofing 35°C, 85% RH'),
              value: '130 min',
            },
            {
              label: i18n.t('Baking 180°C'),
              value: '40 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WhitePanBreadSpongeDough',
      name: i18n.t('White pan bread US'),
      title: i18n.t('White pan bread US'),
      subtitle: i18n.t('Sponge dough'),
      enabled: true,
      icon: White_Pan_Sponge_Dough,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [6, 7, 8, 9, 10, 11, 12, 13, 14],
      fatOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      standardRecipe: [
        {
          title: i18n.t('Ingredients - Sponge 70%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '70%',
            },
            {
              label: i18n.t('Water'),
              value: '41%',
            },
            {
              label: i18n.t('Instant Active Dry Yeast'),
              value: '2%',
            },
            {
              label: i18n.t('Mono & Diglycerides'),
              value: '0.37%',
            },
            {
              label: i18n.t('SSL'),
              value: '0.1%',
            },
            {
              label: i18n.t('Calcium Propionate'),
              value: '0.15%',
            },
          ],
        },
        {
          title: i18n.t('Ingredients - Dough 30%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '30%',
            },
            {
              label: i18n.t('Water'),
              value: '18%',
            },
            {
              label: i18n.t('Salt'),
              value: '2%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.25%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '60 ppm',
            },
            {
              label: i18n.t('ADA'),
              value: '20 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process - Sponge'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: '1 min',
            },
            {
              label: i18n.t('Sponge fermentation 24°C'),
              value: '180 min',
            },
          ],
        },
        {
          title: i18n.t('Process - Dough'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '1 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: i18n.t('Until full development'),
            },
            {
              label: i18n.t('Scaling 2 X 760 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding / panning (open top)'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing 40-43°C, 85%RH'),
              value: '50-60 min',
            },
            {
              label: i18n.t('Baking 215°C'),
              value: '23 min',
            },
          ],
        },
      ],
    },
    {
      key: 'HamburgerBun',
      name: i18n.t('Hamburger bun - US style'),
      title: i18n.t('Hamburger bun - US style'),
      subtitle: i18n.t('Sponge dough'),
      enabled: true,
      icon: Hamburger_Bun,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [6, 7, 8, 9, 10, 11, 12, 13, 14],
      fatOptions: [2, 3, 4, 5, 6],
      standardRecipe: [
        {
          title: i18n.t('Ingredients - Sponge 80%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '80%',
            },
            {
              label: i18n.t('Water'),
              value: '46%',
            },
            {
              label: i18n.t('Instant Active Dry Yeast'),
              value: '2%',
            },
            {
              label: i18n.t('Mono & Diglycerides'),
              value: '1%',
            },
            {
              label: i18n.t('SSL'),
              value: '0.5%',
            },
            {
              label: i18n.t('Calcium Propionate'),
              value: '0.1%',
            },
            {
              label: i18n.t('Vital wheat gluten'),
              value: '2%',
            },
          ],
        },
        {
          title: i18n.t('Ingredients - Dough 20%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '20%',
            },
            {
              label: i18n.t('Water'),
              value: '12%',
            },
            {
              label: i18n.t('Salt'),
              value: '2%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.25%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '60 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process - Sponge'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: '1 min',
            },
            {
              label: i18n.t('Sponge fermentation 24°C'),
              value: '180 min',
            },
          ],
        },
        {
          title: i18n.t('Process - Dough'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '1min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: i18n.t('Until full development'),
            },
            {
              label: i18n.t('Evaluation'),
              value: '2 min',
            },
            {
              label: i18n.t('Scaling 36 X 55 g'),
              value: '1 min',
            },
            {
              label: i18n.t('Rounding'),
              value: '1 min',
            },
            {
              label: i18n.t('Resting'),
              value: '5 min',
            },
            {
              label: i18n.t('Moulding / panning'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing 40-43°C, 85%RH'),
              value: '55 min',
            },
            {
              label: i18n.t('Baking 225°C'),
              value: '9 min',
            },
          ],
        },
      ],
    },
    {
      key: 'Brioche',
      name: i18n.t('Brioche'),
      title: i18n.t('Brioche'),
      enabled: true,
      icon: Brioche,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [18, 19, 20, 21, 22, 23, 24, 25],
      fatOptions: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '31%',
            },
            {
              label: i18n.t('Vital wheat gluten'),
              value: '2%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.7%',
            },
            {
              label: i18n.t('Whole Egg'),
              value: '18%',
            },
            {
              label: i18n.t('Dry yeast (sugar tolerant)'),
              value: ({ sugar }) => {
                return sugar ? `${2 + (sugar - 18) * 0.1}%` : '';
              },
            },
            {
              label: i18n.t('Mono & diglycerides'),
              value: '1%',
            },
            {
              label: i18n.t('SSL'),
              value: '0.2%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '100 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '4 ppm',
            },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '25 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '4 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '8 min',
            },
            {
              label: i18n.t('Add butter'),
              value: '1 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '6 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Scaling 4x550g'),
              value: '10 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding'),
              value: '10 min',
            },
            {
              label: i18n.t('Panning (Lidded)'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing: 34°C / 80%RH'),
              value: '120 min',
            },
            {
              label: i18n.t('Baking: 175°C (rack oven)'),
              value: '32 min',
            },
            {
              label: i18n.t('Cooling'),
              value: '60 min',
            },
            {
              label: i18n.t('Spray with alcohol 1% w/w'),
              value: '',
            },
          ],
        },
      ],
    },
    {
      key: 'USBrioche',
      name: i18n.t('USBrioche'),
      title: i18n.t('USBrioche'),
      enabled: true,
      icon: Brioche,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [10, 11, 12, 13, 14, 15, 16, 17],
      fatOptions: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            { label: i18n.t('Flour'), value: '100%' },
            { label: i18n.t('Water'), value: '33%' },
            { label: i18n.t('Salt'), value: '2%' },
            { label: i18n.t('Whole Egg'), value: '18%' },
            { label: i18n.t('Instant Active Dry Yeast'), value: '3%' },
            { label: i18n.t('Mono & Diglycerides'), value: '1%' },
            { label: i18n.t('SSL'), value: '0.2%' },
            { label: i18n.t('Calcium Propionate'), value: '0.4%' },
            { label: i18n.t('Ascorbic acid'), value: '100 ppm' },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '25 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: '3min',
            },
            { label: i18n.t('Add butter & sugar'), value: '1 min' },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl slow'),
              value: '1 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl fast'),
              value: '3 min',
            },
            { label: i18n.t('Resting'), value: '10 min' },
            { label: i18n.t('Scaling 2 X 700g'), value: '10 min' },
            { label: i18n.t('Resting'), value: '10 min' },
            { label: i18n.t('Moulding'), value: '3 min' },
            { label: i18n.t('Panning (Lidded)'), value: '5 min' },
            {
              label: i18n.t('Proofing: 34°C / 80%RH'),
              value: '75-100 min',
            },
            {
              label: i18n.t('Baking: 190°C (rack oven)'),
              value: '25 min',
            },
          ],
        },
      ],
    },
    {
      key: 'RyeBread',
      name: i18n.t('Rye bread'),
      title: i18n.t('Rye bread'),
      subtitle: i18n.t('Rye subtitle'),
      enabled: true,
      icon: Rye,
      targetShelfLifeOptions: [4, 7, 10, 14],
      ryeFlourOptions: [40, 45, 50, 55, 60],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Total wheat & rye flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '63%',
            },
            {
              label: i18n.t('Sour dough'),
              value: '1.8 -2.8%',
            },
            {
              label: i18n.t('Yeast'),
              value: '2.5%',
            },
            {
              label: i18n.t('Salt'),
              value: '2%',
            },
            {
              label: i18n.t('Gluten'),
              value: '1%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '60 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Pentopan® 500 BG"
                  defaults="Pentopan<sup>®</sup> 500 BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '50 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '4 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '2 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Table resting'),
              value: '25 min',
            },
            {
              label: i18n.t('Scaling 4 x 1200 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '5 min',
            },
            {
              label: i18n.t('Moulding'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing 34°C; 80-90%RH'),
              value: '40 min',
            },
            {
              label: i18n.t('Baking 240°C'),
              value: '60 min',
            },
          ],
        },
      ],
    },

    {
      key: 'Panettone',
      name: i18n.t('Panettone'),
      title: i18n.t('Panettone'),
      icon: Panetonne,
      enabled: true,
      targetShelfLifeOptions: [15, 30, 45, 60],
      sugarOptions: [18, 19, 20, 21, 22, 23, 24],
      fatOptions: [12, 13, 14, 15, 16, 17, 18, 19, 20],
      fatType: ['Hydrogenated vegetable fat (shortening)'],
      standardRecipe: [
        {
          title: i18n.t('Recipe - Sponge'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '50%',
            },
            {
              label: i18n.t('Instant dry yeast'),
              value: '2%',
            },
            {
              label: i18n.t('Water'),
              value: '30%',
            },
          ],
        },
        {
          title: i18n.t('Recipe - Dough'),
          items: [
            {
              label: i18n.t('Flour'),
              value: '50%',
            },
            {
              label: i18n.t('Water'),
              value: '23%',
            },
            {
              label: i18n.t('Egg yolk'),
              value: '16%',
            },
            {
              label: i18n.t('Instant dry yeast'),
              value: '1%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.5%',
            },
            {
              label: i18n.t('Vital wheat gluten'),
              value: '2%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.3%',
            },
            {
              label: i18n.t('Panettone essence'),
              value: '0.5%',
            },
            {
              label: i18n.t('Fruits'),
              value: '20%',
            },
            {
              label: i18n.t('Raisins'),
              value: '20%',
            },
            {
              label: i18n.t('DATEM'),
              value: '0.2%',
            },
            {
              label: i18n.t('Monoglycerides'),
              value: '0.2%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '60 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Lipopan® Xtra BG"
                  defaults="Lipopan<sup>®</sup> Xtra BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '30 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Pentopan® Mono BG"
                  defaults="Pentopan<sup>®</sup> Mono BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '25 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 BG"
                  defaults="Fungamyl<sup>®</sup> 4000 BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '9 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Gluzyme® Mono 10.000 BG"
                  defaults="Gluzyme<sup>®</sup> Mono 10.000 BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '6 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process - Sponge'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '10 min',
            },
            {
              label: i18n.t('Sponge fermentation 24°C'),
              value: '180 min',
            },
          ],
        },
        {
          title: i18n.t('Process - Dough'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '13 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '7 min',
            },
            {
              label: i18n.t('Scaling 6 X 550 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding / panning (lidded)'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing: 40-43°C 85%RH'),
              value: '50-60 min',
            },
            {
              label: i18n.t('Baking 215°C'),
              value: '23 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WholeWheat100SpongeDough',
      name: i18n.t('WholeWheat100SpongeDough'),
      title: i18n.t('WholeWheat100SpongeDough'),
      subtitle: i18n.t('WholeWheat100SpongeDough_subtitle'),
      icon: Whole_Wheat_100_Sponge,
      enabled: true,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [6, 7, 8, 9, 10, 11, 12],
      fatOptions: [2, 3, 4, 5],
      standardRecipe: [
        {
          title: i18n.t('Recipe - Sponge'),
          items: [
            {
              label: i18n.t('Whole wheat flour'),
              value: '60%',
            },
            {
              label: i18n.t('Water'),
              value: '54%',
            },
            {
              label: i18n.t('Instant Active Dry Yeast'),
              value: '2%',
            },
            {
              label: i18n.t('Mono & Diglycerides'),
              value: '1%',
            },
            {
              label: i18n.t('DATEM'),
              value: '0.5%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.4%',
            },
            {
              label: i18n.t('Vital wheat gluten'),
              value: '0.15%',
            },
          ],
        },
        {
          title: i18n.t('Recipe - Dough'),
          items: [
            {
              label: i18n.t('Whole wheat flour'),
              value: '40%',
            },
            {
              label: i18n.t('Water'),
              value: '16%',
            },
            {
              label: i18n.t('Salt'),
              value: '2.1%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.35%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '150 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process - Sponge'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl mixer slow'),
              value: '4 min',
            },
            {
              label: i18n.t('Sponge fermentation 24°C'),
              value: '180 min',
            },
          ],
        },
        {
          title: i18n.t('Process - Dough'),
          items: [
            {
              label: i18n.t('Hobart mixer with McDuffy bowl mixer slow'),
              value: '1 min',
            },
            {
              label: i18n.t('Hobart mixer with McDuffy bowl mixer fast'),
              value: i18n.t('Until full development'),
            },
            {
              label: i18n.t('Evaluation'),
              value: '2 min',
            },
            {
              label: i18n.t('Scaling (open top) 2x760g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '5 min',
            },
            {
              label: i18n.t('Moulding / panning (lidded)'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing 40-43°C, 85%RH'),
              value: '45-60 min',
            },
            {
              label: i18n.t('Baking 215°C'),
              value: '23 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WholeWheat100StraightDough',
      name: i18n.t('WholeWheat100StraightDough'),
      title: i18n.t('WholeWheat100StraightDough'),
      subtitle: i18n.t('WholeWheat100StraightDough_subtitle'),
      icon: Whole_Wheat_100_straight,
      enabled: true,
      targetShelfLifeOptions: [4, 7, 10, 14, 18, 21],
      sugarOptions: [0, 1, 2, 3, 4, 5],
      fatOptions: [0, 1, 2],
      fatType: ['oil', 'shortening'],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Whole wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '72%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.8%',
            },
            {
              label: i18n.t('Instant dry yeast'),
              value: '1.5%',
            },
            {
              label: i18n.t('Vital gluten'),
              value: '3%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.4%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '30 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Lipopan® Xtra BG"
                  defaults="Lipopan<sup>®</sup> Xtra BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '40 ppm',
            },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '20 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '4 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Gluzyme® Mono BG"
                  defaults="Gluzyme<sup>®</sup> Mono BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '3 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '6 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '8 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Scaling 5 X 680 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Rounding'),
              value: '10 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding / panning (lidded)'),
              value: '10 min',
            },
            {
              label: i18n.t('Proofing: 32°C 85%RH'),
              value: '90 min',
            },
            {
              label: i18n.t('Baking 220 °C'),
              value: '40 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WhitePanBreadStraightDoughIndiaSEAsia',
      name: i18n.t('White pan bread IND'),
      title: i18n.t('White pan bread IND'),
      subtitle: i18n.t('Straight dough'),
      enabled: true,
      icon: White_Pan_Straight_Dough,
      targetShelfLifeOptions: [7],
      sugarOptions: [3, 4, 5],
      fatOptions: [0, 1, 2],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '62%',
            },
            {
              label: i18n.t('Fresh Yeast'),
              value: '4.5%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.8%',
            },
            {
              label: i18n.t('Calcium Propionate'),
              value: '0.5%',
            },
            {
              label: i18n.t('Ascorbic Acid'),
              value: '60 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '6 ppm',
            },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '20 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Lipopan® Elite BG"
                  defaults="Lipopan<sup>®</sup> Elite BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '5 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '8 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Scaling 3 x 660 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding'),
              value: '10 min',
            },
            {
              label: i18n.t('Panning (Lidded)'),
              value: '10 min',
            },
            {
              label: i18n.t('Proofing 35-37°C, 90% RH'),
              value: '60 min',
            },
            {
              label: i18n.t('Baking 220°C'),
              value: '30 min',
            },
          ],
        },
      ],
    },
    {
      key: 'WhitePanBreadSpongeDoughIndiaSEAsia',
      name: i18n.t('White pan bread IND'),
      title: i18n.t('White pan bread IND'),
      subtitle: i18n.t('Sponge dough'),
      enabled: true,
      icon: White_Pan_Sponge_Dough,
      targetShelfLifeOptions: [7],
      sugarOptions: [15, 16, 17, 18, 19, 20],
      fatOptions: [0, 1, 2],
      standardRecipe: [
        {
          title: i18n.t('Ingredients - Sponge 50%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '60%',
            },
            {
              label: i18n.t('Fresh Yeast'),
              value: '5%',
            },
            {
              label: i18n.t('Mono & Diglycerides'),
              value: '0.37%',
            },
            {
              label: i18n.t('SSL'),
              value: '0.1%',
            },
            {
              label: i18n.t('Calcium Propionate'),
              value: '0.25%',
            },
          ],
        },
        {
          title: i18n.t('Ingredients - Dough 50%'),
          items: [
            {
              label: i18n.t('Wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '60%',
            },
            {
              label: i18n.t('Salt'),
              value: '2%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.25%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '80 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process - Sponge'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '6 min',
            },
            {
              label: i18n.t('Sponge fermentation 24°C'),
              value: '60 min',
            },
          ],
        },
        {
          title: i18n.t('Process - Dough'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '6 min',
            },
            {
              label: i18n.t('Scaling 2 X 660 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding / panning (open top)'),
              value: '5 min',
            },
            {
              label: i18n.t('Proofing 35-37°C, 85%RH'),
              value: '50-60 min',
            },
            {
              label: i18n.t('Baking 215°C'),
              value: '~23 min',
            },
            {
              label: '',
              value: `${i18n.t('depending on oven')}`,
            },
          ],
        },
      ],
    },
    {
      key: 'WholeWheat100StraightDoughIndiaSEAsia',
      name: i18n.t('WholeWheat100StraightDough IND'),
      title: i18n.t('WholeWheat100StraightDough IND'),
      subtitle: i18n.t('WholeWheat100StraightDough_subtitle'),
      icon: Whole_Wheat_100_straight,
      enabled: true,
      targetShelfLifeOptions: [7],
      sugarOptions: [4, 5, 6],
      fatOptions: [0, 1, 2],
      standardRecipe: [
        {
          title: i18n.t('Recipe'),
          items: [
            {
              label: i18n.t('Whole wheat flour'),
              value: '100%',
            },
            {
              label: i18n.t('Water'),
              value: '72%',
            },
            {
              label: i18n.t('Salt'),
              value: '1.8%',
            },
            {
              label: i18n.t('Fresh Yeast'),
              value: '4%',
            },
            {
              label: i18n.t('Vital gluten'),
              value: '3-5%',
            },
            {
              label: i18n.t('Calcium propionate'),
              value: '0.4%',
            },
            {
              label: i18n.t('Ascorbic acid'),
              value: '30 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Lipopan® Elite BG"
                  defaults="Lipopan<sup>®</sup> Elite BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '6 ppm',
            },
            {
              label: <Trans i18nKey="Panzea® BG" defaults="Panzea<sup>®</sup> BG" components={{ sup: <sup /> }} />,
              value: '20 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Fungamyl® 4000 SG"
                  defaults="Fungamyl<sup>®</sup> 4000 SG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '4 ppm',
            },
            {
              label: (
                <Trans
                  i18nKey="Gluzyme® Mono BG"
                  defaults="Gluzyme<sup>®</sup> Mono BG"
                  components={{ sup: <sup /> }}
                />
              ),
              value: '15 ppm',
            },
          ],
        },
        {
          title: i18n.t('Process'),
          items: [
            {
              label: i18n.t('Spiral mixer slow'),
              value: '2 min',
            },
            {
              label: i18n.t('Spiral mixer fast'),
              value: '8 min',
            },
            {
              label: i18n.t('Evaluation'),
              value: '5 min',
            },
            {
              label: i18n.t('Scaling 5 X 680 g'),
              value: '5 min',
            },
            {
              label: i18n.t('Rounding'),
              value: '10 min',
            },
            {
              label: i18n.t('Resting'),
              value: '10 min',
            },
            {
              label: i18n.t('Moulding / panning (lidded)'),
              value: '10 min',
            },
            {
              label: i18n.t('Proofing: 35-37°C 85%RH'),
              value: '60 min',
            },
            {
              label: i18n.t('Baking 220 °C'),
              value: '30 min',
            },
          ],
        },
      ],
    },
  ];

  return ApplicationConfigs.map((application) => {
    return {
      ...application,
      sugarOptions: application.sugarOptions ? application.sugarOptions.join() : undefined,
      fatOptions: application.fatOptions ? application.fatOptions.join() : undefined,
      eggOptions: application.eggOptions ? application.eggOptions.join() : undefined,
      ryeFlourOptions: application.ryeFlourOptions ? application.ryeFlourOptions.join() : undefined,
      targetShelfLifeOptions: application.targetShelfLifeOptions
        ? application.targetShelfLifeOptions.join()
        : undefined,
    };
  });
};

export const usesOil = (application: ApplicationType) => {
  return (
    application === 'WhitePanBreadSpongeDough' ||
    application === 'WhitePanBreadStraightDough' ||
    application === 'HamburgerBun' ||
    application === 'WholeWheat100StraightDough' ||
    application === 'WholeWheat100SpongeDough' ||
    application === 'WhitePanBreadSpongeDoughIndiaSEAsia' ||
    application === 'WhitePanBreadStraightDoughIndiaSEAsia' ||
    application === 'WholeWheat100StraightDoughIndiaSEAsia'
  );
};

export const usesMargarine = (application: ApplicationType) => {
  return application === 'WhitePanBreadSweetStraightDough';
};

export const usesButter = (application: ApplicationType) => {
  return application === 'Brioche' || application === 'USBrioche';
};

export const getFatTitle = (application: ApplicationType) => {
  if (usesMargarine(application)) {
    return i18n.t('Margarine');
  } else if (usesOil(application)) {
    return i18n.t('Oil');
  } else if (usesButter(application)) {
    return i18n.t('Butter');
  }

  return i18n.t('Fat');
};

export const getFatLabel = (application: ApplicationType) => {
  if (usesMargarine(application)) {
    return i18n.t('Margarine level');
  } else if (usesOil(application)) {
    return i18n.t('Oil level');
  } else if (usesButter(application)) {
    return i18n.t('Butter level');
  }

  return i18n.t('Fat level');
};

export const getApplicationParamTypes = (application: string): string[] => {
  let paramTypes = [];

  const AppConf = ApplicationConfig().find((a) => a.key === application);

  if (AppConf) {
    if (AppConf.eggOptions) {
      paramTypes.push('egg');
    }
    if (AppConf.fatOptions) {
      paramTypes.push('fat');
    }
    if (AppConf.ryeFlourOptions) {
      paramTypes.push('ryeFlour');
    }
    if (AppConf.sugarOptions) {
      paramTypes.push('sugar');
    }
    if (AppConf.targetShelfLifeOptions) {
      paramTypes.push('targetShelfLife');
    }
  }
  return paramTypes;
};

export const getParamLabel = (application: ApplicationType, key: string): string => {
  switch (key) {
    case 'ryeFlour':
      return i18n.t('Rye flour');

    case 'targetShelfLife':
      return i18n.t('Target shelf life');

    case 'sugar':
      return i18n.t('Sugar');

    case 'egg':
      return i18n.t('Egg');

    case 'fat':
      return getFatLabel(application);

    default:
      return '';
  }
};

export default ApplicationConfig;
