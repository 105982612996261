import i18n from '../../i18NextConf';

export const regionOptions = [
  // { key: '', val: i18n.t('Select region') },
  { key: 're_EU', val: i18n.t('Europe') },
  { key: 're_NA', val: i18n.t('North America') },
  { key: 're_SA', val: i18n.t('South America') },
  { key: 're_CH', val: i18n.t('China') },
  { key: 're_IND', val: i18n.t('India') },
  { key: 're_SEA', val: i18n.t('South-East Asia') },
  { key: 're_RAP', val: i18n.t('Rest of Asia Pacific') },
  { key: 're_ME', val: i18n.t('MEA') },
  { key: 're_RU', val: i18n.t('Russia') },
  { key: 're_JA', val: i18n.t('Japan') },
];

export const companyTypeOptions = [
  // { key: '', val: i18n.t('Select your company type') },
  { key: 'ct_bakery', val: i18n.t('Bakery') },
  { key: 'ct_formulator', val: i18n.t('Formulator') },
  { key: 'ct_distributor', val: i18n.t('Distributor') },
];

export const formFields = {
  signIn: {
    username: {
      label: i18n.t('Email') + ' *',
      placeholder: i18n.t('Enter your email address'),
      key: 'email',
      isRequired: true,
      type: 'email',
      order: 1,
      id: 'signIn-email',
    },
    password: {
      label: i18n.t('Password') + ' *',
      placeholder: i18n.t('Enter your password'),
      key: 'password',
      isRequired: true,
      type: 'password',
      order: 2,
      id: 'signIn-password',
    },
  },
  signUp: {
    name: {
      label: i18n.t('Name') + ' *',
      placeholder: i18n.t('Enter your full name'),
      key: 'name',
      isRequired: true,
      type: 'text',
      order: 1,
    },
    username: {
      label: i18n.t('Email') + ' *',
      placeholder: i18n.t('Enter your email address'),
      key: 'email',
      isRequired: true,
      type: 'email',
      order: 2,
    },
    password: {
      label: i18n.t('Password') + ' *',
      placeholder: i18n.t('Enter your password'),
      key: 'password',
      isRequired: true,
      type: 'password',
      order: 3,
    },
    confirm_password: {
      label: i18n.t('Confirm password') + ' *',
      placeholder: i18n.t('Please confirm your password'),
      key: 'confirm_password',
      isRequired: true,
      type: 'password',
      order: 4,
    },
  },
  resetPassword: {
    username: {
      label: i18n.t('Email'),
      placeholder: i18n.t('Enter your email address'),
      key: 'email',
      isRequired: true,
      type: 'email',
      order: 1,
    },
  },
};
