import React from 'react';

import { styled } from '../../theme';

const Wrapper = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    font-family: inherit;
    font-size: 16px;
    line-height: normal;
    color: #2d0127;
    outline: none;
    padding: 10px;
    min-height: 120px;
    resize: none;
  }
`;

const InputWrapper = styled.div<{
  focused: boolean;
  maxWidth: number;
  marginBottom: number;
  isHidden: boolean;
}>`
  ${({ isHidden }) =>
    !isHidden &&
    `
  position: relative;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  border: solid 1px #e7e7e7;
  background-color: #fff;
  padding: 0;
  box-sizing: border-box;
  transition: margin .35s ease;

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
`}
`;
const InputLabel = styled.div`
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  color: inherit;
  user-select: none;
  margin-bottom: 10px;
`;
const InputValue = styled.input<{ error: boolean }>`
  width: 100%;
  height: 38px;
  border: 0;
  padding: 0 8px;
  background: transparent;
  font-family: inherit;
  font-size: 18px;
  font-stretch: normal;
  line-height: normal;
  color: #2d0127;
  outline: none;
  ${({ error }) =>
    error &&
    `
    border: solid 1px red;
  `}
`;
const HiddenInput = styled.input.attrs((props) => ({
  name: props.name,
}))``;

export default class Input extends React.PureComponent<
  {
    value: any;
    unit?: string;
    label: string;
    maxWidth: number;
    marginBottom?: number;
    pattern?: string;
    error: boolean;
    onValue: (value: string) => void;
    className?: string;
    placeholder?: string;
    inputType?: string;
    isHidden?: boolean;
    name: string;
  },
  { focused: boolean }
> {
  state = {
    focused: false,
  };
  onFocus = () => {
    this.setState({ focused: true });
  };
  onBlur = () => {
    this.setState({ focused: false });
  };
  onValue = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
    switch (e.target.type) {
      case 'textarea':
        this.props.onValue(e.target.value);
        break;
      default:
        this.props.onValue(e.target.validity.valid ? e.target.value : this.props.value);
    }
  };

  render() {
    const { focused } = this.state;
    const {
      value = '',
      unit,
      label,
      maxWidth,
      marginBottom = 0,
      pattern,
      className,
      placeholder = '',
      inputType = 'text',
      error,
      isHidden = false,
      name = '',
    } = this.props;

    return (
      <Wrapper>
        {!isHidden && <InputLabel>{label}</InputLabel>}
        <InputWrapper focused={focused} maxWidth={maxWidth} marginBottom={marginBottom} isHidden={isHidden}>
          {inputType === 'text' && isHidden && (
            <InputValue hidden value={value} type="text" name={name} error={error} onChange={this.onValue} />
          )}

          {inputType === 'text' && !isHidden && (
            <InputValue
              name={name}
              value={value}
              type="text"
              pattern={pattern === undefined ? '[a-zA-Z0-9.s]*' : pattern}
              onChange={this.onValue}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              className={className}
              placeholder={placeholder}
              error={error}
            />
          )}

          {inputType === 'textarea' && (
            <textarea
              name={name}
              defaultValue={value}
              onChange={this.onValue}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              className={className}
              placeholder={placeholder}
            />
          )}
        </InputWrapper>
      </Wrapper>
    );
  }
}
