import React, { useCallback, useState } from 'react';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

import BackButton from './back-button';
import i18n from '../i18NextConf';
import { Button, colors, IconButton, Message } from '@novozymes-digital/components';
import Modal from './modal';
import Form from './form';
import Input from './input';
import PardotDialog from './dialogs/PardotDialog';

const Wrapper = styled('div')`
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 0;
  overflow: hidden;
`;

const Sup = styled('sup')`
  font-size: 20px;
  line-height: 1;
`;

const TitleWrapper = styled('div')<{ isResultsPage: boolean }>`

    border-bottom: solid 1px #dbdddb;
    padding-bottom: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 50px;

    ${({ isResultsPage }) =>
      !isResultsPage &&
      `
      ${MediaQueries.large} {
        padding-top: 46px;
      }
    `}

    ${MediaQueries.medium} {
      padding-bottom: 0px;
    }


  ${MediaQueries.large} {
    margin-bottom: ${({ isResultsPage }) => (isResultsPage ? '79px' : '58px')};
  }

  ${MediaQueries.medium} {
    max-width: 100%;

    ${({ isResultsPage }) =>
      !isResultsPage &&
      `
      margin-bottom: 20px;
    `}

  ${MediaQueries.small} {
    padding-right: 20px;
  }
`;

const Title = styled('div')`
  position: relative;
  margin-bottom: 22px;
  color: inherit;

  ${MediaQueries.large} {
  }

  ${MediaQueries.medium} {
    display: flex;
    margin-bottom: 0px;
    align-items: middle;
    justify-content: center;
    height: 52px;
  }

  ${MediaQueries.small} {
  }
`;

const SubTitle = styled('div')`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: inherit;

  ${MediaQueries.large} {
  }

  ${MediaQueries.medium} {
    margin-bottom: 0px;
    vertical-align: middle;
  }

  ${MediaQueries.small} {
  }
`;

const TitleNumber = styled('span')`
  font-weight: 300;
  font-size: 30px;
  line-height: 120%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;

  ${MediaQueries.small} {
    font-size: 24px;
    line-height: calc(28 / 24);
  }
`;

const TitleText = styled('span')`
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  white-space: nowrap;
  color: inherit;

  ${MediaQueries.medium} {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1;
  }

  ${MediaQueries.medium} {
    margin-left: 40px;
  }
`;

const Description = styled('div')`
  font-size: 18px;
  line-height: 25px;
  color: ${colors.black};
  max-width: 620px;

  ${MediaQueries.large} {
    margin-bottom: 41px;
    padding-left: ${({ theme }) => theme.padding.large};
  }

  ${MediaQueries.medium} {
    margin-bottom: 41px;
    vertical-align: middle;
    padding-left: ${({ theme }) => theme.padding.medium};
  }

  ${MediaQueries.small} {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    padding-left: ${({ theme }) => theme.padding.small};
    padding-right: ${({ theme }) => theme.padding.small};
  }
`;

const IconButtonWrapper = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;

  ${MediaQueries.medium} {
    right: 20px;
    bottom: 20px;
  }
`;

type Props = {
  currentPage?: string;
  previousPage?: string;
  title: string;
  subtitle?: string;
  description?: string;
};

const reactReplaceString = require('react-string-replace');

const StepHeader = (props: Props) => {
  const { title = '', subtitle = '', description = '', previousPage = '', currentPage = '' } = props;

  const [openModal, setOpenModal] = useState(false);

  const onModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleContactClick = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleFormSubmit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const isResultPage = currentPage === 'ResultsPage';

  return (
    <>
      <PardotDialog
        dialogTitle={i18n.t('Contact us title')}
        dialogIntro={i18n.t('Contact us subtitle')}
        show={openModal}
        onClose={handleFormSubmit}
        subject="Contact"
      />

      {isResultPage && (
        <IconButtonWrapper>
          <IconButton id="result-fab-contact-us" onClick={handleContactClick}>
            <Message />
          </IconButton>
        </IconButtonWrapper>
      )}

      <Wrapper className="negative-margin">
        <TitleWrapper isResultsPage={isResultPage}>
          <Title className="Title padding">
            <BackButton id="header-btn-back" goBackUrl={previousPage !== '' ? previousPage : null} />

            {title !== '' && <TitleText>{title}</TitleText>}
          </Title>

          {isResultPage && (
            <Button id="result-btn-contact-us" onClick={handleContactClick} type="secondary">
              {i18n.t('Contact us')}
            </Button>
          )}
        </TitleWrapper>

        {description !== '' && (
          <Description>
            {reactReplaceString(description, '*', (match, i) => (
              <Sup key={i}>{match}</Sup>
            ))}
          </Description>
        )}

        {subtitle !== '' && <SubTitle className="padding">{subtitle}</SubTitle>}
      </Wrapper>
    </>
  );
};

export default StepHeader;
