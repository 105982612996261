import React, { CSSProperties, ReactElement, ReactNode } from 'react';

const WrapWithLink = ({
  link,
  target,
  id,
  children,
  style = {},
}: {
  link: string;
  children?: ReactNode;
  target?: string;
  id?: string;
  style?: CSSProperties;
}): ReactElement => {
  if (link) {
    return (
      <a href={link} target={target} id={id} style={{ color: 'inherit', textDecoration: 'inherit', ...style }}>
        {children}
      </a>
    );
  }
  return <>{children}</>;
};

export default WrapWithLink;
