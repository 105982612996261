import React, { useContext } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';

import BackButton from '../../../components/back-button';
import { ThemeContext } from '../../../themecontext';
import QuoteSVG from '../../../images/customer_quote.svg';

import { Utils } from '../../../helpers/equal_height';

const Li = styled('li')`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  flex-basis: calc(33% - 8px);
  flex-grow: 0;
  flex-shrink: 1;
  background: #113f49;
  padding: 30px 24px 20px;
  margin-bottom: 30px;
  color: #fff;
  min-height: 245px;
`;

const QuoteImage = styled('img')`
  position: absolute;
  left: 30px;
  width: 64px;
  height: auto;

  ${MediaQueries.large} {
    top: -20px;
    left: 30px;
  }

  ${MediaQueries.medium} {
    top: -50px;
    left: 0px;
  }

  ${MediaQueries.small} {
    top: -35px;
    left: 0px;
    width: 54px;
    height: auto;
  }
`;

const Quote = styled('div')`
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  padding: 20px 0 0;
  text-align: center;
`;

const Customer = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 23px;

  @media screen and (min-width: 601px) and (max-width: 992px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const Region = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  margin-top: 6px;

  @media screen and (min-width: 601px) and (max-width: 992px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const Divider = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin: 10px 0 0;
`;

const Line = styled('div')`
  display: inline-block;
  width: 54px;
  height: 2px;
  background: #16515e;
`;

const Wrapper = styled('div')`
  width: calc(100% - 10px);
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #113f49;
  color: #fff;

  p {
    line-height: 24px;
  }

  ${MediaQueries.medium} {
    min-height: 290px;
    padding: 0 20px;
  }
`;

const Div = styled('div')`
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 23px;

  ${MediaQueries.small} {
    max-width: 216px;
  }
`;

type Props = {
  country: string;
  quote: string;
  customer?: string;
};

const Statement = (props: Props) => {
  const context = useContext(ThemeContext);
  const { quote = '', customer = '', country = '' } = props;
  const { region } = context;
  const { desktop, tablet, mobile } = context.device;

  function getRegion(current_region: string): string {
    let quoteRegion: string = '';

    switch (current_region) {
      case 're_CH':
        quoteRegion = 'China';
        break;
      default:
        quoteRegion = 'EU';
    }

    return quoteRegion;
  }

  return desktop ? (
    <Li>
      <QuoteImage src={QuoteSVG} />
      <Quote className="slider-quote equal-h">{quote}</Quote>
      <Divider>
        <Line />
      </Divider>
      <Region>
        {country}
        {/*getRegion(region)*/}
      </Region>
      <Customer>{customer}</Customer>
    </Li>
  ) : (
    <Wrapper>
      <Div>
        <QuoteImage src={QuoteSVG} />
        <Quote className="slider-quote equal-h">{quote}</Quote>
        <Divider>
          <Line />
        </Divider>
        <Region>
          {country}
          {/*getRegion(region)*/}
        </Region>
        <Customer>{customer}</Customer>
      </Div>
    </Wrapper>
  );
};

export default Statement;
