import React, { useEffect, createRef, useRef } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';
import { getBrowserHeight } from '../../../helpers/general';

import BioScriptBG from '../../../images/gfx-bioscript.svg';
import { colors } from '@novozymes-digital/components';

const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
  min-width: 100%;
  min-height: 100%;
  display: none;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Container = styled.div`
  background-color: transparent;
  max-width: 673px;
  width: 100%;
  position: absolute;
  z-index: 999;
  left: 50%;
  margin-right: -50%;
  padding: 60px 20px 20px;

  &:not(.top) {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.top {
    top: 0;
    transform: translate(-50%, 0%);
  }

  ${MediaQueries.small} {
    padding: 80px 30px 80px;
  }
`;

const Div = styled('div')`
  width: 100%;
  background: #f6f6f6 url('${BioScriptBG}') calc(100% + 30px) top repeat-y !important;
  padding: 0px 60px 30px;

  ${MediaQueries.large} {
    background-size: 80px auto !important;
  }

  ${MediaQueries.medium} {
    background-size: 80px auto !important;
  }

  ${MediaQueries.small} {
    background-size: 50px auto !important;
    padding: 0 40px 30px 40px;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 10;

  ${MediaQueries.small} {
    top: 42px;
    right: 30px;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 14px;
    content: ' ';
    height: 28px;
    width: 2px;
    background-color: #fff;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  form {
    background: #f6f6f5;
    color: ${colors.black};
  }
`;

interface Props {
  name?: string;
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  renderModal: string;
}

const LearnMoreModal = (props: Props) => {
  const { children, open, onClose, renderModal } = props;

  const parentContainerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const onOverlayClick = (e: React.MouseEvent) => {
    /*e.preventDefault()*/

    let target = e.target as any | null;
    let currentTarget = e.currentTarget as any | null;

    if (target === null || currentTarget === null || typeof target.className !== 'string') return;

    if (target.className.indexOf('close') > -1) {
      currentTarget.click();
    }

    if (e.target === e.currentTarget || target.className.indexOf('container') > -1) {
      onClose();
      if (localStorage.getItem('scrollPos') !== null) {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }
    }
  };

  useEffect(() => {
    if (containerRef.current != null && parentContainerRef.current != null) {
      parentContainerRef.current.scrollTop = 0;
      containerRef.current.classList.remove('top');
      containerRef.current.offsetHeight > getBrowserHeight() ? (containerRef.current.className += ' top') : '';
    }
  }, [renderModal]);

  return (
    <Overlay
      onClick={typeof onClose === 'function' ? onOverlayClick : (): void => {}}
      className={open ? 'open' : ''}
      ref={parentContainerRef}
    >
      <Container className="container learnmore" ref={containerRef}>
        {typeof onClose === 'function' && <CloseBtn className="close" />}
        <Div>{children}</Div>
      </Container>
    </Overlay>
  );
};

export default LearnMoreModal;
