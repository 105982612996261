import React from 'react';
import { styled } from '../../theme';
import i18n from '../../i18NextConf';

import { MediaQueries } from '../../theme/media-queries-css';

if (!('scrollBehavior' in document.documentElement.style)) {
  import('scroll-behavior-polyfill').then(() => {});
}

import ApplicationConfig, { IApplicationConfig } from '../application/application-config';

import { ThemeContext } from '../../themecontext';

import VerticalArrow from '../../images/vertical-arrow.svg';
import { colors } from '@novozymes-digital/components';
import { getFatTitle } from '../application/application-config';

const Wrapper = styled.div`
  margin-top: 10px;
`;

const Input = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #421a3c;
  margin-bottom: 18px;
  padding-bottom: 18px;
  font-family: inherit;

  &:first-of-type {
    border-top: solid 1px #421a3c;
    margin-top: 18px;
    padding-top: 18px;
  }

  ${MediaQueries.large} {
    font-size 18px;
    line-height: 24px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    margin-bottom: 12px;
    width: 100%;
    color: ${colors.black80};

    > *:first-of-type {
      flex-grow: 1;
      flex-shrink: 1;
    }
    > *:last-of-type {
      flex-grow: 0;
      flex-basis: 0;

      > div:last-child {
        color: inherit;
      }

    }
  }
`;

const StandardRecipe = styled.div<{ dropdown: boolean }>`
  border-bottom: ${({ dropdown }) => (dropdown ? null : 'solid 1px #370d32')};
  margin-bottom: ${({ dropdown }) => (dropdown ? null : '18px')};
  padding-bottom: ${({ dropdown }) => (dropdown ? null : '18px')};

  ${MediaQueries.large} {
    font-size: 18px;
    line-height: 25px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Label = styled.div`
  color: ${colors.black80};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const RecipeParametersWrapper = styled.div`
  background: ${colors.bg3};
  padding: 20px;
  margin-top: 10px;

  & > div:not(& > div:last-child) > div:last-child {
    margin-bottom: 20px;
  }

  ${MediaQueries.medium} {
    margin-bottom: 40px;
  }
`;

const DropDownRecipeLabel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 12px;
  min-width: 80px;
  height: 26px;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.color.lightgrey};
  font: bold 14px/1 'Novozymes';
  color: inherit;
  cursor: pointer;
  margin-top: 4px;

  &.show:before,
  &.hide:before {
    position: absolute;
    right: 13px;
    top: 50%;
    margin-left: 10px;
    background: url(${VerticalArrow}) no-repeat 50% 50%;
    transform: translateY(-50%) rotate(-180deg);
    background-size: 10px auto;
    width: 10px;
    height: calc((60.1 / 100) * 10px);
    content: '';
  }

  &.hide:before {
    transform: translateY(-50%) rotate(0deg);
  }
`;
const Value = styled.div`
  color: ${colors.black80};
  white-space: nowrap;

  &.bold {
    font-weight: bold;
  }
`;

const RecipeInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:nth-child(1) {
    ${MediaQueries.large} {
      max-width: 175px;
      line-height: 22px;
    }
  }

  > div:nth-child(2) {
    ${MediaQueries.large} {
      line-height: 22px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  ${MediaQueries.medium} {
  }
`;

const RecipeTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:nth-child(1) {
    ${MediaQueries.large} {
      max-width: 230px;
      line-height: 22px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > div:last-child {
    margin-left: 5px;
  }
`;

const RecipeLabel = styled.div`
  color: ${colors.black80};

  &.bold {
    font-weight: bold;
  }
`;

type Props = {
  sugar?: number;
  fat?: number;
  egg?: number;
  ryeFlour?: number;
  targetShelfLife?: number;
  unit: string;
  region: string;
};

type State = {
  showParameters: boolean;
  application: IApplicationConfig;
};

export default class RecipeParameters extends React.PureComponent<Props, State> {
  static contextType = ThemeContext;
  private sidebarStatus: boolean = false;
  private sidebarHeight: number;

  state = {
    showParameters: false,
    application: ApplicationConfig().find((a) => a.key == localStorage.getItem('recipe-param-application')),
  };

  openRecipeParameters = (openClosed: boolean, pos: number, tablet: boolean) => {
    let Body: HTMLElement = document.querySelector('body');
    let leftColumn: HTMLElement = document.querySelector('.left-column');
    let sidebarWrapper: HTMLElement = document.querySelector('.sidebar-wrapper');

    this.sidebarHeight = !this.sidebarStatus ? leftColumn.clientHeight : this.sidebarHeight;
    this.sidebarStatus = true;

    let setSliderHeight = (openClose: string): void => {
      let screenHeight = window.innerHeight;
      let leftColumnHeight = sidebarWrapper.offsetHeight;

      switch (openClose) {
        case 'open':
          if (leftColumnHeight > screenHeight) {
            leftColumn.style.height = screenHeight + 'px';
          } else {
            leftColumn.style.height = leftColumnHeight + 'px';
            leftColumn.style.removeProperty('auto');
          }
          break;
        case 'close':
          leftColumn.style.height = this.sidebarHeight + 'px';
          leftColumn.style.removeProperty('auto');
          break;
      }
    };

    if (!openClosed) {
      /* Recipe parameters is open */
      let leftColumn: HTMLElement = document.querySelector('.left-column');
      leftColumn.style.overflow = 'auto';
      leftColumn.style.scrollBehavior = 'smooth';
      setTimeout(() => {
        leftColumn.scrollTop = pos + 30;
        tablet ? setSliderHeight('open') : null;
      }, 50);
      Body.style.overflow = 'hidden';
    } else {
      Body.style.removeProperty('overflow');
      leftColumn.style.removeProperty('overflow');
      leftColumn.style.removeProperty('scroll-behavior');
      tablet ? setSliderHeight('close') : null;
    }
  };

  toggleParameters = () => {
    const { tablet } = this.context.device;
    const { showParameters } = this.state;

    if (tablet) {
      let StandardRecipe: HTMLElement = document.querySelector('.left-column .StandardRecipe');
      let offsetTopPos = StandardRecipe.offsetTop;

      this.openRecipeParameters(showParameters, offsetTopPos, tablet);
    }

    this.setState({
      showParameters: !showParameters,
    });
  };

  render() {
    const { showParameters } = this.state;
    const { targetShelfLife, sugar, fat, egg, ryeFlour, unit, region } = this.props;

    let application = ApplicationConfig().find((a) => a.key == localStorage.getItem('recipe-param-application'));

    const recipeParameters = application.standardRecipe;

    const getParamValue = (parameter) => {
      if (typeof parameter.value !== 'string') {
        return parameter.value({ targetShelfLife, sugar, fat, egg, ryeFlour });
      }

      const isRecipeInPPM = parameter.value.indexOf('ppm') !== -1;
      if (unit === 'gram' && isRecipeInPPM) {
        const ppmValue = Number(parameter.value.split(' ')[0]);
        return `${ppmValue / (region === 're_CH' ? 40 : 10)} ${
          region === 're_CH' ? i18n.t(' g / 25 kg') : i18n.t('g / 100 kg')
        }`;
      }
      return parameter.value;
    };

    return (
      <Wrapper className="application-subtitle">
        {application.targetShelfLifeOptions ? (
          <Input>
            <Label>{i18n.t('Target shelf life')}</Label>
            <Value className="bold">
              {targetShelfLife} {targetShelfLife === 1 ? i18n.t('day') : i18n.t('days')}
            </Value>
          </Input>
        ) : (
          ''
        )}

        {application.sugarOptions ? (
          <Input>
            <Label>{i18n.t('Sugar')}</Label>
            <InputWrapper>
              <Value className="bold">{`${sugar !== null ? sugar : ''}%`}</Value>
              {/*<RecipeLabel className="bold">{i18n.t('sugar')}</RecipeLabel>*/}
            </InputWrapper>
          </Input>
        ) : (
          ''
        )}

        {application.fatOptions ? (
          <Input>
            <Label>{getFatTitle(application.key)}</Label>
            <InputWrapper>
              <Value className="bold">{`${fat !== null ? fat : ''}%`}</Value>
              {/*<RecipeLabel className="bold">{i18n.t('fat')}</RecipeLabel>*/}
            </InputWrapper>
          </Input>
        ) : (
          ''
        )}

        {application.eggOptions ? (
          <Input>
            <Label>{i18n.t('Egg')}</Label>
            <InputWrapper>
              <Value className="bold">{`${egg !== null ? egg : ''}%`}</Value>
              {/*<RecipeLabel className="bold">{i18n.t('fat')}</RecipeLabel>*/}
            </InputWrapper>
          </Input>
        ) : (
          ''
        )}

        {application.ryeFlourOptions ? (
          <Input>
            <Label>{i18n.t('Rye flour')}</Label>
            <InputWrapper>
              <Value className="bold">{`${ryeFlour !== null ? ryeFlour : ''}%`}</Value>
              {/*<RecipeLabel className="bold">{i18n.t('fat')}</RecipeLabel>*/}
            </InputWrapper>
          </Input>
        ) : (
          ''
        )}

        <StandardRecipe className="StandardRecipe" dropdown={showParameters}>
          <LabelWrapper className="LabelWrapper">
            <Label className="StandardRecipeLabel">{i18n.t('Based upon standard recipe')}</Label>
            <DropDownRecipeLabel
              data-cy="recipe-dropdown-toggle-recipe"
              id="recipe-dropdown-toggle-recipe"
              className={`DropDownRecipeLabel${showParameters ? ' show' : ' hide'}`}
              onClick={this.toggleParameters}
            >
              {showParameters ? i18n.t('Hide') : i18n.t('Show')}
            </DropDownRecipeLabel>
          </LabelWrapper>
          {showParameters && recipeParameters ? (
            <RecipeParametersWrapper className="RecipeParametersWrapper">
              {recipeParameters.map((step, index) => (
                <div key={index}>
                  <RecipeTitle className="RecipeTitle" key={index}>
                    <RecipeLabel className="bold">{step.title}</RecipeLabel>
                  </RecipeTitle>

                  {step.items.map((parameter, idx) => (
                    <RecipeInput className="RecipeInput" key={idx}>
                      <RecipeLabel>{parameter.label}</RecipeLabel>
                      <Value className="bold">{getParamValue(parameter)}</Value>
                    </RecipeInput>
                  ))}
                </div>
              ))}
            </RecipeParametersWrapper>
          ) : null}
        </StandardRecipe>
      </Wrapper>
    );
  }
}
