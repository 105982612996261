import React from 'react';
import styled from 'styled-components';
import { Reset } from 'styled-reset';
import i18n from '../i18NextConf';

import { ThemeContext } from '../themecontext';
import { debounceAnimationFrame } from '../utils';
import RegionConfig from '../region-config';

interface Props {
  region: string;
  lang: string;
}

export default class GlobalWrapper extends React.PureComponent<
  {
    children: React.ReactNode;
  },
  Props
> {
  state = {
    region: '',
    lang: i18n.options.resources[i18n.languages[0]] !== undefined ? i18n.languages[0] : 'en',
  };

  async componentDidMount() {
    let region = await RegionConfig.getUserRegion();

    this.setState({
      region: region,
    });

    window.addEventListener(
      'resize',
      debounceAnimationFrame(() => {
        this.forceUpdate();
      })
    );

    //window.dispatchEvent(new Event('resize'))
  }

  setLanguage = (language: string) => {
    this.setState({
      lang: language,
    });
  };

  deviceWidth = (): number => {
    return window.innerWidth || document.body.clientWidth;
  };

  render() {
    const { region, lang } = this.state;
    const deviceWidth = this.deviceWidth();

    const Tablet = deviceWidth < 993;
    const Mobile = deviceWidth <= 640;
    const Desktop = deviceWidth > 992;

    return (
      <>
        <Reset />
        <ThemeContext.Provider
          value={{
            device: {
              tablet: Tablet,
              mobile: Mobile,
              desktop: Desktop,
            },
            region: region,
            language: this.setLanguage,
            lang: lang,
          }}
        >
          {this.props.children}
        </ThemeContext.Provider>
      </>
    );
  }
}
