import React from 'react';

import { styled } from '../theme';
import NovozymesLogoImage from '../images/ic-novozymes-black.svg';

const NovozymesLogoImg = styled.img`
  display: block;
`;

export default class NovozymesLogo extends React.PureComponent {
  render() {
    return <NovozymesLogoImg width="152" height="54" src={NovozymesLogoImage} />;
  }
}
