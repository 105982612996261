import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import i18n from '../../i18NextConf';
import { Enzyme } from '../../types';
import GetProductLinks from '../../static-data/ProductLinks';
import WrapWithLink from '../../components/WrapWithLink';
import EnzymeActions from './enzyme-actions';
import { colors } from '@novozymes-digital/components';
import { Trans } from 'react-i18next';

const Wrapper = styled.div`
  flex-basis: 520px;
  width: 100%;
  margin-right: 0;
  margin-bottom: 0;

  ${MediaQueries.small} {
    flex-basis: auto;
  }

  @media screen and (max-width: 1280px) {
    flex-shrink: 1;
  }
`;

const EnzymeInfoWrapper = styled.div`
  max-width: 400px;
  flex-grow: 0;
  flex-shrink: 1;
  padding-right: 0;
  padding-left: 0;
  border-left: 0;

  @media screen and (max-width: 1460px) {
    max-width: 760px;
  }

  ${MediaQueries.medium} {
  }
`;

const EnzymeName = styled.div`
  color: inherit;
  font-family: Novozymes;
  font-weight: bold;
  margin-bottom: 5px;

  ${MediaQueries.large} {
    font-size: 22px;
    line-height: 30px;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 3px;
  }

  ${MediaQueries.small} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
  }
`;

const EnzymeDescription = styled.div`
  color: ${colors.black};
  font-family: inherit;

  ${MediaQueries.large} {
    font-size: 18px;
    line-height: 25px;
    display: inline-block;
    margin-left: 4px;
    font-weight: normal;
  }

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }

  ${MediaQueries.small} {
    font-size: 12px;
    font-weight: bold;
    line-height 16px;
    max-width: 120px;
  }
`;

const DossageLabel = styled.div`
  font-size: 18px;
  line-height: 25px;
  display: inline-block;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }

  ${MediaQueries.small} {
    font-size: 12px;
    line-height: 24px;
  }
`;

type Props = {
  name: Enzyme;
  description: any;
  color: string;
  region: string;
  type: string;
};

export default class EnzymeInfo extends React.PureComponent<Props> {
  render() {
    const { name, description, color, region, type } = this.props;
    const language = i18n.languages[0];
    const productLinks = GetProductLinks(language)[name];

    return (
      <Wrapper className="enzyme-info">
        <EnzymeInfoWrapper className="Wrapper" color={color}>
          <WrapWithLink link={productLinks && productLinks.info} id="enzyme-list-product-page" target="_blank">
            <EnzymeName className="enzyme-name" color={color}>
              {type === 'control' ? null : (
                <Trans
                  i18nKey={name}
                  components={{
                    sup: <sup />,
                  }}
                />
              )}{' '}
              {type !== 'control' && <InfoOutlinedIcon fontSize="small" />}
            </EnzymeName>
          </WrapWithLink>
          {type !== 'control' ? <DossageLabel>{i18n.t('Dosage:')}</DossageLabel> : null}

          <EnzymeDescription className="enzyme-descr" style={type === 'control' ? { fontWeight: 'bold' } : null}>
            {description}
          </EnzymeDescription>
          <br />
          {type !== 'control' ? <EnzymeActions name={name} language={language} /> : null}
        </EnzymeInfoWrapper>
      </Wrapper>
    );
  }
}
