import React from 'react';

import { styled } from '../../theme';
import EnzymeInfo from './enzyme-info';
import EnzymeIndicators from './enzyme-indicators';
import { Enzyme } from '../../types';

const EnzymeInfoWrapper = styled.div`
  flex-basis: 40%;
`;

const EnzymeListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const EnzymeIndicatorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-basis: 60%;
`;

type Props = {
  type: string;
  region: string;
  device: any;
  label: string;
  name?: Enzyme;
  description: string;
  color: string;
  application: string;
};

export default class EnzymeListItem extends React.PureComponent<Props> {
  render() {
    const { name, label, color, type, device, description, region, application } = this.props;

    const { tablet, desktop } = device;

    const EnzymeListItemTablet = (
      <EnzymeListItemWrapper>
        <EnzymeInfoWrapper>
          <EnzymeInfo name={name} description={description} color={color} region={region} type={type} />
        </EnzymeInfoWrapper>
        <EnzymeIndicatorsWrapper>
          <EnzymeIndicators type={type} enzymeName={name} application={application} region={region} />
        </EnzymeIndicatorsWrapper>
      </EnzymeListItemWrapper>
    );

    return EnzymeListItemTablet;
  }
}
