import React, { useContext } from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import i18n from '../../i18NextConf';
import GoBack from '../go-back';

const Wrapper = styled('div')<{
  marginBottom: number;
}>`
  position: relative;
  padding: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Line = styled('div')<{
  marginBottom: number;
}>`
  height: 1px;
  background: #dbdddb;
  margin-top: 20px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const H3 = styled('h3')`
  display: flex;
  font-weight: bold;
  color: inherit;
  justify-content: center;

  ${MediaQueries.large} {
    font-size: 28px;
    line-height: 36px;
  }

  ${MediaQueries.small} {
    font-size: 20px;
    line-height: 28px;
    padding-left: 40px;
  }
`;

interface Props {
  title: string;
  goBack: () => any;
  marginBottom?: number;
}

const NaviHeader = (props: Props) => {
  const { title = '', goBack, marginBottom = 20 } = props;

  return (
    <>
      <Wrapper marginBottom={marginBottom}>
        <GoBack {...props} />
        <H3>{title}</H3>
      </Wrapper>
      <Line marginBottom={marginBottom} />
    </>
  );
};

export default NaviHeader;
