import React, { useState, useEffect } from 'react';
import { Recommendation } from '../../types';
import { fetchPreviousRecommendationsAPI } from '../../services/api';
import SavedRecommendation from './savedRecommendation';

interface Props {
  updateRecommendations: boolean;
  closeMenu: () => void;
  resetHistory: () => void;
}

const RecommendationsCH = (props: Props) => {
  const { updateRecommendations, closeMenu, resetHistory } = props;
  //const [isLoading, setIsLoading] = useState(true)
  const [previousRecommendations, setPreviousRecommendations] = useState<Array<Recommendation>>([]);

  useEffect(() => {
    async function getRecommendations() {
      const history = await fetchPreviousRecommendationsAPI();
      const fetchedRecommendations = history;
      //setIsLoading(false)
      setPreviousRecommendations(fetchedRecommendations);
    }

    getRecommendations();
  }, [updateRecommendations]);

  //if (isLoading) return <Loader />

  if (previousRecommendations.length > 0) {
    return (
      <>
        {previousRecommendations.map((item: Recommendation, index: number) => {
          return <SavedRecommendation key={index} closeMenu={closeMenu} resetHistory={resetHistory} savedData={item} />;
        })}
      </>
    );
  } else {
    return null;
  }
};

export default RecommendationsCH;
