import { colors } from '@novozymes-digital/components';
import React from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import RecipeParameters from './recipe-parameters';

const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Title = styled('div')`
  font-family: inherit;
  font-weight: bold;
  color: ${colors.black80};
  margin-bottom: 0px;
  padding-right: 16px;

  ${MediaQueries.large} {
    font-size: 32px;
    line-height: 36px;
    max-width: 254px;
  }

  ${MediaQueries.medium} {
    font-size: 25px;
    line-height: 28px;
  }
`;

export const SubTitle = styled('div')`
  font-family: inherit;
  color: ${colors.black80};
  margin-top: 8px;
  margin-bottom: 0px;

  ${MediaQueries.large} {
    font-size: 16px;
    line-height: 14px;
    max-width: 254px;
  }

  ${MediaQueries.medium} {
    font-size: 14px;
    line-height: 14px;
  }
`;

const ApplicationIconWrapper = styled.div`
  height: 50px;
  width: 50px;
  padding: 6px;
`;
const ApplicationIcon = styled.img`
  width: 100%;
  height: auto;
`;

const Sidebar = ({ applicationData, input, unit, region }) => {
  const { sugar, fat, egg, ryeFlour, targetShelfLife } = input;

  return (
    <>
      <TitleWrapper>
        <Title className="application-header">{applicationData.name}</Title>
        <ApplicationIconWrapper>
          <ApplicationIcon alt={`${applicationData.name} icon`} src={applicationData.icon} />
        </ApplicationIconWrapper>
      </TitleWrapper>

      {applicationData.subtitle && <SubTitle>{applicationData.subtitle}</SubTitle>}
      <RecipeParameters
        unit={unit}
        sugar={sugar}
        fat={fat}
        egg={egg}
        ryeFlour={ryeFlour}
        targetShelfLife={targetShelfLife}
        region={region}
      />
    </>
  );
};

export default Sidebar;
