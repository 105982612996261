import { Enzyme } from '../types';

type LinkType = 'info' | 'sample' | 'quote';
type LinkSetType = Partial<Record<LinkType, string>>;
type EnzymeLinksSetType = Record<Enzyme, LinkSetType>;

const RUSSIAN_LINKS: Partial<EnzymeLinksSetType> = {
  'Novamyl 3D BG': {
    info: '/productinfo/ru/Novamyl_3D_BG_application_sheet.pdf',
    sample: 'https://campaign.novozymes.com/softer-for-longer#try_product',
    quote: 'https://campaign.novozymes.com/softer-for-longer#request_price',
  },
  NM3D: {
    info: '/productinfo/ru/Novamyl_3D_BG_application_sheet.pdf',
    sample: 'https://campaign.novozymes.com/softer-for-longer#try_product',
    quote: 'https://campaign.novozymes.com/softer-for-longer#request_price',
  },
  'Novamyl 10000 BG': {
    info: '/productinfo/ru/Novamyl_10_000_BG_application_sheet.pdf',
    sample: 'mailto:NZRU@novozymes.com',
    quote: 'mailto:NZRU@novozymes.com',
  },
  'Novamyl Boost BG': {
    info: '/productinfo/ru/Novamyl_Boost_application_sheet.pdf',
    sample: 'mailto:NZRU@novozymes.com',
    quote: 'mailto:NZRU@novozymes.com',
  },
  'Novamyl Rye BG': {
    info: '/productinfo/ru/Novamyl_Rye_application_sheet.pdf',
    sample: 'mailto:NZRU@novozymes.com',
    quote: 'mailto:NZRU@novozymes.com',
  },
};

const CHINESE_LINKS: Partial<EnzymeLinksSetType> = {
  'Novamyl 3D BG': {
    info: '',
    sample: 'https://host.huiju.cool/p/61707',
    quote: 'https://detail.1688.com/offer/592246296939.html?spm=a26352.13672862.offerlist.7.4c6025426ttGEF',
  },
  NM3D: {
    info: '',
    sample: 'https://host.huiju.cool/p/61707',
    quote: 'https://detail.1688.com/offer/592246296939.html?spm=a26352.13672862.offerlist.7.4c6025426ttGEF',
  },
  'Novamyl 10000 BG': {
    info: '',
    sample: '',
    quote: '',
  },
  'Novamyl Boost BG': {
    info: '',
    sample: '',
    quote: '',
  },
  'Novamyl Rye BG': {
    info: '',
    sample: '',
    quote: '',
  },
};

const ENGLISH_LINKS: Partial<EnzymeLinksSetType> = {
  'Novamyl 3D BG': {
    info: 'https://biosolutions.novozymes.com/en/baking/products/freshness/novamyl-3D',
    sample: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds21w',
    quote: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds259',
  },
  NM3D: {
    info: 'https://biosolutions.novozymes.com/en/baking/products/freshness/novamyl-3D',
    sample: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds21w',
    quote: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds259',
  },
  'Novamyl 10000 BG': {
    info: 'https://biosolutions.novozymes.com/en/baking/products/freshness/novamyl',
    sample: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds25y',
    quote: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds25w',
  },
  'Novamyl Boost BG': {
    info: 'https://biosolutions.novozymes.com/en/baking/products/freshness/novamyl-boost',
    sample: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds25k',
    quote: 'https://nz.engage.novozymes.com/l/701243/2021-03-02/ds21y',
  },
  'Novamyl Rye BG': {
    info: 'https://biosolutions.novozymes.com/en/baking/products/freshness/novamyl-rye',
    sample: 'https://nz.engage.novozymes.com/l/701243/2021-03-03/ds6cp',
    quote: 'https://nz.engage.novozymes.com/l/701243/2021-03-03/ds6cr',
  },
};

const GetProductLinks = (language: string): Partial<EnzymeLinksSetType> => {
  switch (language) {
    case 'ru':
      return RUSSIAN_LINKS;

    case 'zh':
      return CHINESE_LINKS;

    default:
      return ENGLISH_LINKS;
  }
};

export default GetProductLinks;
