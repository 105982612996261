import { createGlobalStyle } from 'styled-components';
import { MediaQueries } from './media-queries-css';

import SelectLanguageArrow from '../images/vertical-arrow-purple.png';
import { colors } from '@novozymes-digital/components';

const ComponentStyles = createGlobalStyle`

    .padding {

        &.white {
            background: #fff;
        }

        &.grey {
            background: #f6f6f6;
        }

        &:not(.left) {
      		${MediaQueries.large} {
              padding-left:  ${({ theme }) => theme.padding.large} !important;
              padding-right: ${({ theme }) => theme.padding.large} !important;
      		}

      		${MediaQueries.medium} {
              padding-left:  ${({ theme }) => theme.padding.medium} !important;
              padding-right: ${({ theme }) => theme.padding.medium} !important;
      		}

    	    ${MediaQueries.small} {
              padding-left:  ${({ theme }) => theme.padding.small} !important;
              padding-right: ${({ theme }) => theme.padding.small} !important;
    	    }
      }

      &.left {
      		${MediaQueries.large} {
              padding-top: 0;
              padding-left:  ${({ theme }) => theme.padding.large} !important;
              padding-right:  ${({ theme }) => theme.padding.large} !important;
      		}

      		${MediaQueries.medium} {
              padding-right: ${({ theme }) => theme.padding.medium} !important;
              padding-left:  ${({ theme }) => theme.padding.medium} !important;
      		}

    	    ${MediaQueries.small} {
              padding-left:  ${({ theme }) => theme.padding.small} !important;
              padding-right:  ${({ theme }) => theme.padding.small} !important;
    	    }
      }

    }

    .negative-margin {
        &:not(.mobile) {
            ${MediaQueries.large} {
            	margin-left:  -${({ theme }) => theme.padding.large};
            	margin-right: -${({ theme }) => theme.padding.large};
            }
        }

        ${MediaQueries.medium} {
        	margin-left:  -${({ theme }) => theme.padding.medium};
        	margin-right: -${({ theme }) => theme.padding.medium};
        }

        ${MediaQueries.small} {
        	margin-left:  -${({ theme }) => theme.padding.small};
        	margin-right: -${({ theme }) => theme.padding.small};
        }

    }

    /* *** DEV *** */
    .amplify-signup-region-select{
      flex-basis: 100% !important;
      border: 1px solid #c4c4c4 !important;
    }

    /* Sidebar Area */

    .LoginWrapper {

      &.show {
        display: block;
        background: none;
      }

      &.hide {
        display: none;
      }

      

      // ${MediaQueries.medium} {
      //   position: fixed;
      //   top: 0;
      //   background-color: rgba(0, 0, 0, 0.5);
      //   min-width: 100%;
      //   min-height: 100%;
      //   z-index: 999;

      //   &.show {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //   }

      //   div[class*="Section__container"] {
      //     max-width: 673px;
      //     width: 100%;
      //     max-height: 100%;
      //     overflow-y: auto;
      //     -webkit-overflow-scrolling: touch;
      //     position: absolute;
      //     z-index: 1000;
      //     top: 50%;
      //     left: 50%;
      //     margin-right: -50%;
      //     transform: translate(-50%, -50%);
      //     padding: 60px 40px 40px;

      //     @media screen and (max-width: 420px) {
      //       padding: 60px 20px 40px;
      //     }
      //   }

      //   @media screen and (max-device-width: 1024px) {
      //     overflow-y: scroll;
      //   }

      //   div[class*="Form__formContainer"] {
      //     position:relative;
      //     z-index: 1001
      //   }
      // }

    }

    .LoginWrapper {
      position: relative;

      .TermsOfUse {
        font-style: italic;
        color: ${colors.black};
        text-align: center;
        background: #f6f6f5;
        padding: 0px 48px 48px;

        a {
          color: inherit;
        }
      }
    }

    /* STEP 4: Resultspage */

    .results-page {

        ${MediaQueries.medium} {
          .Content {
            background: #f6f6f5 !important;
          }
        }

        /* Header Area */

        .HeaderWrapper {

            /* Switch Between Units */

            .UnitToggle  {


            }
        }

    }

    .results-page {

        ${MediaQueries.medium} {
          .StandardRecipe .DropDownRecipeLabel {
            background: #e7e7e7;
            color: ${colors.black};
            font-weight: bold;

              > div {
                background: url(${SelectLanguageArrow}) no-repeat 100% 50%;
                background-size: 8px auto;
                border:0;
                width: 8px;
                height: 14px;
                transform: rotate(0deg);

                &.down {
                  transform: rotate(-180deg);
                }
              }
          }
        }

    }

    .results-page {

        /* Softness, Elasticity & Moistness */

        .FreshnessAttributeResult {

            ${MediaQueries.medium} {
                margin-bottom: 6px;
            }

        }

        .EnzymeCost {
            margin-left:0;
        }

    }

    /* Slider */

    ${MediaQueries.medium} {
      .StandardRecipe {
        .LabelWrapper {
          /*margin-bottom: 0px;*/
        }
      }
    }

`;

export default ComponentStyles;
