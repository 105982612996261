import LearnMoreGraph_1_EU from './images/p2_p3_graph_elasticity_eu.png';
import LearnMoreGraph_2_EU from './images/p1_graph_softness_eu.png';

import LearnMoreGraph_1_CH from './images/p2_p3_graph_elasticity_ch.png';
import LearnMoreGraph_2_CH from './images/p1_graph_softness_ch.png';

interface GraphicsInterface {
  graph_1: string;
  graph_2: string;
}

const LearnMoreGraphics = (lang: string) => {
  let Graphics: GraphicsInterface = {
    graph_1: lang === 'en' ? LearnMoreGraph_1_EU : LearnMoreGraph_1_CH,
    graph_2: lang === 'en' ? LearnMoreGraph_2_EU : LearnMoreGraph_2_CH,
  };

  return Graphics;
};

export default LearnMoreGraphics;
