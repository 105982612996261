import React, { useContext } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';
import i18n from '../../../i18NextConf';
import Button from '../../button';

const Wrapper = styled('div')<{
  topMargin: number;
  bottomMargin: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ topMargin }) => topMargin}px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin}px;
`;

interface Props {
  type?: string;
  topMargin?: number;
  bottomMargin?: number;
  closeModal: () => void;
}

const CloseModal = (props: Props) => {
  const { type = '', topMargin = 0, bottomMargin = 0, closeModal } = props;

  return (
    <Wrapper topMargin={topMargin} bottomMargin={bottomMargin}>
      <Button minWidth={200} primary={false} onClick={closeModal}>
        {i18n.t('Close')}
      </Button>
    </Wrapper>
  );
};

export default CloseModal;
