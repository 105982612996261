import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../i18NextConf';

import { styled } from '../../theme';
import Modal from '../../components/modal';
import Form from '../../components/form';
import Input from '../../components/input';
import Button from '../../components/button';
import Loader from '../../components/loader';
import FlowLayout from '../../components/flow-layout';
import StepHeader from '../../components/step-header';
import SideBar, { Label, Title } from '../../components/flow-layout/sidebar';
import ApplicationBox from './application-box';
import ComingSoon from './coming-soon';
import { MediaQueries } from '../../theme/media-queries-css';

import RegionConfig from '../../region-config';
import ApplicationConfig, { IApplicationConfig } from './application-config';

import { saveApplicationSuggestionAPI, fetchPreviousRecommendationsAPI } from '../../services/api';
import { colors } from '@novozymes-digital/components';

const StepWrapper = styled('div')``;

const MainContent = styled('div')``;

const ApplicationsWrapper = styled('div')`
  padding-bottom: 60px;

  ${MediaQueries.medium} {
    vertical-align: middle;
  }

  ${MediaQueries.small} {
    margin-bottom: 30px;
  }
`;

const ApplicationBoxesWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
  margin-top: 20px;
  margin-bottom: 40px;

  @media screen and (max-width: 1140px) and (min-width: 993px) {
    max-width: 660px;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
  }

  ${MediaQueries.medium} {
    max-width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 16px;
  }
`;

const HelpText = styled('div')`
  display: inline;
  font-family: Novozymes;
  font-size: 18px;
  line-height: 25px;
  color: ${colors.black};

  ${MediaQueries.medium} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const RequestLink = styled.span`
  cursor: pointer;
  font-family: Novozymes;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.black};

  @media screen and (max-width: 420px) {
    display: block;
  }

  ${MediaQueries.medium} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ButtonsWrapper = styled('div')`
  margin-top: 24px;
  margin-bottom: 24px;

  ${MediaQueries.medium} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  ${MediaQueries.small} {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;

    a {
      width: 100%;
      display: block;

      button {
        width: 100%;
      }
    }
  }
`;
const HelpTextWrapper = styled('div')`
  ${MediaQueries.medium} {
    display: flex;
    justify-content: center;
    flex-direction: column;

    > * {
      &:first-child {
        margin-bottom: 4px;
      }
      text-align: center;
    }
  }
`;

const SubHeader = styled('div')`
  font-size: 18px;
  line-height: 25px;

  ${MediaQueries.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const FeedBackInfo = styled.div`
  padding: 50px 40px;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 260px ul {
    li {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }
  }
`;

const SubHeading = styled.span`
  height: 14px;
  display: block;
`;

const reactReplaceString = require('react-string-replace');

interface State {
  openModal: boolean;
  openComingSoon: boolean;
  applicationName: string;
  invalidInput: boolean;
  isLoading: boolean;
  showFeedback: boolean;
}

export default class ApplicationPage extends React.PureComponent<{}, State> {
  state = {
    openModal: false,
    openComingSoon: false,
    applicationName: '',
    invalidInput: true,
    isLoading: true,
    showFeedback: false,
  };

  applicationOrder: string = '';

  async componentDidMount() {
    this.applicationOrder = await RegionConfig.getConfig('ApplicationOrder');

    await fetchPreviousRecommendationsAPI();

    this.setState({
      isLoading: false,
    });
  }

  validateInput = (name: any) => {
    if (name.length > 2) {
      this.setState({
        invalidInput: false,
      });
    }
    if (name.length <= 2) {
      this.setState({
        invalidInput: true,
      });
    }
  };

  onFormSubmit = async () => {
    const { applicationName } = this.state;

    await saveApplicationSuggestionAPI(applicationName);

    this.setState({
      showFeedback: true,
    });
    //this.handleCloseModal()
  };

  handleOpenModal = () => {
    document.body.style.overflow = 'hidden';
    this.setState({
      openModal: true,
      showFeedback: false,
    });
  };

  handleCloseModal = () => {
    document.body.style.overflow = 'auto';
    this.setState({
      openModal: false,
      applicationName: '',
      invalidInput: true,
    });
  };

  changeApplicationName = (applicationName: any): void => {
    this.setState({
      applicationName,
    });
    this.validateInput(applicationName);
  };

  onClickApplication = () => {
    this.setState({
      openComingSoon: true,
    });
  };

  closeComingSoon = () => {
    this.setState({
      openComingSoon: false,
    });
  };

  render() {
    const { openModal, openComingSoon, applicationName, invalidInput, isLoading, showFeedback } = this.state;

    const sidebar = (
      <>
        <Title className="application-header">{i18n.t('Welcome')}</Title>
        {
          <Label className="application-subtitle">
            {reactReplaceString(
              i18n.t(
                'Use SmartBake® to reach your target shelf life. Enter your most important recipe parameters for a dosage recommendation tailored to your fresh-keeping needs.'
              ),
              '®',
              (match, i) => (
                <sup key={i}>{match}</sup>
              )
            )}
          </Label>
        }
      </>
    );

    if (isLoading) return <Loader />;

    let applications = [];

    if (this.applicationOrder.length > 0) {
      const applist = this.applicationOrder.split(',');
      applications = applist.map((app) => ApplicationConfig().find((a) => a.key == app));
    }

    const infoFeedback = showFeedback ? (
      <FeedBackInfo>
        <ul className="feedback-info">
          <li>{i18n.t('The requested application will be considered for the future releases of the tool.')}</li>
        </ul>
      </FeedBackInfo>
    ) : null;

    return (
      <>
        <FlowLayout sidebar={sidebar} progress={2 / 4}>
          <StepWrapper className="StepWrapper">
            <MainContent className="MainContent">
              <StepHeader currentPage="/overviewPage" title={i18n.t('Applications')} />

              <SubHeader>{i18n.t('Select application')}</SubHeader>

              <ApplicationsWrapper className="ApplicationPage">
                <ApplicationBoxesWrapper className="ApplicationBoxesWrapper">
                  {applications.map((application, index) => (
                    <ApplicationBox
                      id="application-select-application"
                      key={application.key}
                      application={application}
                      onClick={this.onClickApplication}
                    >
                      <span>{application.title || ''}</span>

                      <SubHeading>{application.subtitle || ''}</SubHeading>

                      {typeof application.title === 'undefined' && application.name}
                    </ApplicationBox>
                  ))}
                </ApplicationBoxesWrapper>
                <HelpTextWrapper>
                  <HelpText>{i18n.t('Can’t find your application? ')}</HelpText>
                  <RequestLink
                    data-cy="application-request-application"
                    id="application-request-application"
                    onClick={this.handleOpenModal}
                  >
                    {i18n.t('Request application ')}
                  </RequestLink>
                </HelpTextWrapper>
              </ApplicationsWrapper>
            </MainContent>
            {/*<ButtonsWrapper>
              <Link to="/OverviewPage">
                <Button minWidth={190} marginRight={12} primary={false}>
                  {i18n.t('Back')}
                </Button>
              </Link>
              {
              <Link to="/RecipeParameters">
                <Button minWidth={190} marginRight={0} primary>
                  {i18n.t('Next')}
                </Button>
              </Link>}
            </ButtonsWrapper>*/}
          </StepWrapper>
        </FlowLayout>
        <Modal onClose={this.handleCloseModal} open={openModal}>
          {!showFeedback && (
            <Form
              id="additional-applications"
              title={i18n.t('Additional applications')}
              description={i18n.t('Please enter the name of an application you would like to request')}
              onFormSubmit={this.onFormSubmit}
              disabled={invalidInput || showFeedback === true}
              inputs={
                <div style={{ width: '100%' }}>
                  <Input
                    maxWidth={454}
                    minWidth={454}
                    label={`${i18n.t('Name of application')} *`}
                    onValue={this.changeApplicationName}
                    pattern=".*"
                    value={applicationName}
                    error={invalidInput}
                  />
                </div>
              }
            />
          )}
          {showFeedback && infoFeedback}
        </Modal>
        <Modal open={openComingSoon} onClose={this.closeComingSoon}>
          <ComingSoon
            title={i18n.t('Coming soon!')}
            description={i18n.t('This application is not available right now ')}
          />
        </Modal>
      </>
    );
  }
}
