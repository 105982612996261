import React, { useContext } from 'react';

import { styled, Theme } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import { ThemeContext } from '../../themecontext';

const Wrapper = styled.div<{ setWidth: string }>`
  width: ${({ setWidth }) => setWidth};
  padding-right: 20px;

  sup {
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    vertical-align: top;
  }
`;

type Props = {
  desktopWidth?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  children: React.ReactNode;
};

const EnzymeInfoContainer = (props: Props) => {
  const context = useContext(ThemeContext);
  const { desktop, tablet, mobile } = context.device;

  let { children, desktopWidth = '100%', tabletWidth = '', mobileWidth = '' } = props;

  tabletWidth = tabletWidth !== '' ? tabletWidth : desktopWidth;
  mobileWidth = tabletWidth !== '' ? mobileWidth : desktopWidth;

  return (
    <Wrapper className="enzyme-info-container" setWidth={desktop ? desktopWidth : tablet ? tabletWidth : mobileWidth}>
      {children}
    </Wrapper>
  );
};

export default EnzymeInfoContainer;
