import React, { useContext } from 'react';
import { Theme } from '../../theme';
import { ThemeContext } from '../../themecontext';
import ResultsPageCH from './index_ch';
import ResultsPageEU from './index_eu';

interface Props {
  children: React.ReactNode;
  theme?: Theme;
}

const ResultsPage = (props: Props) => {
  const context = useContext(ThemeContext);

  const application = localStorage.getItem('recipe-param-application');
  const sugar = Number(localStorage.getItem('recipe-param-sugar')) || 0;
  const fat = Number(localStorage.getItem('recipe-param-fat')) || 0;
  const egg = Number(localStorage.getItem('recipe-param-egg')) || 0;
  const ryeFlour = Number(localStorage.getItem('recipe-param-ryeFlour')) || 0;
  const targetShelfLife = Number(localStorage.getItem('recipe-param-targetShelfLife')) || 0;

  const inputs = {
    application,
    sugar,
    fat,
    egg,
    ryeFlour,
    targetShelfLife,
  };

  if (!context.region) {
    return null;
  }

  let page: any;

  switch (context.region) {
    case 're_CH':
      page = <ResultsPageCH {...props} {...inputs}></ResultsPageCH>;
      break;
    default:
      page = <ResultsPageEU {...props} {...inputs}></ResultsPageEU>;
  }

  return page;
};

export default ResultsPage;
