import React from 'react';
import i18n from '../i18NextConf';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

const BurgerMenuWrapper = styled.div`
  position: absolute;
  top: 30px;
  width: 50px;
  height: 50px;
  z-index: 1;
  background: #e7e7e7;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${MediaQueries.large} {
    right: 64px;
    top: 40px;
  }

  @media screen and (max-width: 1280px) and (min-width: 993px) {
    right: 40px;
  }

  ${MediaQueries.medium} {
    top: 28px;
    right: 40px;
  }
`;

const BurgerMenu = styled.div`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 2px;
  background: ${({ theme }) => theme.color.purple};
  border-radius: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    background: ${({ theme }) => theme.color.purple};
  }

  &:after {
    bottom: -6px;
  }

  &:before {
    top: -6px;
  }
`;

const BurgerNavigation = (props) => {
  const openNavigation = (e) => {
    const { open } = props;
    open();
  };

  return (
    <BurgerMenuWrapper id={props.id} onClick={openNavigation}>
      <BurgerMenu />
    </BurgerMenuWrapper>
  );
};

export default BurgerNavigation;
