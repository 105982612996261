import React from 'react';
import { styled, Theme } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

const Wrapper = styled.div<{
  isLandingPage: boolean;
}>`
  ${({ isLandingPage }) =>
    !isLandingPage &&
    `
	`}

  ${({ isLandingPage }) =>
    isLandingPage &&
    `
		${MediaQueries.medium} {
		}
	`}
`;

const VideoWrapper = styled('div')``;

const Video = styled('div')`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const isLandingPage = window.location.pathname === '/';

const VideoCH = (props: any) => {
  return (
    <Wrapper className={`${!isLandingPage ? 'negative-margin padding left white' : ''}`} isLandingPage={isLandingPage}>
      <div className="medium">
        <VideoWrapper>
          <Video>
            <iframe src={props.url} frameBorder="0" allowFullScreen></iframe>
          </Video>
        </VideoWrapper>
      </div>
    </Wrapper>
  );
};

export default VideoCH;
