import React, { useContext } from 'react';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

import BackButton from './back-button';
import { ThemeContext } from '../themecontext';

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 0;

  @media screen and (min-width: 1141px) {
    &.results-page {
      flex-basis: 520px;
      max-width: 520px;
    }
  }

  @media screen and (max-width: 1140px) {
    flex-basis: auto;
  }

  ${MediaQueries.medium} {
  }

  ${MediaQueries.small} {
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  position: relative;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
  color: inherit;

  ${MediaQueries.small} {
    margin-bottom: 5px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: inherit;

  ${MediaQueries.small} {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface Props {
  title: string;
  description?: string;
}

const SubHeader = (props: Props) => {
  const context = useContext(ThemeContext);
  const { title, description = '' } = props;
  const { tablet, desktop } = context.device;

  return (
    <Wrapper>
      <Title>{title}</Title>

      {description !== '' && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default SubHeader;
