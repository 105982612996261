import React, { useContext } from 'react';

import { styled } from '../../../theme';
import { MediaQueries } from '../../../theme/media-queries-css';
import i18n from '../../../i18NextConf';
import InfoIcon from './info-icon';

import GoBack from './go-back';

const Wrapper = styled('div')`
  position: relative;
  padding: 30px 0;
  margin: 0 0 30px;
  border-bottom: solid 1px #dbdddb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H3 = styled('h3')`
  display: flex;
  font-weight: bold;
  color: inherit;
  justify-content: center;

  ${MediaQueries.large} {
    font-size: 28px;
    line-height: 36px;
  }

  ${MediaQueries.small} {
    font-size: 20px;
    line-height: 28px;
  }
`;

interface Props {
  title?: string;
  goBack?: () => void;
  learnMorePage?: boolean;
}

const Header = (props: Props) => {
  const { title = '', learnMorePage = true, goBack } = props;

  return (
    <Wrapper>
      {learnMorePage && <GoBack goBack={goBack} />}
      <H3>{title === '' ? i18n.t('Learn More') : title}</H3>
    </Wrapper>
  );
};

export default Header;
