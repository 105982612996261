import React, { useContext } from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import i18n from '../../i18NextConf';
import Button from '../button';

const Wrapper = styled('div')`
  position: relative;
  padding: 20px 0 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H3 = styled('h3')`
  display: flex;
  font-weight: bold;
  color: inherit;
  justify-content: center;

  ${MediaQueries.large} {
    font-size: 28px;
    line-height: 36px;
  }

  ${MediaQueries.small} {
    font-size: 20px;
    line-height: 28px;
  }
`;

interface Props {
  title: string;
  goBack: () => void;
}

const NaviBackBtn = (props: Props) => {
  const { title } = props;

  const showPreviousComponent = () => {
    let { goBack } = props;
    goBack();
  };

  return (
    <Wrapper>
      <Button minWidth={200} primary={false} onClick={showPreviousComponent}>
        {title}
      </Button>
    </Wrapper>
  );
};

export default NaviBackBtn;
