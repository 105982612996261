import { colors } from '@novozymes-digital/components';
import React from 'react';

import { styled } from '../../theme';
import Button from '../button';

const Container = styled.div`
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  color: ${colors.black};
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 600px) {
    color: ${colors.black};
  }
`;
const Description = styled.div`
  color: ${colors.black};
  font-size: 18px;
  line-height: calc(25 / 18);
  margin: 16px 0px 32px 0px;
  max-width: 366px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: calc(23 / 16);
    color: inherit;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface Props {
  title?: string;
  description?: string;
  inputs: React.ReactNode;
  onFormSubmit: (value: any) => void;
  disabled: boolean;
  isSave?: boolean;
  className?: string;
  submitText?: string;
}

const Form = (props: Props) => {
  const { title = '', description = '', submitText = '', inputs, onFormSubmit, disabled } = props;

  return (
    <Container className="Form">
      {title !== '' && <Title>{title}</Title>}
      {description !== '' && <Description>{description}</Description>}
      {props.inputs}
      <ButtonWrapper>
        <Button primary onClick={onFormSubmit} disabled={disabled} minWidth={200}>
          {submitText}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default Form;
