import { Amplify, I18n } from '@aws-amplify/core';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import awsconfig from './aws-config';
import ErrorBoundery from './errorBoundery';
import ApplicationPage from './pages/application';
import LandingPage from './pages/landing';
import SavedRecommendations from './pages/overview/';
import RecipeParametersPage from './pages/recipe-parameters';
import ResultsPage from './pages/results';
import TermsOfUsePage from './pages/terms-of-use';
import { initializeCookieConsent } from '@novozymes-digital/utils';
import { CssBaseline } from '@material-ui/core';
import i18n from './i18NextConf';

Amplify.configure(awsconfig);

declare const window: Window &
  typeof globalThis & {
    _DATADOG_SYNTHETICS_BROWSER: unknown;
  };

export const isLocalhost = (): boolean => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

const App: React.FC = () => {
  const [language, setLanguage] = useState(i18n.languages[0]);

  const handleLanguageChange = (lng: string) => {
    //console.log('App lang:' + lng)
    I18n.setLanguage(lng);
    setLanguage(lng);
  };

  //I18n.setLanguage(i18n.languages[0]);
  i18n.on('languageChanged', (lng) => handleLanguageChange(lng));
  I18n.setLanguage(language);

  console.log('lang: ', language);

  TagManager.dataLayer({
    dataLayer: {
      language: language,
    },
    dataLayerName: 'dataLayer',
  });

  useEffect(() => {
    // check that is not a dev env and the user is not a datadog bot and only then add analytics tracking
    if (!isLocalhost() && window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      initializeCookieConsent();
      // initializeGTM('GTM-KTWCZXC');
    }
  }, []);

  return (
    <Router>
      <CssBaseline />
      <ErrorBoundery>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/TermsOfUsePage" element={<TermsOfUsePage />} />
          <Route path="/ApplicationPage" element={<ApplicationPage />} />
          <Route path="/RecipeParameters" element={<RecipeParametersPage>{}</RecipeParametersPage>} />
          <Route path="/ResultsPage" element={<ResultsPage>{}</ResultsPage>} />
          <Route path="/SavedRecommendations" element={<SavedRecommendations>{}</SavedRecommendations>} />
        </Routes>
      </ErrorBoundery>
    </Router>
  );
};

export default App;
