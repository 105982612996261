import React from 'react';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';
import i18n from '../../i18NextConf';
import { ThemeContext } from '../../themecontext';

const TabsWrapper = styled.div`
  background: ${({ theme }) => theme.color.lightgrey};
  border-bottom: solid 2px ${({ theme }) => theme.color.green};

  ${MediaQueries.large} {
    padding-left: ${({ theme }) => theme.padding.large};
  }

  ${MediaQueries.medium} {
    padding-left: ${({ theme }) => theme.padding.medium};
  }

  ${MediaQueries.small} {
    padding-left: ${({ theme }) => theme.padding.small};
  }

  ul {
    list-style: none;
    display: flex;
    max-width: 320px;

    li {
      position: relative;
      font-family: inherit;
      font-weight: bold;
      cursor: pointer;
      color: inherit;
      border-radius: 10px 10px 0 0;
      margin: 0 8px;
      white-space: nowrap;
      background: #fff;

      box-shadow:
        /* left "inner border" */ inset 2px 0 0 0 #e6e6e6,
        /* right "inner border" */ inset -2px 0 0 0 #e6e6e6, /* top "inner border" */ inset 0 2px 0 0 #e6e6e6,
        /* bottom "inner border" */ inset 0 -2px 0 0 transparent;

      ${MediaQueries.large} {
        font-size: 18px;
        line-height: 1;
        padding: 17px 40px;
      }

      ${MediaQueries.medium} {
        font-size: 16px;
        line-height: 1;
        padding: 17px 22px;
      }

      &:not(.space) {
        text-align: center;
      }

      &.active {
        position: relative;
        margin-bottom: -2px;
        box-shadow:
          /* left "inner border" */ inset 2px 0 0 0 ${({ theme }) => theme.color.green},
          /* right "inner border" */ inset -2px 0 0 0 ${({ theme }) => theme.color.green},
          /* top "inner border" */ inset 0 2px 0 0 ${({ theme }) => theme.color.green},
          /* bottom "inner border" */ inset 0 -2px 0 0 transparent;

        ${MediaQueries.large} {
        }
      }
    }
  }
`;

type State = {};

type Props = {
  setCurrentActiveTab: (string) => void;
  currentActiveTab?: string;
};

export default class ResultsTabs extends React.PureComponent<Props, State> {
  static contextType = ThemeContext;

  setThisTabActive = (tab: string) => () => {
    this.props.setCurrentActiveTab(tab);
  };

  render() {
    const { currentActiveTab } = this.props;

    return (
      <TabsWrapper className="negative-margin">
        <ul>
          <li
            data-cy="tab-moistness"
            id="tab-moistness"
            onClick={this.setThisTabActive('moistness')}
            className={`moistness${currentActiveTab === 'moistness' ? ' active' : ''}`}
          >
            {i18n.t('Moistness')}
          </li>
          <li
            data-cy="tab-softness"
            id="tab-softness"
            onClick={this.setThisTabActive('softness')}
            className={`softness${currentActiveTab === 'softness' ? ' active' : ''}`}
          >
            {i18n.t('Softness')}
          </li>
          <li
            data-cy="tab-elasticity"
            id="tab-elasticity"
            onClick={this.setThisTabActive('elasticity')}
            className={`elasticity${currentActiveTab === 'elasticity' ? ' active' : ''}`}
          >
            {i18n.t('Elasticity')}
          </li>
        </ul>
      </TabsWrapper>
    );
  }
}
