import React from 'react';
import { styled } from '../../theme';
import i18n from '../../i18NextConf';
import { Enzyme } from '../../types';
import GetProductLinks from '../../static-data/ProductLinks';
import { Chip, useMediaQuery } from '@material-ui/core';
import { colors } from '@novozymes-digital/components';
import { MediaQueries } from '../../theme/media-queries-css';

const Wrapper = styled.div`
  display: flex;
  padding-top: 8px;

  ${MediaQueries.small} {
    flex-direction: column;
    & > div {
      margin-bottom: 8px;
    }
  }
`;

type Props = {
  name: Enzyme;
  language: string;
};

const EnzymeActions = ({ name, language }: Props) => {
  const productLinks = GetProductLinks(language)[name];

  const openURL = (url: string) => () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  if (!productLinks) {
    return null;
  }

  const nameAsId = name.toLowerCase().replace(/\s/g, '-');
  const isMobile = useMediaQuery(MediaQueries.small);

  return (
    <Wrapper>
      {productLinks.quote && (
        <Chip
          id={`${nameAsId}-request-quote`}
          size={isMobile ? 'small' : undefined}
          label={i18n.t('Request quote')}
          onClick={openURL(productLinks.quote)}
          style={{
            marginRight: '8px',
            backgroundColor: colors.primaryGreen,
            color: colors.black80,
          }}
        />
      )}
      {productLinks.sample && (
        <Chip
          id={`${nameAsId}-order-sample`}
          size={isMobile ? 'small' : undefined}
          label={i18n.t('Order sample')}
          onClick={openURL(productLinks.sample)}
          style={{
            marginRight: '8px',
            backgroundColor: colors.primaryGreen,
            color: colors.black80,
          }}
        />
      )}
    </Wrapper>
  );
};

export default EnzymeActions;
