import React from 'react';
import { styled } from '../theme';
import { Link } from 'react-router-dom';

import { Hub, I18n, Auth } from 'aws-amplify';
import { MediaQueries } from '../theme/media-queries-css';
import { colors } from '@novozymes-digital/components';

const LogoWrapper = styled.div<{ size?: number }>`
  font-family: Novozymes;
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
  color: ${colors.black80};
  margin-bottom: 4px;
  padding-top: 0;

  sup {
    font-size: 16px;
    line-height: 1;
    vertical-align: top;
    margin-left: 1px;
  }

  ${MediaQueries.large} {
    font-size: 22px;
    line-height: 1;
  }

  ${MediaQueries.medium} {
    margin-bottom: 0;
  }

  ${MediaQueries.small} {
    font-size: 24px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default class Logo extends React.PureComponent<{ size: number }> {
  componentDidMount() {
    /*
    Auth.currentAuthenticatedUser().then(user => console.log(user))
    })*/
  }

  render() {
    return (
      <LogoWrapper size={this.props.size}>
        SmartBake<sup>&reg;</sup>
      </LogoWrapper>
    );
  }
}
