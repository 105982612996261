import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core';

// @ts-ignore
import TagManager from 'react-gtm-module';

import { theme } from './theme';
import './theme/fonts.css';
import './theme/font-awesome/css/all.css';
import GlobalStyles from './theme/global-css';
import ComponentStyles from './theme/components-css';
import App from './app';

import GlobalWrapper from './components/global-wrapper';
import smartbakeTheme from './theme/SmartbakeTheme';
import DownForMaintenance from './DownForMaintenance';

const tagManagerArgs = {
  gtmId: 'GTM-KTWCZXC',
  dataLayerName: 'dataLayer',
};

TagManager.initialize(tagManagerArgs);

const isDownForMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MUIThemeProvider theme={smartbakeTheme}>
      <>
        <GlobalWrapper>{isDownForMaintenance ? <DownForMaintenance /> : <App />}</GlobalWrapper>
        <GlobalStyles />
        <ComponentStyles />
      </>
    </MUIThemeProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
