import React, { useContext, useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { styled } from '../../theme';

import { ThemeContext } from '../../themecontext';

import Modal from '../modal';
import LanguageSwitcher from '../language-switcher';

import i18n from '../../i18NextConf';
import { Auth, I18n } from 'aws-amplify';
import { MediaQueries } from '../../theme/media-queries-css';

import BioBackground from '../../images/nz-bg.svg';
import NaviArrow from './navi_arrow.svg';

import LearnMore from '../learn-more';
import Header from '../learn-more/parts/header';

import FactorsEffectingFreshkeeping from '../learn-more/factors-effecting-freshkeeping';
import ElasticityOfTheCrumb from '../learn-more/how-is-elasticity-of-the-crumb-measured';
import SoftnessOfTheCrumb from '../learn-more/how-is-the-softness-of-the-crumb-measured';
import FormularOtherIssues from '../forms/other_issues';

import Recommendations from '../recommendations/';

import NaviBackBtn from './navi-back-btn';
import NaviHeader from './navi-header';
import Button from '../button';
import PardotDialog from '../dialogs/PardotDialog';
import { colors } from '@novozymes-digital/components';

const Wrapper = styled('div')`
  background: #fff;

  ${MediaQueries.large} {
    padding: 60px 100px 90px;
    background-size: 16% auto;
  }

  ${MediaQueries.medium} {
    padding: 40px 40px 65px;
  }

  ${MediaQueries.small} {
    padding: 40px 40px 30px;
  }
`;

const NaviWrapper = styled('div')`
  position: relative;
  background-color: #f6f6f6;
  background-image: url(${BioBackground});
  background-position: calc(100% + 35px) 60px;
  background-repeat: repeat-y;
  background-size: 120px auto;
  overflow: hidden;

  ${MediaQueries.large} {
    min-height: 700px;
  }

  ${MediaQueries.medium} {
    min-height: 700px;
    background-size: 120px auto;
  }

  ${MediaQueries.small} {
    background-position: calc(100% + 26px) 0px;
    background-size: 60px auto;
    min-height: 510px;
  }
`;
const NaviInnerWrapper = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1002;
  display: none;
  pointer-events: none;

  @media screen and (min-width: 993px) {
    padding: 60px 100px 60px;
    background-size: 16% auto;
  }

  ${MediaQueries.medium} {
    padding: 40px 40px 65px;
  }

  ${MediaQueries.small} {
    padding: 40px 40px 40px;
  }

  &.show {
    display: block;
    pointer-events: auto;
  }

  > div {
    padding-bottom: 0px;
  }
`;

const Parent = styled('div')`
  overflow: hidden;
  width: 100%;
  z-index: 0;
  display: none;
  pointer-events: none;
  z-index: 1002;

  ${MediaQueries.large} {
    padding: 60px 100px 60px;
    background-size: 16% auto;
  }

  ${MediaQueries.medium} {
    padding: 40px 40px 65px;
  }

  &.show {
    display: block;
    pointer-events: auto;
  }
`;

const NaviLabel = styled('div')`
  font-size: 24px;
  line-height: 1;
  color: ${colors.black};
  font-weight: bold;
  margin-bottom: 15px;

  @media screen and (min-width: 993px) {
    font-size: 30px;
    text-align: center;
    margin-bottom: 36px;
  }
`;

const Ul = styled.ul`
  list-style: none;
`;

const Li = styled.li<{
  inactive?: boolean;
}>`
  position: relative;
  color: ${colors.black};
  border-bottom: 1px solid #dbdddb;
  font-weight: bold;

  &:not(.log-out-btn) {
    ${MediaQueries.small} {
      padding-right: 20px;
    }

    &:before {
      position: absolute;
      right: 6px;
      top: 50%;
      background: url(${NaviArrow}) no-repeat right 50%;
      background-size: 8px auto;
      transform: translateY(-50%);
      width: 8px;
      height: calc((91.1 / 55.08) * 8px);
      content: '';
      z-index: 1;
      opacity: ${({ inactive }) => (inactive ? 0.2 : 1)};
      pointer-events: none;

      ${MediaQueries.small} {
        background-size: 6px auto;
        width: 6px;
        height: calc((91.1 / 55.08) * 6px);
      }
    }
  }

  ${MediaQueries.large} {
    font-size: 18px;
  }

  &.log-out-btn {
    display: flex;
    justify-content: center;
    border-bottom: 0;
    margin-top: 52px;

    ${MediaQueries.small} {
      margin-top: 20px;
    }

    button {
      font-weight: bold;
    }
  }

  &.language {
    ${MediaQueries.small} {
      height: 54px;
      padding: 0;
    }

    &:before {
      transform: translateY(-50%) rotate(90deg);
    }

    select {
      font-weight: bold;

      ${MediaQueries.large} {
        padding: 26px 0;
      }

      ${MediaQueries.medium} {
        padding: 18px 0;
      }
    }
  }

  &:first-child {
    border-top: 1px solid #dbdddb;
  }

  a {
    color: inherit;
    text-decoration: none !important;
    display: block;
    width: 100%;
    height: 100%;
    padding: 18px 0;
  }
`;

const NewContent = styled('div')`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

interface Props {
  show: boolean;
  closemodal: () => void;
}

let history: string[] = ['Navi'];
let mainNaviContainer: HTMLElement | null = null;

const Navi = (props: Props) => {
  const { show } = props;
  const [showRecommendations, setShowRecommendation] = useState(false);
  const [ResetFormularOtherIssues, setResetFormularOtherIssues] = useState(false);
  const [ShowHideBackBtn, setShowHideBackBtn] = useState(true);
  const [CurrentComponent, setCurrentComponent] = useState('');
  const context = useContext(ThemeContext);
  const { tablet, desktop, mobile } = context.device;

  const [showPardotForm, setShowPardotForm] = useState(false);

  const showPardot = () => setShowPardotForm(true);
  const closePardot = () => setShowPardotForm(false);

  const handleLogout = async () => {
    Auth.signOut({})
      .then((data) => window.location.replace('/'))
      .catch((err) => console.log(err));
  };

  const resetComponents = () => {
    mainNaviContainer !== null ? (mainNaviContainer.style.minHeight = '') : null;
    let reset = document.querySelectorAll('.main-navi .component') as any;
    for (let i = 0; i <= reset.length - 1; i += 1) {
      reset[i].classList.remove('show');
    }
  };

  const handleSelectedComponent = useCallback(
    (component: string, setHeight: boolean = true, show: boolean = true): void => {
      let selectedComponent: any = document.querySelector(component);
      show ? (selectedComponent.className += ' show') : selectedComponent.classList.remove('show');
      setHeight ? (mainNaviContainer.style.minHeight = getComponentHeight(selectedComponent.children[0]) + 'px') : '';
      setCurrentComponent(component);
    },
    []
  );

  const showComponent = useCallback(
    (component: string) => {
      resetComponents();

      if (/(FactorsEffectingFreshkeeping|ElasticityOfTheCrumb|SoftnessOfTheCrumb)/g.test(component)) {
        history[history.length - 1] !== 'LearnMore' ? history.splice(history.length - 1, 1) : '';
      }

      history.indexOf(component) === -1 ? history.push(component) : '';

      switch (component) {
        case 'Navi':
          handleSelectedComponent('.component.navi');
          setShowRecommendation(true);
          break;

        case 'Recommendations':
          handleSelectedComponent('.component.saved-recommendations');
          break;

        case 'LearnMore':
          handleSelectedComponent('.component.learn-more');
          break;

        case 'FactorsEffectingFreshkeeping':
          handleSelectedComponent('.component.factors-effecting-freshkeeping');
          break;

        case 'ElasticityOfTheCrumb':
          handleSelectedComponent('.component.elasticity-of-the-crumb');
          break;

        case 'SoftnessOfTheCrumb':
          handleSelectedComponent('.component.softness-of-the-crumb');
          break;

        case 'FormularOtherIssues':
          handleSelectedComponent('.component.formular-other-issues');
          break;
      }
    },
    [handleSelectedComponent]
  );

  useEffect(() => {
    mainNaviContainer = mainNaviContainer === null ? document.querySelector('.main-navi') : mainNaviContainer;
    if (show) {
      localStorage.removeItem('scrollPos');
      // @ts-ignore
      localStorage.setItem('scrollPos', window.pageYOffset);
      showComponent('Navi');
    }
  }, [show, showComponent]);

  const closeMenu = () => {
    const { closemodal } = props;
    resetComponents();
    closemodal();
  };

  const showHideBackBtn = (showHide: boolean) => {
    setShowHideBackBtn(showHide);
  };

  const removeNoScroll = () => {
    document.querySelector('html').classList.remove('noscroll');
  };

  const getComponentHeight = (el: HTMLElement): number => {
    let container: HTMLElement = document.querySelector('.component');
    let paddingTop: any = window.getComputedStyle(container, null).getPropertyValue('padding-top');
    let paddingBottom: any = window.getComputedStyle(container, null).getPropertyValue('padding-bottom');
    let calcPadding: number = parseInt(paddingTop) + parseInt(paddingBottom);

    return el.scrollHeight + calcPadding;
  };

  const resetHistory = () => {
    history = ['Navi'];
  };

  const goBack = () => {
    resetComponents();

    let hideComponent: string = history.slice(-1).toString();

    history.pop();
    let showComponent: string = history.slice(-1).toString();

    switch (hideComponent) {
      case 'Recommendations':
        handleSelectedComponent('.component.saved-recommendations', false, false);
        break;

      case 'LearnMore':
        handleSelectedComponent('.component.learn-more', false, false);
        break;

      case 'FactorsEffectingFreshkeeping':
        handleSelectedComponent('.component.factors-effecting-freshkeeping', false, false);
        break;

      case 'ElasticityOfTheCrumb':
        handleSelectedComponent('.component.elasticity-of-the-crumb', false, false);
        break;

      case 'SoftnessOfTheCrumb':
        handleSelectedComponent('.component.softness-of-the-crumb', false, false);
        break;

      case 'FormularOtherIssues':
        handleSelectedComponent('.component.formular-other-issues', false, false);
        break;
    }

    switch (showComponent) {
      case 'Navi':
        handleSelectedComponent('.component.navi');
        break;

      case 'Recommendations':
        handleSelectedComponent('.component.saved-recommendations');
        break;

      case 'LearnMore':
        handleSelectedComponent('.component.learn-more');
        break;

      /*
      case 'FactorsEffectingFreshkeeping' :
      case 'ElasticityOfTheCrumb' :
      case 'SoftnessOfTheCrumb' :
        console.log('test@')
        handleSelectedComponent('.component.learn-more')
      break*/

      case 'FormularOtherIssues':
        handleSelectedComponent('.component.formular-other-issues');
        break;
    }
  };

  const languageSwitcher = <LanguageSwitcher name="modal-navi" />;

  let isSavedRecommendationsPage = window.location.pathname.toLowerCase() === '/savedrecommendations';
  let isApplicationPage = window.location.pathname.toLowerCase() === '/applicationpage';

  const recommendations = isSavedRecommendationsPage
    ? { color: '#bcbcbc', cursor: 'default' }
    : { color: 'inherit', cursor: 'pointer' };

  return (
    <Modal onClose={closeMenu} open={show} name="navigation" verticalPos="top" currentComponent={CurrentComponent}>
      <PardotDialog
        show={showPardotForm}
        onClose={closePardot}
        dialogTitle={i18n.t('Contact us title')}
        dialogIntro={i18n.t('Please describe your issues and we will get back to you')}
        subject="Issues"
      />
      <NaviWrapper className="main-navi">
        <NaviInnerWrapper className="component navi show">
          <div>
            <NaviLabel>{i18n.t('Menu')}</NaviLabel>
            <Ul>
              <Li id="menu-new-recommendation" onClick={removeNoScroll}>
                <Link
                  data-cy="menu-new-recommendation"
                  to={isApplicationPage ? '' : '/ApplicationPage'}
                  style={{ color: 'inherit', cursor: 'pointer' }}
                  onClick={isApplicationPage ? closeMenu : undefined}
                >
                  {i18n.t('New recommendation')}
                </Link>
              </Li>
              <Li id="menu-saved-recommendations" inactive={isSavedRecommendationsPage}>
                <a
                  data-cy="menu-saved-recommendations"
                  href="#"
                  onClick={() => {
                    showComponent('Recommendations');
                  }}
                >
                  {i18n.t('Saved recommendations')}
                </a>
              </Li>
              <Li id="menu-learn-more">
                <a
                  href="#"
                  onClick={() => {
                    showComponent('LearnMore');
                  }}
                >
                  {i18n.t('Learn more')}
                </a>
              </Li>
              <Li id="menu-language" className="language">
                {languageSwitcher}
              </Li>
              <Li id="menu-other-issues-bake">
                <a
                  href="#"
                  onClick={() => {
                    showPardot();
                  }}
                >
                  {i18n.t('Other issues with your bake?')}
                </a>
              </Li>
              <Li id="menu-terms-conditions">
                <Link data-cy="menu-terms-conditions" to="/TermsOfUsePage" onClick={removeNoScroll}>
                  {i18n.t('Terms of Use')}
                </Link>
              </Li>
              <Li id="menu-logout" className="log-out-btn">
                <Button minWidth={206} primary={false} onClick={handleLogout}>
                  {i18n.t('Logout')}
                </Button>
              </Li>
            </Ul>
          </div>
        </NaviInnerWrapper>

        <Parent className="component saved-recommendations">
          <div>
            <NaviHeader title={i18n.t('Saved recommendations')} goBack={goBack} />
            <Recommendations
              updateRecommendations={showRecommendations}
              closeMenu={closeMenu}
              resetHistory={resetHistory}
            />
            <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
          </div>
        </Parent>

        <Parent className="component learn-more">
          <div>
            <NaviHeader title={i18n.t('Learn more')} marginBottom={0} goBack={goBack} />
            <LearnMore showBtn={false} showHeader={false} showComponent={showComponent} />
            <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
          </div>
        </Parent>

        <Parent className="component factors-effecting-freshkeeping">
          <div>
            <NaviHeader title={i18n.t('Learn more')} goBack={goBack} />
            <FactorsEffectingFreshkeeping shownFromNavi={true} showComponent={showComponent} />
            <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
          </div>
        </Parent>

        <Parent className="component softness-of-the-crumb">
          <div>
            <NaviHeader title={i18n.t('Learn more')} goBack={goBack} />
            <SoftnessOfTheCrumb shownFromNavi={true} showComponent={showComponent} />
            <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
          </div>
        </Parent>

        <Parent className="component elasticity-of-the-crumb">
          <div>
            <NaviHeader title={i18n.t('Learn more')} goBack={goBack} />
            <ElasticityOfTheCrumb shownFromNavi={true} showComponent={showComponent} />
            <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
          </div>
        </Parent>

        {/* <Parent className="component formular-other-issues">
          <div>
            <NaviHeader
              title={i18n.t('Other issues with your bake?')}
              goBack={goBack}
            />
            <FormularOtherIssues
              resetFormular={show}
              showComponent={showComponent}
              showHideBackBtn={showHideBackBtn}
              closeMenu={closeMenu}
            />
            {ShowHideBackBtn && (
              <NaviBackBtn goBack={goBack} title={i18n.t('Back')} />
            )}
          </div>
        </Parent> */}
      </NaviWrapper>
    </Modal>
  );
};

export default Navi;
