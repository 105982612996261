import React from 'react';
import { Navigate } from 'react-router-dom';

import { styled } from '../../theme';
import { MediaQueries } from '../../theme/media-queries-css';

import i18n from '../../i18NextConf';

import ApplicationConfig, { IApplicationConfig } from './application-config';
import { colors } from '@novozymes-digital/components';

const ApplicationBoxWrapper = styled.button<{
  url: string;
  enabled: boolean;
}>`
  position: relative;
  min-height: 160px;
  padding: 16px 20px;
  margin: 0;
  width: 50%;
  max-width: 332px;
  cursor: pointer;
  outline: none;
  appearance: none;
  background: #fff;
  transition: background 0.25s ease;
  border: 0;
  border-bottom: solid 4px #f6f6f6;

  @media (min-width: 375px) {
    &:nth-child(odd) {
      border-right: solid 2px #f6f6f6 !important;
    }
    &:nth-child(even) {
      border-left: solid 2px #f6f6f6 !important;
    }
  }

  ${MediaQueries.medium} {
    border: 0;
    border-bottom: solid 4px #f6f6f5 !important;
  }

  ${MediaQueries.small} {
    min-height: 100px;
  }

  @media screen and (max-width: 374px) {
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1140px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.enabled {
    opacity: 1;
    color: ${({ theme }) => theme.color.text.white};
  }

  &.inactive {
    color: ${colors.black};
    cursor: default;

    div:first-of-type {
      opacity: 0.2;
    }

    span,
    div:last-of-type {
      opacity: 0.3;
    }
  }
`;

const ApplicationText = styled.div`
  display: inline;
  font-family: 'Novozymes';
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${colors.black};

  span:nth-child(1) {
    display: block;
    font-weight: bold;
  }

  span:nth-child(2) {
    font-size: 12px;
  }

  ${MediaQueries.small} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const SVGIconWrapper = styled.div`
  width: 56px;
  height: 56px;
  margin: 0 auto 12px;

  ${MediaQueries.small} {
    width: 34px;
    height: auto;
    margin-bottom: 4px;
  }
`;
const SVGIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export default class ApplicationBox extends React.PureComponent<
  {
    id: string;
    application: IApplicationConfig;
    children: React.ReactNode;
    onClick: () => void;
  },
  {
    navigate: boolean;
  }
> {
  state = {
    navigate: false,
  };

  navigateToRecipeParameters(application: IApplicationConfig) {
    localStorage.setItem('recipe-param-application', application.key);
    this.setState({ navigate: true });
  }

  render() {
    const { navigate } = this.state;

    if (navigate) return <Navigate to="/RecipeParameters" replace></Navigate>;

    const { application, children, onClick, id } = this.props;

    if (!application) return null;

    return (
      <ApplicationBoxWrapper
        id={id}
        className={`ApplicationBoxWrapper ${application.enabled ? 'enabled' : 'inactive'}`}
        enabled={application.enabled}
        url={application.icon}
        onClick={application.enabled ? () => this.navigateToRecipeParameters(application) : onClick}
        onTouchStart={(e) => {
          e.currentTarget.setAttribute('style', 'background: #f6f6f5');
        }}
        onTouchEnd={(e) => {
          e.currentTarget.setAttribute('style', 'background: #fff');
        }}
      >
        <SVGIconWrapper>
          <SVGIcon src={application.icon} />
        </SVGIconWrapper>
        <ApplicationText className="ApplicationText">{children}</ApplicationText>
      </ApplicationBoxWrapper>
    );
  }
}
