import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import i18n from '../../i18NextConf';
import { MediaQueries } from '../../theme/media-queries-css';

const UnitToggleWrapper = styled('div')<{ small?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ small }) => (small ? '16px' : '60px')};
  padding-bottom: ${({ small }) => (small ? '16px' : '28px')};

  ${MediaQueries.small} {
    padding-top: ${({ small }) => (small ? '8px' : '22px')};
    padding-bottom: ${({ small }) => (small ? '8px' : '9px')};
  }
`;

const UnitToggleTitle = styled('div')<{ small?: boolean }>`
  font-weight: bold;
  font-size: ${({ small }) => (small ? '18px' : '28px')};
  line-height: ${({ small }) => (small ? '25px' : '32px')};

  ${MediaQueries.medium} {
    font-size: ${({ small }) => (small ? '18px' : '20px')};
    line-height: ${({ small }) => (small ? '25px' : '24px')};
  }
`;

const RadioWrapper = styled('div')`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  display-direction: row;

  ${MediaQueries.medium} {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
  }
`;

export type Unit = 'gram' | 'ppm';

export interface UnitSelectorProps {
  unit: string;
  label?: string;
  onToggleUnit(selectedUnit: Unit): void;
  small?: boolean;
  region: string;
}

const UnitSelector = ({ unit, label, onToggleUnit, small, region }: UnitSelectorProps) => {
  const handleRadioClick = useCallback(
    (selectedUnit: Unit) => () => {
      onToggleUnit(selectedUnit);
    },
    [onToggleUnit]
  );

  return (
    <UnitToggleWrapper className="medium" small={small}>
      {label ? <UnitToggleTitle small={small}>{label}</UnitToggleTitle> : null}
      <RadioWrapper>
        <FormControlLabel
          checked={unit === 'gram'}
          value="gram"
          control={<Radio className="gram" />}
          onChange={handleRadioClick('gram')}
          label={i18n.t(region === 're_CH' ? ' g / 25 kg flour' : 'g / 100 kg flour')}
        />
        <FormControlLabel
          checked={unit === 'ppm'}
          value="ppm"
          control={<Radio className="ppm" />}
          onChange={handleRadioClick('ppm')}
          label={i18n.t('ppm')}
        />
      </RadioWrapper>
    </UnitToggleWrapper>
  );
};

export default UnitSelector;
