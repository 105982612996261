import React from 'react';
import { Link } from 'react-router-dom';

import { styled } from '../../theme';
import FlowLayout from '../../components/flow-layout';
import { Title, Label, SubTitle } from '../../components/flow-layout/sidebar';
import StepHeader from '../../components/step-header';
import SubHeader from '../../components/sub-header';
import Button from '../../components/button';
import Loader from '../../components/loader';
import RecipeInput from './recipe-input';

import Disclaimer from '../../components/disclaimer';
import Divider from '../../components/divider';

import { fetchPreviousRecommendationsAPI } from '../../services/api';
import { MediaQueries } from '../../theme/media-queries-css';

import i18n from '../../i18NextConf';

import { ThemeContext } from '../../themecontext';

import ApplicationConfig, { IApplicationConfig } from '../application/application-config';
import RecipeParameters from '../results/recipe-parameters';
import UnitSelector, { Unit } from '../../components/unit/unitSelector';
import Sidebar from '../results/sidebar';
import { getFatLabel, getFatTitle } from '../application/application-config';

const StepWrapper = styled('div')``;

const Wrapper = styled('div')`
  max-width: 400px;
`;

const ButtonsWrapper = styled('div')`
  margin-top: 50px;
  margin-bottom: 24px;

  ${MediaQueries.medium} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  ${MediaQueries.small} {
    margin-top: 20px;
    margin-bottom: 0px;

    > a {
      width: 100%;
      display: block;
    }

    > a:first-of-type {
      order: 2;
      margin-top: 20px;
    }

    > a:last-of-type {
      order: 1;
      margin-bottom: 0px;
    }

    button {
      width: 100%;
    }
  }
`;
/*
const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);
  return [value, setValue];
};
*/

export default class RecipeParametersPage extends React.PureComponent<
  {
    children: React.ReactNode;
    sugar?: number | null;
    fat?: number | null;
    egg?: number | null;
    ryeFlour?: number | null;
    targetShelfLife?: number | null;
  },
  {
    sugar: number | null;
    fat: number | null;
    egg: number | null;
    ryeFlour: number | null;
    targetShelfLife: number | null;
    unit: string;
    invalidInputs: boolean;
    isLoading: boolean;
    application: IApplicationConfig;
  }
> {
  state = {
    sugar: null,
    fat: null,
    egg: null,
    ryeFlour: null,
    targetShelfLife: null,
    unit: 'ppm',
    invalidInputs: true,
    isLoading: true,
    application: ApplicationConfig().find((a) => a.key == localStorage.getItem('recipe-param-application')),
  };

  static contextType = ThemeContext;
  public sweetBreadStraightDoughSelected: boolean;

  async componentDidMount() {
    let { sugar, fat, egg, ryeFlour, targetShelfLife } = this.state; //this.props

    sugar = sugar || (localStorage.getItem('recipe-param-sugar') as any);
    targetShelfLife = targetShelfLife || (localStorage.getItem('recipe-param-targetShelfLife') as any);
    fat = fat || (localStorage.getItem('recipe-param-fat') as any);
    egg = egg || (localStorage.getItem('recipe-param-egg') as any);
    ryeFlour = ryeFlour || (localStorage.getItem('recipe-param-ryeFlour') as any);

    if (
      sugar !== undefined &&
      sugar !== null &&
      this.state.application.sugarOptions &&
      this.state.application.sugarOptions.split(',').indexOf(String(sugar)) < 0
    ) {
      sugar = null;
    }
    if (
      fat !== undefined &&
      fat !== null &&
      this.state.application.fatOptions &&
      this.state.application.fatOptions.split(',').indexOf(String(fat)) < 0
    ) {
      fat = null;
    }
    if (
      egg !== undefined &&
      egg !== null &&
      this.state.application.eggOptions &&
      this.state.application.eggOptions.split(',').indexOf(String(egg)) < 0
    ) {
      egg = null;
    }
    if (
      ryeFlour !== undefined &&
      ryeFlour !== null &&
      this.state.application.ryeFlourOptions &&
      this.state.application.ryeFlourOptions.split(',').indexOf(String(ryeFlour)) < 0
    ) {
      ryeFlour = null;
    }
    if (
      targetShelfLife !== undefined &&
      targetShelfLife !== null &&
      this.state.application.targetShelfLifeOptions &&
      this.state.application.targetShelfLifeOptions.split(',').indexOf(String(targetShelfLife)) < 0
    ) {
      targetShelfLife = null;
    }

    this.sweetBreadStraightDoughSelected =
      localStorage.getItem('recipe-param-application') === 'WhitePanBreadSweetStraightDough';

    if (sugar !== undefined) this.setState({ sugar });
    if (fat !== undefined) this.setState({ fat });
    if (egg !== undefined) this.setState({ egg });
    if (ryeFlour !== undefined) this.setState({ ryeFlour });
    if (targetShelfLife !== undefined) this.setState({ targetShelfLife });

    const unit = localStorage.getItem('unit') || 'ppm';
    this.setState({ unit });

    this.validationInputs(sugar, fat, egg, ryeFlour, targetShelfLife);
    this.setState({
      isLoading: false,
    });
    await fetchPreviousRecommendationsAPI();
  }

  validationInputs = (
    sugar: number | null,
    fat: number | null,
    egg: number | null,
    ryeFlour: number | null,
    targetShelfLife: number | null
  ) => {
    if (sugar === null && this.state.application && this.state.application.sugarOptions) {
      this.setState({ invalidInputs: true });
      return null;
    }

    if (fat === null && this.state.application && this.state.application.fatOptions) {
      this.setState({ invalidInputs: true });
      return null;
    }

    if (egg === null && this.state.application && this.state.application.eggOptions) {
      this.setState({ invalidInputs: true });
      return null;
    }
    if (ryeFlour === null && this.state.application && this.state.application.ryeFlourOptions) {
      this.setState({ invalidInputs: true });
      return null;
    }

    if (targetShelfLife === null && this.state.application && this.state.application.targetShelfLifeOptions) {
      this.setState({ invalidInputs: true });
      return null;
    }

    localStorage.setItem('recipe-param-sugar', String(sugar));
    localStorage.setItem('recipe-param-fat', String(fat));
    localStorage.setItem('recipe-param-egg', String(egg));
    localStorage.setItem('recipe-param-ryeFlour', String(ryeFlour));
    localStorage.setItem('recipe-param-targetShelfLife', String(targetShelfLife));

    this.setState({ invalidInputs: false });

    /*
    //Sugar rules
    if (sugar >= 2 && sugar <= 9) {
      //targetShelfLife rules
      if (
        targetShelfLife === 3 ||
        targetShelfLife === 7 ||
        targetShelfLife === 10 ||
        targetShelfLife === 14 ||
        targetShelfLife === 17 ||
        targetShelfLife === 21
      ) {
        this.setState({ invalidInputs: false })
        localStorage.setItem('recipe-param-targetShelfLife', String(targetShelfLife))
        localStorage.setItem('recipe-param-sugar', String(sugar))
      } else {
        this.setState({ invalidInputs: true })
      }
    } else {
      this.setState({ invalidInputs: true })
    }
    */
  };

  onSugarValue = (sugar: number | null) => {
    this.setState({ sugar: sugar });
    this.validationInputs(sugar, this.state.fat, this.state.egg, this.state.ryeFlour, this.state.targetShelfLife);
  };

  onFatValue = (fat: number | null) => {
    //console.log('onsugar' + sugar);
    this.setState({ fat: fat });
    this.validationInputs(this.state.sugar, fat, this.state.egg, this.state.ryeFlour, this.state.targetShelfLife);
  };

  onEggValue = (egg: number | null) => {
    //console.log('onsugar' + sugar);
    this.setState({ egg: egg });
    this.validationInputs(this.state.sugar, this.state.fat, egg, this.state.ryeFlour, this.state.targetShelfLife);
  };

  onRyeFlourValue = (ryeFlour: number | null) => {
    //console.log('onsugar' + sugar);
    this.setState({ ryeFlour: ryeFlour });
    this.validationInputs(this.state.sugar, this.state.fat, this.state.egg, ryeFlour, this.state.targetShelfLife);
  };

  onTargetShelfLifeValue = (targetShelfLife: number | null) => {
    //console.log('ontarget' + targetShelfLife);
    this.setState({ targetShelfLife: targetShelfLife });
    this.validationInputs(this.state.sugar, this.state.fat, this.state.egg, this.state.ryeFlour, targetShelfLife);
  };

  toggleUnit = (selectedUnit: Unit) => {
    this.setState({
      unit: selectedUnit,
    });

    localStorage.setItem('unit', selectedUnit);
  };

  render() {
    const { sugar, fat, egg, ryeFlour, targetShelfLife, unit, invalidInputs, isLoading } = this.state;

    /*
    console.log('sugar' + sugar)
    console.log('target' + targetShelfLife)
    console.log('LS sugar' + String(localStorage.getItem('recipe-param-sugar')))
    console.log('LS target' + String(localStorage.getItem('recipe-param-targetShelfLife')))
    */

    let application = ApplicationConfig().find((a) => a.key == localStorage.getItem('recipe-param-application'));

    interface SmartBakeInterface {
      mobile: string;
      tablet: string;
      desktop: string;
      device: string;
      region: string;
    }

    let SmartBake: SmartBakeInterface = {
      tablet: this.context.device.tablet,
      desktop: this.context.device.desktop,
      mobile: this.context.device.mobile,
      device: this.context.device,
      region: this.context.region,
    };

    if (isLoading) return <Loader />;

    return (
      <FlowLayout
        sidebar={
          <Sidebar
            applicationData={application}
            input={{ sugar, fat, egg, ryeFlour, targetShelfLife }}
            unit={unit}
            region={SmartBake.region}
          />
        }
        progress={3 / 4}
      >
        <StepWrapper className="RecipeParameters">
          <StepHeader
            title={i18n.t('Recipe parameters')}
            description={`${i18n.t(
              '*While there are many ingredients in a formulation, we have considered the key ones that would impact fresh-keeping enzymes dosage recommendation.'
            )} ${i18n.t('To get the narrowest dosage range, input your recipes sugar level.')}`}
            previousPage="/ApplicationPage"
            currentPage="RecipeParameters"
          />

          {application.sugarOptions ? (
            <Wrapper>
              <SubHeader title={i18n.t('Sugar')} />
              <RecipeInput
                minWidth={150}
                maxWidth={254}
                label={i18n.t('Sugar level')}
                unit="%"
                onValue={this.onSugarValue}
                value={sugar}
                className="sugar-level"
                options={application.sugarOptions}
              />
            </Wrapper>
          ) : (
            ''
          )}

          {application.fatOptions ? (
            <Wrapper>
              <SubHeader title={getFatTitle(application.key)} />
              <RecipeInput
                minWidth={150}
                maxWidth={254}
                label={getFatLabel(application.key)}
                unit="%"
                onValue={this.onFatValue}
                value={fat}
                className="fat-level"
                options={application.fatOptions}
              />
            </Wrapper>
          ) : (
            ''
          )}

          {application.eggOptions ? (
            <Wrapper>
              <SubHeader title={i18n.t('Egg')} />
              <RecipeInput
                minWidth={150}
                maxWidth={254}
                label={i18n.t('Egg level')}
                unit="%"
                onValue={this.onEggValue}
                value={egg}
                className="egg-level"
                options={application.eggOptions}
              />
            </Wrapper>
          ) : (
            ''
          )}

          {application.ryeFlourOptions ? (
            <Wrapper>
              <SubHeader title={i18n.t('Rye flour')} />
              <RecipeInput
                minWidth={150}
                maxWidth={254}
                label={i18n.t('Rye flour level')}
                unit="%"
                onValue={this.onRyeFlourValue}
                value={ryeFlour}
                className="rye-flour"
                options={application.ryeFlourOptions}
              />
            </Wrapper>
          ) : (
            ''
          )}

          {application.targetShelfLifeOptions ? (
            <Wrapper>
              <SubHeader
                title={
                  this.sweetBreadStraightDoughSelected ? i18n.t('Target shelf life **') : i18n.t('Target shelf life')
                }
              />
              <RecipeInput
                minWidth={174}
                maxWidth={295}
                label={i18n.t('Target shelf life')}
                unit={i18n.t('days')}
                onValue={this.onTargetShelfLifeValue}
                value={targetShelfLife}
                className="target-shelf-life"
                options={application.targetShelfLifeOptions}
              />
            </Wrapper>
          ) : (
            ''
          )}

          <Wrapper>
            <UnitSelector
              unit={unit}
              label={i18n.t('Unit')}
              onToggleUnit={this.toggleUnit}
              small
              region={SmartBake.region}
            />
          </Wrapper>

          <ButtonsWrapper>
            <Link to="/ApplicationPage">
              <Button id="recipe-btn-back" minWidth={100} marginRight={12} primary={false}>
                {i18n.t('Back')}
              </Button>
            </Link>
            <Link
              to={
                invalidInputs === false
                  ? {
                      pathname: '/ResultsPage' /*,
                      state: {
                        sugar: sugar,
                        targetShelfLife: targetShelfLife,
                      }*/,
                    }
                  : {}
              }
            >
              <Button id="recipe-btn-get-your-results" minWidth={175} marginRight={0} primary disabled={invalidInputs}>
                {i18n.t('Get your results')}
              </Button>
            </Link>
          </ButtonsWrapper>

          <Divider desktopHeight={30} tabletHeight={30} mobileHeight={20} />

          {this.sweetBreadStraightDoughSelected && (
            <Disclaimer
              text={i18n.t(
                '**Bread crumb softness decreases rapidly during the first weeks of storage due to drastic loss in moisture to environment. When the moisture reaches equilibrium, the staling rate becomes constant. When targeting a shelf life longer than available in SmartBake, increase your dosage by 25-50% to reach optimal softness.'
              )}
            />
          )}

          <Divider desktopHeight={60} tabletHeight={50} mobileHeight={30} />
        </StepWrapper>
      </FlowLayout>
    );
  }
}
