import React from 'react';
import styled from 'styled-components';
import Main from './main';
import Sidebar from './sidebar';
import { debounceAnimationFrame } from '../../utils';
import SliderArrow from '../../images/slider-arrow.png';

import Navi from '../navigation/navi';
import BurgerNavigation from '../burgermenu';
import { MediaQueries } from '../../theme/media-queries-css';

import { ThemeContext } from '../../themecontext';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;

  ${MediaQueries.large} {
    min-height: 100%;
  }

  ${MediaQueries.medium} {
    flex-direction: column;
    height: 100%;
  }
`;

const LeftColumn = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 460px;
  flex-grow: 0;
  padding: 0 0 56px 0;
  background-image: url('/bread2.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 1460px) and (min-width: 993px) {
    flex-basis: 436px;
  }

  ${MediaQueries.medium} {
    position: fixed;
    max-height: 100% !important;
    height: 0;
    width: 100%;
    padding: 0;
    bottom: 0;
    z-index: 1;
    /*transform: translateY(100%);*/
    transition: height 0.45s ease;
    will-change: height;
    overflow: hidden;
    box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.55);
    z-index: 100;
  }
`;

const RightColumn = styled.div<{
  resultspage: boolean;
}>`
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink:0;
    padding-left:0;
    padding-right:0;
    background: ${({ resultspage }) => (resultspage ? '#f6f6f6' : '#f6f6f6')};

    ${MediaQueries.medium} {
    	padding-left:  ${({ theme }) => theme.padding.medium};
    	padding-right: ${({ theme }) => theme.padding.medium};
      height: auto !important;
      order:1;
      width:100%;

      ${({ resultspage }) =>
        !resultspage &&
        `
        padding-top: 0px;
      `}

    ${MediaQueries.small} {
    	padding-left:  ${({ theme }) => theme.padding.medium};
    	padding-right: ${({ theme }) => theme.padding.medium};
    }
`;

const VerticalArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 40px;
  margin: 0 auto;
  z-index: 1001;
  cursor: pointer;
`;

const VerticalArrow = styled.div`
  width: 39px;
  height: 14px;
  &.showArrow {
    background: url(${SliderArrow}) no-repeat 50% 0%;
    background-size: 39px 14px;
    margin-top: 12px;
  }

  &.hideArrow {
    top: -2px;
    height: 40px;
    &:after {
      position: relative;
      top: 13px;
      display: block;
      height: 4px;
      background: #77306e;
      content: '';
    }
  }
`;

const Column = styled.div`
  min-height: 100%;
`;

type Props = {
  progress: number;
  sidebar: React.ReactNode;
  children: React.ReactNode;
};

interface State {
  sliderClosed: boolean;
  openNavigation: boolean;
}

export default class FlowLayout extends React.PureComponent<Props, State> {
  static contextType = ThemeContext;

  state = {
    sliderClosed: false,
    openNavigation: false,
  };

  closeOpenSlider = () => {
    let applicationSubtitle: HTMLDivElement = document.querySelector('.sidebar-content .application-subtitle');

    if (applicationSubtitle !== null) {
      this.setState({
        sliderClosed: !this.state.sliderClosed,
      });
    }
  };

  openNavigation = () => {
    const { openNavigation } = this.state;
    document.querySelector('html').className += 'noscroll';
    this.setState({
      openNavigation: true,
    });
  };

  closeNavigation = () => {
    document.querySelector('html').classList.remove('noscroll');
    this.setState({
      openNavigation: false,
    });
  };

  resultsPage = (): boolean => {
    return window.location.pathname.replace(/\//g, '').toLowerCase() === 'resultspage';
  };

  removeHeightOnDesktop = () => {
    let leftColumn: HTMLElement = document.querySelector('.left-column');
    //let rightColumn: HTMLElement = document.querySelector('.RightColumn')
    //let flowLayoutWrapper: HTMLElement = document.querySelector('.flowlayout-wrapper')

    if (leftColumn !== null /* && rightColumn !== null && flowLayoutWrapper !== null*/) {
      leftColumn.style.removeProperty('height');
      leftColumn.style.removeProperty('overflow');
      //rightColumn.style.removeProperty('height')

      //flowLayoutWrapper.style.removeProperty('height')
      //flowLayoutWrapper.style.removeProperty('overflow')
    }
  };

  componentDidMount() {
    //this.forceUpdate() // if forceUpdate is not fired on componentDidMount calculateHeight returns null
  }

  render() {
    const { tablet, desktop } = this.context.device;
    if (desktop) this.removeHeightOnDesktop();

    const { sliderClosed, openNavigation } = this.state;

    return (
      <>
        <Navi show={openNavigation} closemodal={this.closeNavigation} />
        <Page>
          <LeftColumn className="left-column">
            <BurgerNavigation id="menu-hamburger-open-menu" open={this.openNavigation} />
            {tablet && (
              <VerticalArrowWrapper className="VerticalArrowWrapper" onClick={this.closeOpenSlider}>
                <VerticalArrow className={`VerticalArrow${sliderClosed ? ' hideArrow' : ' showArrow'}`} />
              </VerticalArrowWrapper>
            )}
            <Column className="Column">
              <Sidebar sliderclosed={sliderClosed} openclose={this.closeOpenSlider}>
                {this.props.sidebar}
              </Sidebar>
            </Column>
          </LeftColumn>
          <RightColumn className="RightColumn padding left" resultspage={this.resultsPage()}>
            <Column className="Column">
              <Main progress={this.props.progress}>{this.props.children}</Main>
            </Column>
          </RightColumn>
        </Page>
      </>
    );
  }
}
