import { colors } from '@novozymes-digital/components';
import React from 'react';
import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

const animate = () => `
  :hover {
    transform: scale(1);
  }
  transform: scale(1);
  transition: transform 300ms ease;
  transform: translate3d(0,0,1);
`;

const ButtonWrapper = styled.button<{
  minWidth: number;
  marginRight: number;
  theme: object;
  primary: boolean;
  disabled: boolean;
}>`
  font-family: 'Novozymes';
  ${({ disabled }) => (disabled ? '' : `${animate()}`)}
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  position: relative;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin-right: ${({ marginRight }) => marginRight}px;
  background-color: ${({ primary, theme }) => (primary ? theme.color.green : 'transparent')};
  border: ${({ primary, theme }) => (primary ? 'none' : 'solid 1px #000')};
  border-radius: 50px;
  padding: ${({ primary }) => (primary ? '11px 30px' : '9px 30px')};
  min-width: ${({ minWidth }) => minWidth}px;
  font-size: 16px;
  line-height: 24px;

  ${MediaQueries.medium} {
    line-height: 23px;
    padding: ${({ primary }) => (primary ? '11px 30px' : '10px 30px')};
  }
`;

const ButtonText = styled.div<{
  primary: boolean;
  theme: object;
}>`
  font-weight: ${({ primary }) => (primary ? 'bold' : 'bold')};
  color: ${colors.black};
`;

export default class Button extends React.PureComponent<{
  id?: string;
  children: React.ReactNode;
  minWidth?: number;
  marginRight?: number;
  primary: boolean;
  disabled?: boolean;
  onClick?: (value: any | null) => void;
  classname: string;
}> {
  static defaultProps = {
    classname: 'ButtonWrapper',
  };

  render() {
    const { id, classname, children, minWidth, marginRight, primary, disabled, onClick } = this.props;

    return (
      <ButtonWrapper
        className={classname}
        id={id}
        onClick={onClick}
        minWidth={minWidth === undefined ? 190 : minWidth}
        marginRight={marginRight === undefined ? 0 : marginRight}
        primary={primary}
        disabled={disabled === undefined ? false : disabled}
      >
        <ButtonText primary={primary}>{children}</ButtonText>
      </ButtonWrapper>
    );
  }
}
