import React from 'react';
import { styled } from '../../theme';

const Wrapper = styled('div')`
  flex-basis: 30px;
  flex-shrink: 0;
`;

const SVG = styled('svg')<{
  className: string;
}>`
  width: 30px;
  height: 30px;
  fill: #2d0127;

  line {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }

  ${({ className }) =>
    /active/gi.test(className) &&
    `
    fill: #e7e7e7;

    line.vertical {
      display: none;
    }

    line.horizontal {
      stroke: #2d0127;
    }

  `}
`;

const AccordionArrow = (props) => {
  return (
    <Wrapper>
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={props.className}>
        <circle cx="15" cy="15" r="15" />
        <line className="horizontal" x1="11.02" y1="15" x2="18.98" y2="15" />
        <line className="vertical" x1="15" y1="18.98" x2="15" y2="11.02" />
      </SVG>
    </Wrapper>
  );
};

export default AccordionArrow;
