export function debounceAnimationFrame<A extends any[]>(f: (...args: A) => void): (...args: A) => void {
  let timeout: number | null = null;

  if (timeout) {
    window.cancelAnimationFrame(timeout);
  }

  function debouncedFunction(...args: A): void {
    timeout = window.requestAnimationFrame(function () {
      f(...args);
    });
  }
  return debouncedFunction;
}

export const isSandbox = () => process.env.REACT_APP_ENV === 'sandbox';
