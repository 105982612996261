import React from 'react';
import { withTheme } from 'styled-components';
import { Link, Navigate } from 'react-router-dom';

import ApplicationConfig from '../application/application-config';

import { Enzyme, EnzymeGraphType } from '../../types';
import { styled, Theme } from '../../theme';
import Button from '../../components/button';
import Modal from '../../components/modal';
import Form from '../../components/form';
import Input from '../../components/input';
import Loader from '../../components/loader';
import FlowLayout from '../../components/flow-layout';
import StepHeader from '../../components/step-header';

import EnzymeInfoContainer from './enzyme-info-container';
import EnzymeInfoDescr from './enzyme-info-description';
import EnzymeList from './enzyme-list';
import EnzymeInfo from './enzyme-info';
import EnzymeGraph from './enzyme-graph';
import FreshnessInfoModal from './freshness-info-modal';

import LearnMore from './learn-more/';
import FactorsEffectingFreshkeeping from '../../components/learn-more/factors-effecting-freshkeeping';
import SoftnessOfTheCrumb from '../../components/learn-more/how-is-the-softness-of-the-crumb-measured';
import ElasticityOfTheCrumb from '../../components/learn-more/how-is-elasticity-of-the-crumb-measured';
import LearnMoreModal from '../../components/learn-more/modal';

import ConsumerStatements from './consumer-statements/';
import Statement from './consumer-statements/statements';

import Divider from '../../components/divider';
import Disclaimer from '../../components/disclaimer';

import Video from '../../components/video';
import ResultsTabs from './tabs';

import Header from '../../components/learn-more/parts/header';
import FormularOtherIssuesResultspage from '../../components/forms/other_issues_resultspage';

import { fetchPreviousRecommendationsAPI, calculateResultAPI, saveResultAPI } from '../../services/api';

import i18n from '../../i18NextConf';
import { debounceAnimationFrame } from '../../utils';
import { MediaQueries } from '../../theme/media-queries-css';

import InfoPng from '../../images/info-icon.png';
import { ThemeContext } from '../../themecontext';

import Results_OK from '../../images/results_ok.svg';
import ElasticityVideo from '../../components/video/elasticityVideo';
import UnitSelector, { Unit } from '../../components/unit/unitSelector';
import PardotDialog from '../../components/dialogs/PardotDialog';
import triggerHotjar from '../../helpers/triggerHotjar';

import Sidebar from './sidebar';
import ResultIntro from './result-intro';
import { Trans } from 'react-i18next';
import WrapWithLink from '../../components/WrapWithLink';
import GetProductLinks from '../../static-data/ProductLinks';
import { colors } from '@novozymes-digital/components';
import { withRouter } from './withRouter';

const ResultsPageWrapper = styled('div')`
  .medium {
    ${MediaQueries.large} {
      max-width: 876px;
    }
  }

  .large {
    max-width: 1004px;
  }
`;
const DivWrapper = styled('div')`
  button > div {
    font-weight: normal;
  }
`;
const StepWrapper = styled('div')``;
const MainContent = styled('div')``;

const HeaderWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 43px;

  ${MediaQueries.medium} {
    padding-top: 0px;
  }

  ${MediaQueries.small} {
  }
`;

const FreshnessAttributeResult = styled('div')<{ show: string }>`
  display: ${({ show }) => (show === 'visible' ? 'block' : 'none')}
  margin-bottom: 0;
`;

const FreshnessDetails = styled('div')<{ flexdirection: string }>`
  display: flex;
  flex-direction: ${({ flexdirection }) => flexdirection};
  background: #fff;
  justify-content: space-around;
  padding-top: 80px;

  @media screen and (min-width: 1461px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 1279px) {
    &:not(.no-flex-column) {
      flex-direction: column;
    }
  }

  ${MediaQueries.medium} {
    padding-top: 50px;
  }

  ${MediaQueries.large} {
    justify-content: space-between;
  }

  ${MediaQueries.small} {
    padding-top: 30px;
    &.no-flex-column {
      flex-direction: column;
    }

    .enzyme-info-container {
      width: 100% !important;
    }

    .graph {
      margin-top: 16px;
      width: 100% !important;
    }
  }
`;

const SaveRecommendationWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 48px;
  background: ${({ theme }) => theme.color.lightgrey};

  ${MediaQueries.large} {
    border-top: solid 2px ${({ theme }) => theme.color.green}
    padding-top: 80px
    padding-bottom: 80px;
  }
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;

  ${MediaQueries.medium} {
    background: #fff;

    > * {
      margin-bottom: 20px;
    }
  }

  ${MediaQueries.medium} {
    flex-direction: column;

    > a,
    button {
      width: 100%;
      max-width: 400px;
    }

    > a {
      order: 2;
      margin: 0px auto 40px !important;
    }

    > button {
      order: 1;
      margin: 0 auto 20px;
    }
  }

  ${MediaQueries.medium} {
    justify-content: center;
    > * {
      margin-bottom: 0px;
    }
  }
`;

const LinksDivider = styled('div')`
  height: 54px;

  ${MediaQueries.medium} {
    height: 34px;
  }
`;

const LinksWrapper = styled('div')`
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    ${MediaQueries.medium} {
      justify-content: center;
    }
  }

  p,
  a {
    display: inline-block;
    margin-right: auto;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    ${MediaQueries.medium} {
      margin-right: unset;
    }
  }

  a {
    margin-bottom: 16px;
  }
`;

const Bold = styled('div')`
  display: inline;
  font-weight: bold;
`;

const GreenColor = styled('span')`
  color: ${({ theme }) => theme.color.green}
  font-weight: bold;
`;

const OrangeColor = styled('span')`
  color: ${({ theme }) => theme.color.orange}
  font-weight: bold;
`;

const Paragragh = styled('p')`
  margin: 0 0 20px;
`;

/*
const InfoIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`
const InfoIcon = styled('div')`
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: url(${InfoPng}) no-repeat 50% 50%;
  background-size: 26px 26px;
`*/

const InfoIconText = styled('div')`
  padding-left: 15px;
  color: ${colors.black};
  text-decoration: underline;
`;

const FreshnessWrapper = styled('div')`
  background: #fff;
`;

const ConsumerStatementsWrapper = styled('div')`
  display: flex;
  background: #fff;

  ${MediaQueries.large} {
    padding-left: ${({ theme }) => theme.padding.large};
    padding-right: ${({ theme }) => theme.padding.large};
    padding-top: ${({ theme }) => theme.padding.large};
  }

  ${MediaQueries.medium} {
    padding-left: ${({ theme }) => theme.padding.medium};
    padding-right: ${({ theme }) => theme.padding.medium};
    padding-top: ${({ theme }) => theme.padding.medium};
  }

  ${MediaQueries.small} {
    padding-left: ${({ theme }) => theme.padding.small};
    padding-right: ${({ theme }) => theme.padding.small};
  }

  @media screen and (max-width: 1279px) {
    flex-direction: column;

    .enzyme-info-container {
      padding-bottom: 60px;
      width: 100% !important;

      ${MediaQueries.medium} {
        padding-bottom: 20px;
      }

      ${MediaQueries.small} {
        padding-bottom: 20px;
      }
    }
  }
`;

const FeedBackInfo = styled.div`
  padding: 50px 40px;
  box-shadow: 0 14px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 260px ul {
    li {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  theme: Theme;
  application?: string;
  sugar?: number;
  fat?: number;
  egg?: number;
  ryeFlour?: number;
  targetShelfLife?: number;
}

interface State {
  currentTab: string;
  isLoading: boolean;
  unit: string;
  openPriceModal: boolean;
  priceNovamyl3D: any;
  priceNovamyl10000: any;
  costNovamyl3DFrom: number | null;
  costNovamyl3DTo: number | null;
  costNovamyl10000From: number | null;
  costNovamyl10000To: number | null;
  openSaveModal: boolean;
  openModal: string;
  recommendationTitle: string;
  comment: string;
  showFreshnessInfo: boolean;
  softnessData: Array<EnzymeGraphType> | null;
  elasticityData: Array<EnzymeGraphType> | null;
  novamyl3DMax: number;
  novamyl3DMin: number;
  novamyl10000Max: number;
  novamyl10000Min: number;
  data: any;
  previousRecommendations: any;
  duplicateRecommendationTitle: boolean;
  showFeedback: boolean;
  showPardotDialog: boolean;
}

type Outcomes = {
  PPMMax: number;
  PPMMean: number;
  PPMMin: number;
  PPMViz: number;
  elasticity: number[];
  name: Enzyme;
  softness: number[];
}[];

type ApiData = {
  control: any;
  outcomes: Outcomes;
  elasticity_lower: number;
  elasticity_upper: number;
  softness_lower: number;
  softness_upper: number;
};

class ResultsPage extends React.PureComponent<Props, State> {
  applicationData: any = ApplicationConfig().find((a) => a.key == localStorage.getItem('recipe-param-application'));

  private _isMounted: boolean = false;
  private dayEqualTo: string;
  private tabsRef: React.RefObject<HTMLDivElement>;
  static contextType = ThemeContext;

  constructor(props: Props) {
    super(props);

    this.tabsRef = React.createRef();

    this.state = {
      currentTab: 'moistness',
      isLoading: true,
      unit: 'ppm',
      openPriceModal: false,
      priceNovamyl3D: localStorage.getItem('priceNovamyl3D'),
      priceNovamyl10000: localStorage.getItem('priceNovamyl10000'),
      costNovamyl3DFrom: null,
      costNovamyl3DTo: null,
      costNovamyl10000From: null,
      costNovamyl10000To: null,
      openSaveModal: false,
      openModal: '',
      recommendationTitle: this.applicationData.name,
      comment: '',
      showFreshnessInfo: false,
      softnessData: null,
      elasticityData: null,
      novamyl3DMax: 0,
      novamyl3DMin: 0,
      novamyl10000Max: 0,
      novamyl10000Min: 0,
      data: {},
      previousRecommendations: [],
      duplicateRecommendationTitle: false,
      showFeedback: false,
      showPardotDialog: false,
    };
  }

  async fetchNewResult() {
    this.setState({
      isLoading: true,
    });

    const { application, sugar, fat, egg, ryeFlour, targetShelfLife } = this.props;

    const region = this.context.region;

    const result = await calculateResultAPI({
      region,
      application,
      sugar,
      fat,
      egg,
      ryeFlour,
      targetShelfLife,
    });

    if (result) {
      const data = result;
      const unit = localStorage.getItem('unit') || 'ppm';

      this.dayEqualTo = data.control.day_equal_to;

      this.setState({
        unit,
        data,
      });

      this.calculatePrice();
    }

    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.application !== this.props.application ||
      prevProps.sugar !== this.props.sugar ||
      prevProps.fat !== this.props.fat ||
      prevProps.egg !== this.props.egg ||
      prevProps.ryeFlour !== this.props.ryeFlour ||
      prevProps.targetShelfLife !== this.props.targetShelfLife
    ) {
      this.fetchNewResult();
    }

    if (prevState.isLoading && !this.state.isLoading) {
      let lang = i18n.language;
      triggerHotjar('result_page', lang);
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.fetchNewResult();

    const history = await fetchPreviousRecommendationsAPI();
    const fetchedRecommendations = history;

    this.setState({
      previousRecommendations: fetchedRecommendations,
    });

    window.addEventListener(
      'resize',
      debounceAnimationFrame(() => {
        this.forceUpdate();
      })
    );
  }

  componentWillUnmount() {
    this._isMounted = true;
  }

  getGraphData = (apiData: ApiData, unit: string, type: 'softness' | 'elasticity'): EnzymeGraphType[] => {
    const indexToColor = (index) => {
      switch (index) {
        case 0:
          return '#c4d203';
        case 1:
          return '#ffb403';
        default:
          return '#ffe102';
      }
    };

    interface EnzmyeGraphDataType {
      id: string | number;
      color: string;
      data: {
        x: any;
        y: number;
      }[];
      min: any;
      max: any;
    }

    const enzymeGraphData: EnzmyeGraphDataType[] = apiData.outcomes.map((enzData, index) => {
      return {
        id: `${i18n.t(enzData.name).replace('<sup>®</sup>', '')}, ${
          unit === 'ppm' ? enzData.PPMViz + 'ppm' : Number((enzData.PPMViz / 10).toFixed(1)) + i18n.t('g / 100 kg')
        }`,

        color: indexToColor(index),
        data: enzData[type].map((item: any, index: any) => {
          return {
            x: index + 1,
            y: Number(item.toFixed(2)),
          };
        }),
        min: apiData[`${type}_lower`],
        max: apiData[`${type}_upper`],
      };
    });

    const controlGraphData = {
      id: i18n.t('Control'),
      color: '#9d9d9d',
      data: apiData.control[type].map((dataPoint, index) => ({
        x: index + 1,
        y: Number(dataPoint.toFixed(2)),
      })),
      min: 0,
      max: 0,
    };

    return [...enzymeGraphData, controlGraphData];
  };

  toggleUnit = (selectedUnit: Unit) => {
    this.setState({
      unit: selectedUnit,
    });

    localStorage.setItem('unit', selectedUnit);
  };

  removeBodyScroll = (open: boolean): void => {
    open
      ? (document.querySelector('html').className += 'noscroll')
      : document.querySelector('html').classList.remove('noscroll');
  };

  setCurrentTab = (tab: string) => {
    this.setState({
      currentTab: tab,
    });
  };

  openModal = (current: string) => {
    localStorage.removeItem('scrollPos');
    // @ts-ignore
    localStorage.setItem('scrollPos', window.pageYOffset);
    this.removeBodyScroll(true);
    this.setState({
      openModal: current,
    });
  };

  closeModal = () => {
    this.removeBodyScroll(false);
    this.setState({
      openModal: '',
    });
  };

  handleOpenPriceModal = () => {
    this.setState({
      openPriceModal: true,
    });
  };

  handleClosePriceModal = () => {
    this.setState({
      openPriceModal: false,
    });
  };

  changeNovamyl3DValue = (priceNovamyl3D: any): void => {
    this.setState({ priceNovamyl3D });
    localStorage.setItem('priceNovamyl3D', priceNovamyl3D);
  };

  changeNovamyl10000Value = (priceNovamyl10000: any): void => {
    this.setState({ priceNovamyl10000 });
    localStorage.setItem('priceNovamyl10000', priceNovamyl10000);
  };

  calculatePrice = () => {
    const { priceNovamyl3D, priceNovamyl10000, novamyl3DMin, novamyl3DMax, novamyl10000Min, novamyl10000Max } =
      this.state;

    if (priceNovamyl3D !== null && priceNovamyl10000 !== null) {
      const costNovamyl3DFrom = (novamyl3DMin * Number(priceNovamyl3D)) / 1000;
      const costNovamyl3DTo = (novamyl3DMax * Number(priceNovamyl3D)) / 1000;

      const costNovamyl10000From = (novamyl10000Min * Number(priceNovamyl10000)) / 1000;
      const costNovamyl10000To = (novamyl10000Max * Number(priceNovamyl10000)) / 1000;

      this.setState({
        costNovamyl3DFrom,
        costNovamyl3DTo,
        costNovamyl10000From,
        costNovamyl10000To,
        openPriceModal: false,
      });
    }
  };

  handleOpenSaveModal = () => {
    this.setState({
      openSaveModal: true,
    });
  };
  handleCloseSaveModal = () => {
    this.setState({
      openSaveModal: false,
    });
  };

  changeRecommendationTitle = (recommendationTitle: any): void => {
    const { previousRecommendations } = this.state;
    const dup = previousRecommendations.find((h) => h.title.toLowerCase() == recommendationTitle.toLowerCase()) != null;
    this.setState({
      recommendationTitle: recommendationTitle,
      duplicateRecommendationTitle: dup,
    });
  };

  changeComment = (comment: any): void => {
    this.setState({ comment });
  };

  saveRecommendation = async () => {
    let { sugar, fat, egg, ryeFlour, targetShelfLife } = this.props;

    let application = localStorage.getItem('recipe-param-application');
    sugar = +(localStorage.getItem('recipe-param-sugar') || 0);
    fat = +(localStorage.getItem('recipe-param-fat') || 0);
    egg = +(localStorage.getItem('recipe-param-egg') || 0);
    ryeFlour = +(localStorage.getItem('recipe-param-ryeFlour') || 0);
    targetShelfLife = +(localStorage.getItem('recipe-param-targetShelfLife') || 0);

    const { comment, recommendationTitle } = this.state;
    let newComment: string = comment === '' ? 'No comments' : comment;

    //if (sugar !== undefined && targetShelfLife !== undefined) {
    await saveResultAPI({ application, sugar, fat, targetShelfLife, egg, ryeFlour }, newComment, recommendationTitle);
    this.setState({ showFeedback: true });
    //}
    //this.handleCloseSaveModal()
    //history.push('/OverviewPage')
  };

  openFreshnessInfo = () => {
    this.removeBodyScroll(true);
    this.setState({
      showFreshnessInfo: true,
    });
  };

  closeFreshnessInfoModal = () => {
    this.removeBodyScroll(false);
    this.setState({
      showFreshnessInfo: false,
    });
  };

  openPardotDialog = () => {
    this.setState({
      showPardotDialog: true,
    });
  };

  closePardotDialog = () => {
    this.setState({
      showPardotDialog: false,
    });
  };

  scrollToTabs = () => {
    if (this.tabsRef) {
      this.tabsRef.current.scrollIntoView(true);
    }
  };

  showTabAndScroll = (tab) => {
    this.setCurrentTab(tab);
    this.scrollToTabs();
  };

  getMoistnessContent = (application: string, lang: string, recommendations: Outcomes, region: string) => {
    const recommendationName = recommendations[0] && recommendations[0].name;
    const recommendation = (
      <Trans
        i18nKey={recommendationName}
        components={{
          sup: <sup />,
        }}
      />
    );

    const hasAlternative = recommendations.length > 1;
    const alternativeName = hasAlternative && recommendations[1].name;
    const alternative = hasAlternative ? (
      <Trans
        i18nKey={alternativeName}
        components={{
          sup: <sup />,
        }}
      />
    ) : null;

    if (recommendationName === 'Novamyl Rye BG' && alternativeName === 'Novamyl 3D BG') {
      return (
        <>
          <EnzymeInfoDescr title={i18n.t('The effect of enzymes on moistness')}>
            <Trans
              i18nKey="rye-moistness-1"
              components={{
                green: <GreenColor />,
                orange: <OrangeColor />,
                sup: <sup />,
              }}
              values={{
                recommendation: i18n.t(recommendationName),
                alternative: i18n.t(alternativeName),
              }}
            ></Trans>
          </EnzymeInfoDescr>

          <EnzymeInfoDescr>
            <Trans
              i18nKey="rye-moistness-2"
              components={{
                green: <GreenColor />,
                orange: <OrangeColor />,
                sup: <sup />,
              }}
              values={{
                recommendation: i18n.t(recommendationName),
                alternative: i18n.t(alternativeName),
              }}
            ></Trans>
          </EnzymeInfoDescr>

          <EnzymeInfoDescr>
            <Trans i18nKey="rye-moistness-3"></Trans>
          </EnzymeInfoDescr>
        </>
      );
    }

    console.log('recommendation && alternative', {
      recommendation,
      alternative,
    });

    if (recommendation && alternative) {
      return (
        <>
          <EnzymeInfoDescr title={i18n.t('The effect of enzymes on moistness')}>
            {i18n.t('At the recommended dosages')}
            <span className="green">{recommendation}</span>
            {i18n.t('will maintain a high moistness perception')}
            {i18n.t('of your bread throughout your target shelf life. With ')}
            <span className="orange">{alternative}</span>
            {i18n.t(' some degree of moistness is maintained.')}
          </EnzymeInfoDescr>
          <EnzymeInfoDescr>
            {i18n.t(
              '*Moistness perception is how moist the bread feels during chewing. If you feel the moisture is being drawn from your mouth by the sample, the sample is dry; not moist.'
            )}
          </EnzymeInfoDescr>
          {region != 're_JA' && (
            <EnzymeInfoDescr>
              {i18n.t('moistness customer 1')}
              <GreenColor>
                {
                  <Trans
                    i18nKey={'Novamyl® 3D BG'}
                    components={{
                      sup: <sup />,
                    }}
                  />
                }
              </GreenColor>
              {i18n.t('moistness customer 2')}
              <GreenColor>
                <Trans
                  i18nKey={'Novamyl® 3D BG'}
                  components={{
                    sup: <sup />,
                  }}
                />{' '}
              </GreenColor>
              {i18n.t('moistness customer 3')}
              <OrangeColor>
                <Trans
                  i18nKey={'Novamyl® 10000 BG'}
                  components={{
                    sup: <sup />,
                  }}
                />{' '}
              </OrangeColor>
              {i18n.t('moistness customer 4')}{' '}
              {lang === 'en' ? (
                <Trans
                  i18nKey={'Novamyl® 3D BG'}
                  components={{
                    sup: <sup />,
                  }}
                />
              ) : (
                ''
              )}
            </EnzymeInfoDescr>
          )}
        </>
      );
    }

    return (
      <>
        <EnzymeInfoDescr title={i18n.t('The effect of enzymes on moistness')}>
          {i18n.t('At the recommended dosages')}
          <GreenColor>{recommendation}</GreenColor>
          {i18n.t('will maintain a high moistness perception')}
          {i18n.t('of your bread throughout your target shelf life.')}
        </EnzymeInfoDescr>
        <EnzymeInfoDescr>
          {i18n.t(
            '*Moistness perception is how moist the bread feels during chewing. If you feel the moisture is being drawn from your mouth by the sample, the sample is dry; not moist.'
          )}
        </EnzymeInfoDescr>
        <EnzymeInfoDescr>
          {i18n.t('ch - A Consumer panel gave bread with')}
          <GreenColor>{recommendation}</GreenColor>
          {i18n.t(
            'ch - higher scores on moistness, tenderness and overall appeal compared to bread with no fresh-keeping enzyme. Increasing'
          )}
          <GreenColor>{recommendation}</GreenColor>
          {i18n.t('ch - dosages led to even higher consumer preference.')}
        </EnzymeInfoDescr>
      </>
    );
  };

  render() {
    let { theme, sugar, fat, egg, ryeFlour, targetShelfLife } = this.props;
    interface SmartBakeInterface {
      mobile: string;
      tablet: string;
      desktop: string;
      device: string;
      region: string;
    }

    let SmartBake: SmartBakeInterface = {
      tablet: this.context.device.tablet,
      desktop: this.context.device.desktop,
      mobile: this.context.device.mobile,
      device: this.context.device,
      region: this.context.region,
    };

    sugar = +(localStorage.getItem('recipe-param-sugar') || 0);
    fat = +(localStorage.getItem('recipe-param-fat') || 0);
    egg = +(localStorage.getItem('recipe-param-egg') || 0);
    ryeFlour = +(localStorage.getItem('recipe-param-ryeFlour') || 0);
    targetShelfLife = +(localStorage.getItem('recipe-param-targetShelfLife') || 0);

    const {
      currentTab,
      isLoading,
      unit,
      priceNovamyl3D,
      priceNovamyl10000,
      costNovamyl3DFrom,
      costNovamyl3DTo,
      costNovamyl10000From,
      costNovamyl10000To,
      openPriceModal,
      openSaveModal,
      openModal,
      recommendationTitle,
      comment,
      showFreshnessInfo,
      novamyl3DMax,
      novamyl3DMin,
      novamyl10000Max,
      novamyl10000Min,
      data,
      previousRecommendations,
      duplicateRecommendationTitle,
      showFeedback,
    } = this.state;

    const application = localStorage.getItem('recipe-param-application');

    let applicationData = ApplicationConfig().find((a) => a.key == application);

    let lang = localStorage.getItem('i18nextLng');

    //if (!data.outcomes) return null;

    if ((sugar === 0 && fat === 0 && egg === 0 && ryeFlour === 0) || targetShelfLife === 0)
      return <Navigate to="/RecipeParameters" />;

    if (isLoading) return <Loader />;

    const softnessData = this.getGraphData(data, unit, 'softness');

    const elasticityData = this.getGraphData(data, unit, 'elasticity');

    const recommendations: Outcomes = data.outcomes;

    const errorFeedback = duplicateRecommendationTitle ? (
      <ul className="feedback-error" style={{ marginTop: '30px' }}>
        <li>{i18n.t('Duplicate recommendation name.')}</li>
      </ul>
    ) : null;

    const infoFeedback = (
      <FeedBackInfo>
        <ul>
          <li>{i18n.t('Recommendation saved.')}</li>
        </ul>
      </FeedBackInfo>
    );

    const language = i18n.languages[0];
    const linksForLanguage = GetProductLinks(language);
    const hasAlternatives = recommendations.length > 1;

    const recommendationName = (
      <Trans
        i18nKey={recommendations[0] ? recommendations[0].name : ''}
        components={{
          sup: <sup />,
        }}
      />
    );

    const alternativeName = (
      <Trans
        i18nKey={recommendations[1] ? recommendations[1].name : ''}
        components={{
          sup: <sup />,
        }}
      />
    );

    const alternativeLinks =
      hasAlternatives && recommendations[1].name && linksForLanguage && linksForLanguage[recommendations[1].name];

    return (
      <ResultsPageWrapper className="results-page">
        <FlowLayout
          sidebar={
            <Sidebar
              applicationData={applicationData}
              input={{ sugar, fat, egg, ryeFlour, targetShelfLife }}
              unit={unit}
              region={SmartBake.region}
            />
          }
          progress={4 / 4}
        >
          <StepWrapper className="StepWrapper">
            <MainContent className="MainContent">
              <HeaderWrapper className="HeaderWrapper">
                <StepHeader title={i18n.t('Results')} previousPage="/RecipeParameters" currentPage="ResultsPage" />
              </HeaderWrapper>

              <ResultIntro
                region={SmartBake.region}
                application={application}
                recommendation={recommendations[0].name}
                alternatives={recommendations.slice(1).map((data) => data.name)}
              />

              <UnitSelector
                unit={unit}
                label={!SmartBake.mobile ? i18n.t('Our recommendation') : undefined}
                onToggleUnit={this.toggleUnit}
                region={SmartBake.region}
              />

              <EnzymeList
                region={SmartBake.region}
                device={SmartBake.device}
                recommendations={recommendations}
                application={application}
                unit={unit}
                scrollToTabAndMakeActive={this.showTabAndScroll}
              />

              <Divider
                line="hide"
                desktopHeight={46}
                tabletHeight={46}
                mobileHeight={0}
                bgColor="#f6f6f6"
                negativeMargin="false"
              />

              <LearnMore
                openModal={this.openModal}
                subject="freshkeeping"
                bgColor="#f6f6f6"
                text={i18n.t('Learn about factors affecting fresh-keeping')}
                paddingTop={20}
                paddingBottom={20}
                showBorders={true}
                maxWidth="medium"
              />

              <Divider
                line="hide"
                desktopHeight={56}
                tabletHeight={56}
                mobileHeight={0}
                bgColor="#f6f6f6"
                negativeMargin="false"
              />

              <ResultsTabs setCurrentActiveTab={this.setCurrentTab} currentActiveTab={this.state.currentTab} />

              <FreshnessWrapper className="negative-margin" ref={this.tabsRef}>
                <FreshnessAttributeResult
                  id="tab-content-softness"
                  className="large"
                  show={currentTab === 'softness' ? 'visible' : 'hidden'}
                >
                  <FreshnessDetails flexdirection="row" className="padding white no-flex-column">
                    {/*
                    <EnzymeInfoWrapper>
                      {enzymes.map((enzyme, index) => (
                        <EnzymeInfo
                          key={index}
                          name={enzyme.name}
                          description={enzyme.softness}
                          color={enzyme.color}
                        />
                      ))}
                    </EnzymeInfoWrapper>
                    */}

                    <EnzymeInfoContainer desktopWidth="44%">
                      <EnzymeInfoDescr>
                        {i18n.t('At the recommended dosages, with both ')}
                        {SmartBake.region != 're_JA' && (
                          <>
                            <br />
                            <br />
                            {i18n.t('Using ')}
                            <GreenColor>{recommendationName}</GreenColor>
                            {i18n.t(', the softness of your bread day ')}
                            <Bold>{targetShelfLife}</Bold>
                            {i18n.t(' is expected to be similar to a ')}
                            {this.dayEqualTo ? <Bold>{this.dayEqualTo}</Bold> : null}
                            {i18n.t(' days old bread without addition of fresh-keeping enzymes.')}
                          </>
                        )}
                      </EnzymeInfoDescr>
                    </EnzymeInfoContainer>

                    <EnzymeGraph
                      device={SmartBake.device}
                      region={SmartBake.region}
                      width="56%"
                      data={softnessData}
                      legendLeft={i18n.t('force in grams')}
                      legendBottom={i18n.t('days')}
                      textTop={i18n.t('firm')}
                      textBottom={i18n.t('soft')}
                      spaceXAxis={application === 'Panettone'}
                    />
                  </FreshnessDetails>

                  <Divider line="hide" bgColor="#fff" desktopHeight={50} tabletHeight={30} mobileHeight={20} />
                  <LearnMore
                    openModal={this.openModal}
                    subject="softness"
                    paddingTop={20}
                    paddingBottom={20}
                    showBorders={true}
                    bgColor="#fff"
                    text={i18n.t('How is the softness of the crumb measured?')}
                    addPadding={true}
                    maxWidth="large"
                  />
                  <Divider line="hide" bgColor="#fff" desktopHeight={50} tabletHeight={20} mobileHeight={20} />
                </FreshnessAttributeResult>

                <FreshnessAttributeResult
                  id="tab-content-elasticity"
                  className="large"
                  show={currentTab === 'elasticity' ? 'visible' : 'hidden'}
                >
                  <FreshnessDetails flexdirection="row" className="padding white no-flex-column">
                    <EnzymeInfoContainer desktopWidth="44%" tabletWidth="44%" mobileWidth="100%">
                      <EnzymeInfoDescr>{i18n.t('elasticity text')}</EnzymeInfoDescr>
                    </EnzymeInfoContainer>
                    {/*
                    <EnzymeInfoWrapper>
                      {enzymes.map((enzyme, index) => (
                        <EnzymeInfo
                          key={index}
                          name={enzyme.name}
                          description={enzyme.elasticity}
                          color={enzyme.color}
                        />
                      ))}
                    </EnzymeInfoWrapper>
                    {SmartBake.tablet &&
                      <InfoIconWrapper onClick={this.openFreshnessInfo}>
                        <InfoIcon></InfoIcon>
                        <InfoIconText>{i18n.t('Freshkeeping parameters')}</InfoIconText>
                      </InfoIconWrapper>
                    }
                    */}

                    <EnzymeGraph
                      device={SmartBake.device}
                      width="56%"
                      data={elasticityData}
                      legendLeft={i18n.t('ratio %')}
                      legendBottom={i18n.t('days')}
                      textTop={i18n.t('more')}
                      textBottom={i18n.t('less')}
                      spaceXAxis={application === 'Panettone'}
                    />
                  </FreshnessDetails>

                  <Divider line="hide" bgColor="#fff" desktopHeight={50} tabletHeight={30} mobileHeight={20} />
                  <LearnMore
                    openModal={this.openModal}
                    subject="elasticity"
                    paddingTop={20}
                    paddingBottom={20}
                    showBorders={true}
                    bgColor="#fff"
                    text={i18n.t('How is elasticity of the crumb measured?')}
                    maxWidth="large"
                    addPadding={true}
                  />

                  <Divider line="hide" bgColor="#fff" desktopHeight={50} tabletHeight={20} mobileHeight={20} />
                </FreshnessAttributeResult>

                <FreshnessAttributeResult
                  id="tab-content-moistness"
                  className="large"
                  show={currentTab === 'moistness' ? 'visible' : 'hidden'}
                >
                  <FreshnessDetails flexdirection="row" className="padding white">
                    <EnzymeInfoContainer desktopWidth="100%" tabletWidth="100%" mobileWidth="100%">
                      {this.getMoistnessContent(application, lang, recommendations, SmartBake.region)}
                    </EnzymeInfoContainer>
                  </FreshnessDetails>

                  {recommendations[0] && recommendations[0].name === 'Novamyl 3D BG' && (
                    <ConsumerStatementsWrapper>
                      <ConsumerStatements bgcolor="#fff" calcEqualHeight={currentTab === 'moistness'}>
                        <Statement country={i18n.t('Chinese customer')} quote={i18n.t('Chinese customer quote')} />
                        <Statement country={i18n.t('US customer')} quote={i18n.t('US customer quote 2')} />
                        <Statement country={i18n.t('Russian customer')} quote={i18n.t('Russian customer quote')} />
                        <Statement
                          country={i18n.t('Australian customer')}
                          quote={i18n.t('Australian customer quote')}
                        />
                        <Statement country={i18n.t('Thai customer')} quote={i18n.t('Thai customer quote')} />
                        <Statement country={i18n.t('US customer')} quote={i18n.t('US customer quote')} />
                      </ConsumerStatements>
                    </ConsumerStatementsWrapper>
                  )}

                  <Divider line="hide" bgColor="#fff" desktopHeight={50} tabletHeight={40} mobileHeight={40} />

                  {/*
                <FreshnessDetails>
                  <EnzymeInfoWrapper>
                  {enzymes.map((enzyme, index) => (
                    <EnzymeInfo
                      key={index}
                      name={enzyme.name}
                      description={enzyme.moistness}
                      color={enzyme.color}
                    />
                  ))}
                  </EnzymeInfoWrapper>
                </FreshnessDetails>
                */}
                </FreshnessAttributeResult>
              </FreshnessWrapper>

              {currentTab === 'elasticity' && <ElasticityVideo />}
            </MainContent>

            {/*<Divider bgColor="#fff" negativeMargin="true" desktopHeight={40} tabletHeight={0} mobileHeight={0} />*/}

            <SaveRecommendationWrapper className="negative-margin">
              <ButtonsWrapper className="action-btns large padding">
                <Link
                  to={{
                    pathname: '/RecipeParameters',
                  }}
                  state={{
                    sugar: sugar,
                    fat: fat,
                    egg: egg,
                    ryeFlour: ryeFlour,
                    targetShelfLife: targetShelfLife,
                  }}
                >
                  <Button id="result-btn-back" minWidth={225} marginRight={12} primary={false}>
                    {i18n.t('Back')}
                  </Button>
                </Link>
                <Button id="result-btn-save" minWidth={225} marginRight={0} primary onClick={this.handleOpenSaveModal}>
                  {i18n.t('Save recommendation')}
                </Button>
              </ButtonsWrapper>
              <LinksWrapper className="large padding left">
                <div>
                  <LinksDivider></LinksDivider>
                  <Link data-cy="result-new-recommendation" id="result-new-recommendation" to="/ApplicationPage">
                    {i18n.t('New recommendation')}
                  </Link>
                  <p
                    id="result-other-issues"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      this.openPardotDialog();
                    }}
                  >
                    {i18n.t('Other issues with your bake?')}
                  </p>
                </div>
              </LinksWrapper>
            </SaveRecommendationWrapper>
          </StepWrapper>
        </FlowLayout>

        <Modal onClose={this.handleClosePriceModal} open={openPriceModal}>
          <Form
            id="enzyme-price"
            title={i18n.t('Enzyme price')}
            description={i18n.t('Add your enzyme price in €/kg')}
            onFormSubmit={this.calculatePrice}
            disabled={
              /*priceNovamyl3D.length === 0 || priceNovamyl3D.length === 0*/ priceNovamyl3D === null ||
              priceNovamyl10000 === null
                ? true
                : false
            }
            isSave
            inputs={
              <div className="EnzymePrice">
                <Input
                  maxWidth={454}
                  minWidth={454}
                  marginBottom={20}
                  label={i18n.t('Novamyl® 3D BG')}
                  pattern="[0-9\.]*"
                  onValue={this.changeNovamyl3DValue}
                  value={priceNovamyl3D === null ? '' : priceNovamyl3D}
                  unit={i18n.t('€/kg enzyme')}
                  error={false}
                />
                <Input
                  maxWidth={454}
                  minWidth={454}
                  label={i18n.t('Novamyl® 10000 BG')}
                  pattern="[0-9\.]*"
                  onValue={this.changeNovamyl10000Value}
                  value={priceNovamyl10000 === null ? '' : priceNovamyl10000}
                  unit={i18n.t('€/kg enzyme')}
                  error={false}
                />
              </div>
            }
          />
        </Modal>

        <Modal onClose={this.handleCloseSaveModal} open={openSaveModal}>
          {!showFeedback && (
            <Form
              id="save-form"
              title={i18n.t('Save recommendation')}
              description={i18n.t('Save your recommendation now so you can access it later.')}
              onFormSubmit={this.saveRecommendation}
              disabled={
                recommendationTitle.length === 0 ||
                duplicateRecommendationTitle === true ||
                showFeedback /* || comment.length === 0*/
                  ? true
                  : false
              }
              isSave
              inputs={
                <div style={{ width: '100%' }}>
                  <Input
                    maxWidth={454}
                    minWidth={454}
                    marginBottom={20}
                    label={`${i18n.t('Recommendation Name')} *`}
                    pattern=".*"
                    onValue={this.changeRecommendationTitle}
                    value={recommendationTitle === null ? '' : recommendationTitle}
                    error={false}
                  />
                  <Input
                    maxWidth={100}
                    minWidth={454}
                    label={i18n.t('Comment')}
                    pattern=".*"
                    onValue={this.changeComment}
                    value={comment === null ? '' : comment}
                    error={false}
                  />
                  {errorFeedback}
                </div>
              }
            />
          )}
          {showFeedback && infoFeedback}
        </Modal>

        <Modal open={showFreshnessInfo} onClose={this.closeFreshnessInfoModal}>
          <FreshnessInfoModal onClose={this.closeFreshnessInfoModal} />
        </Modal>

        <LearnMoreModal
          renderModal={openModal}
          open={/(freshkeeping|softness|elasticity)/gi.test(openModal)}
          onClose={this.closeModal}
        >
          {openModal === 'freshkeeping' && (
            <FactorsEffectingFreshkeeping learnMorePage={false} onClose={this.closeModal} openModal={this.openModal} />
          )}
          {openModal === 'softness' && (
            <SoftnessOfTheCrumb learnMorePage={false} onClose={this.closeModal} openModal={this.openModal} />
          )}
          {openModal === 'elasticity' && (
            <ElasticityOfTheCrumb learnMorePage={false} onClose={this.closeModal} openModal={this.openModal} />
          )}
          {openModal === 'otherissues' && (
            <>
              <Header title={i18n.t('Other issues with your bake?')} learnMorePage={false} />
              <FormularOtherIssuesResultspage closeModal={this.closeModal} />
            </>
          )}
        </LearnMoreModal>
        <PardotDialog
          show={this.state.showPardotDialog}
          onClose={this.closePardotDialog}
          dialogTitle={i18n.t('Contact us title')}
          dialogIntro={i18n.t('Please describe your issues and we will get back to you')}
          subject="Issues"
        />
      </ResultsPageWrapper>
    );
  }
}

const ResultsPageWithRouter = withRouter(ResultsPage);
export default withTheme(ResultsPageWithRouter);
