import React, { useContext } from 'react';

import { styled } from '../theme';
import { MediaQueries } from '../theme/media-queries-css';

import BackButton from './back-button';
import { ThemeContext } from '../themecontext';

const Wrapper = styled('div')<{
  marginBottom: number;
  maxWidth: number;
}>`
  font-style: italic;
  font-size: 14px;
  line-height: 21px;
  color: #838c85;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;
const Sup = styled('sup')`
  font-size: 16px;
  line-height: 1;

  &:last-of-type {
    margin-right: 3px;
  }
`;

type Props = {
  text: string;
  maxwidth?: number;
  marginBottom?: number;
};

const reactReplaceString = require('react-string-replace');

const Disclaimer = (props: Props) => {
  //const context = useContext(ThemeContext);
  const { text = '', marginBottom = 0, maxwidth = 600 } = props;
  //const { desktop } = context.device;

  return (
    text !== '' && (
      <Wrapper maxWidth={maxwidth} marginBottom={marginBottom}>
        {reactReplaceString(text, '*', (match, i) => (
          <Sup key={i}>{match}</Sup>
        ))}
      </Wrapper>
    )
  );
};

export default Disclaimer;
