import React, { useRef, useState } from 'react';
import i18n from '../i18NextConf';
import { LanguageSelect } from '@novozymes-digital/components';

const LanguageSwitcher = () => {
  const [currentLang, setCurrentLang] = useState(i18n.languages[0]);

  const handleLanguageSwitch = async (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng);
    window.location.reload();
  };

  const supportedLanguages = [
    { key: 'en', label: i18n.t('English') },
    { key: 'zh', label: i18n.t('Chinese') },
    { key: 'ru', label: i18n.t('Russian') },
    { key: 'ja', label: i18n.t('Japanese') },
  ];

  const languages = supportedLanguages.map((supportedLanguage) => supportedLanguage.key);

  const anchorRef = useRef(null);

  return (
    <LanguageSelect
      handleLanguageSelect={handleLanguageSwitch}
      languages={languages}
      supportedLanguages={supportedLanguages}
      currentLanguage={currentLang}
      anchorRef={anchorRef}
      zIndex={1000}
    />
  );
};

export default LanguageSwitcher;
