import React from 'react';

import i18n from '../i18NextConf';
import { styled, Theme } from '../theme';
import { Auth, I18n } from 'aws-amplify';

import { MediaQueries } from '../theme/media-queries-css';

import VerticalArrow from '../images/vertical-arrow.svg';
import { ThemeContext } from '../themecontext';
import { colors } from '@novozymes-digital/components';

const LanguageSwitcherWrapper = styled.div`
  position: relative;
  margin: 26px 0 30px;
  max-width: 440px;

  &.wrapper-language-switcher-frontpage {
    &:before {
      position: absolute;
      right: 26px;
      top: 50%;
      background: url(${VerticalArrow}) no-repeat right 50%;
      background-size: 16px auto;
      transform: translateY(-50%);
      width: 16px;
      height: calc((100 / 60.1) * 16px);
      content: '';
      z-index: 1;
      pointer-events: none;
    }
  }

  &.wrapper-modal-navi {
    height: 100%;
    width: 100%;

    &:before {
      display: none;
    }
  }

  &:not(.wrapper-modal-navi) {
    background: #fff;
    padding: 0;
    display: flex;
    align-items: center;

    ${MediaQueries.large} {
      height: 56px;
    }
  }

  &:not(.wrapper-modal-navi) {
    ${MediaQueries.medium} {
      margin: 12px 0 28px;
    }
  }

  /* {Language Switcher Frontpage} */

  &.wrapper-language-switcher-frontpage {
    select {
      padding: 0 20px;
    }

    ${MediaQueries.medium} {
      order: 2;
      width: 100%;
      max-width: 460px;
    }
  }

  &.wrapper-modal-navi {
    margin: 0;
    max-width: 100%;
  }

  ${MediaQueries.medium} {
    order: 2;
    width: 100%;
    max-width: 300px;

    &.wrapper-modal-navi {
      margin: 0;
      max-width: 100%;
    }
  }

  &:after {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    content: '';
    z-index: 1;
    width: 11px;
    height: 8px;
    pointer-events: none;
  }
`;

/* {Selectbox} */

const Select = styled.select.attrs((props) => ({
  tabIndex: -1,
}))`
  position: relative;
  font-family: inherit;
  font-size: 18px !important;
  line-height: 1;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: none;

  &:not(.modal-navi) {
    ${MediaQueries.medium} {
      height: 45px;
    }
  }

  &.modal-navi {
    padding: 0;
    padding: 0px;
    width: 100%;
    color: ${colors.black};
    font-weight: normal;
    font-size: inherit;
    border: 0;
    background: none;
    max-width: 100%;
  }

  ${MediaQueries.medium} {
    font-size: 16px !important;
    &:not(.modal-navi) {
      max-width: 300px;
      background: none;
    }
  }

  &:focus {
    outline: 0;
  }
`;

interface State {
  currentLang: string;
}

export default class LanguageSwitcher extends React.PureComponent<
  {
    name: string;
    update?: () => void;
  },
  State
> {
  static contextType = ThemeContext;

  state = {
    currentLang: i18n.languages[0],
  };

  private handleLanguageSwitch = async (lng) => {
    i18n.changeLanguage(lng);
    this.setState({
      currentLang: lng,
    });
    // trigger a global setState to re-render translated texts across components
    let setLanguage = this.context.language;
    setLanguage(lng);
  };

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.handleLanguageSwitch(e.target.value);
  };

  componentDidMount() {}

  render() {
    const { currentLang } = this.state;
    const { name } = this.props;

    const ChangeLanguage = <option value="">{i18n.t('Change language')}</option>;

    return (
      <>
        <LanguageSwitcherWrapper id="language-selector" className={`wrapper-${name}`}>
          <Select
            onChange={(e) => {
              this.onChange(e);
            }}
            className={name}
          >
            {ChangeLanguage}
            <option id="language-option-en" value="en">
              {i18n.t('English')}
            </option>
            <option id="language-option-zh" value="zh">
              {i18n.t('Chinese')}
            </option>
            <option id="language-option-ru" value="ru">
              {i18n.t('Russian')}
            </option>
            <option id="language-option-ja" value="ja">
              {i18n.t('Japanese')}
            </option>
            {/*
            <option value="ar">{i18n.t('Arab')}</option>
            */}
          </Select>
        </LanguageSwitcherWrapper>
      </>
    );
  }
}
