import React from 'react';

import { ThemeContext } from '../../themecontext';

import RecipeParametersPageEU from './index_eu';
import RecipeParametersPageCH from './index_ch';

export default class RecipeParametersPage extends React.PureComponent<{
  children: React.ReactNode;
  sugar?: number | null;
  fat?: number | null;
  targetShelfLife?: number | null;
}> {
  static contextType = ThemeContext;

  render() {
    if (!this.context.region) {
      return null;
    }

    let page: any;

    switch (this.context.region) {
      case 're_CH':
        page = <RecipeParametersPageCH {...this.props}></RecipeParametersPageCH>;
        break;
      default:
        page = <RecipeParametersPageEU {...this.props}></RecipeParametersPageEU>;
    }

    return page;
  }
}
